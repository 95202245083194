import RootLayout from "../../layouts/RootLayout";
import PagesBreadcrumbs from "../../components/PagesBreadcrumbs/PagesBreadcrumbs";
import PageHeading from "../../components/headings/PageHeading";
import "./TermsPage.scss";

const TermsPage = () => {
  return (
    <RootLayout>
      <main id="main">
        <PagesBreadcrumbs />
        <section className='terms'>
          <div className="container">
            <div className="terms__wrapper">
              <header className='terms__header'>
                <PageHeading variant="h1">KONIECZNIE SIĘ ZAPOZNAJ!</PageHeading>
                <PageHeading variant="h2">REGULAMIN STRONY</PageHeading>
              </header>
              <div className="terms__content">
                <ol className='my-0' type="1">
                  <li>Treningi personalne prowadzone są przez trenerów personalnych: Michała Bobyka.</li>
                  <li>Klient rozpoczynając współpracę, zobowiązany jest posiadać zaświadczenie o braku przeciwwskazań do podjęcia aktywności fizycznej wydane przez lekarza. Akceptując poniższy regulamin oświadcza, że posiada owe zaświadczenie lub podejmuje treningi na własną odpowiedzialność.</li>
                  <li>Warunkiem dopuszczenia do udziału w treningach personalnych jest złożenie wypełnionego i podpisanego własnoręcznie przez uczestnika zajęć w/w oświadczenia.</li>
                  <li>Klient jest zobowiązany do poinformowania trenera personalnego o wszelkich dolegliwościach zdrowotnych, stanie zdrowia, celem dopasowania rodzaju ćwiczeń oraz ich intensywności do stanu zdrowia ćwiczącego.</li>
                  <li>Klient powinien stawić się punktualnie na umówionych zajęciach (treningu personalnym). Zalecane jest przybycie 15min wcześniej celem przygotowania się do treningu.</li>
                  <li>Trening personalny trwa 50-60min.</li>
                  <li>Trening personalny może zostać odwołany bez żadnych konsekwencji przez obie strony z 24 godzinnym wyprzedzeniem.</li>
                  <li>W przypadku kiedy klient w czasie krótszym niż 24 godziny nie poinformuje trenera o odwołaniu treningu przed umówionym terminem, trening personalny zostaje uznany przez trenera za przeprowadzony.</li>
                  <li>W przypadku kiedy trener w czasie krótszym niż 24 godziny nie poinformuje klienta o odwołaniu treningu przed umówionym terminem, klient otrzymuje 1 trening personalny gratis.</li>
                  <li>W przypadku spóźnienia się klienta na trening personalny, czas spóźnienia odliczony będzie od regulaminowego czasu treningu.</li>
                  <li>W przypadku niepojawienia się klienta na treningu personalnym, trening personalny zostaje uznany przez trenera za przeprowadzony.</li>
                  <li>W przypadku niepojawienia się trenera na treningu personalnym, klient otrzymuje 1 trening personalny gratis.</li>
                  <li>Płatność za treningi jest dokonywana gotówką u trenera , lub przelewem. Terminy treningów są ustalane bezpośrednio z trenerem.</li>
                  <li>Opłaty za trening powinny być uiszczane z góry nie później niż w dniu rozpoczęcia pierwszego treningu.</li>
                  <li>W przypadku wykupienia pakietów treningowych okres ich ważności liczy się począwszy od dnia, w którym odbył się pierwszy trening.</li>
                  <li>Pakiet treningowy (10 treningów) jest ważny 1 10 tygodni od  momentu wykupienia. Po tym czasie, nie wykorzystane treningi przepadają. W razie niemożliwości wykorzystania pakietu, można go podarować lub odprzedać innej osobie.</li>
                  <li>Podczas ćwiczeń obowiązuje strój sportowy, nieograniczający swobody ruchu, obuwie sportowe. Klient jest zobowiązany posiadać ze sobą ręcznik treningowy oraz wodę do uzupełniania płynów podczas treningu.</li>
                  <li>Zaleca się, aby klient min. godzinę przed treningiem zjadł pełnowartościowy posiłek.</li>
                  <li>W przypadku wahań poziomu cukru we krwi (cukrzyca, zaburzenia gospodarki cukrowej/insulinowej) bądź omdleń zaleca się aby na trening zabierany był ze sobą glukometr oraz słodkie przekąski (daktyle, żele dla cukrzyków itp.).</li>
                  <li>W przypadku ujawnienia się nietypowych dolegliwości klienta podczas treningu może on zostać poproszony o dodatkową konsultację lekarską. W szczególnych przypadkach treningi mogą zostać zawieszone do czasu postawienia przez lekarza diagnozy.</li>
                  <li>Jeśli treningi odbywają się w klubie fitness/ siłowni trenujący wykupują wejście do klubu / siłowni we własnym zakresie.</li>
                  <li>Zarówno trener, jak i klient, dołoży wszelkich starań, by cele klienta zostały osiągnięte.</li>
                  <li>Kontakt z klientem posiadającym jeden z trzech pakietów (3-6-12m) , z naszej strony, odbywa się na prywatnym panelu klienta , do 24h od otrzymania wiadomości.</li>
                  <li>Usługa zaczyna działać - pakiet start i trzy abonamenty - od momentu zaksięgowania wpłaty (przelew internetowy).</li>
                  <li>W razie kontuzji , urazu , który wydarzy się podczas trenowania z nami online - nie ponosimy odpowiedzialności , gdyż nie ma nas na miejscu 1:1 i nie jesteśmy w stanie dopilnować idealnej techniki ćwiczeń.</li>
                  <li>Przy pakiecie 6 i 12 miesięcy , klient dostaje od nas godzinny czat / rozmowę video / z wybranym trenerem / do przetrenowania danej grupy mięśniowej / wykorzystania przy korygowaniu ćwiczeń z którymi klient ma problem, nie czuje się pewnie.</li>
                  <li>Przy pakiecie 12 miesięcy , klient dostaje od nas trening personalny 1:1 w Rzeszowie na siłowni Cityfit, do przetrenowania danej grupy mięśniowej, wykorzystania przy korygowaniu ćwiczeń z którymi klient ma problem, nie czuje się pewnie.</li>
                </ol>
              </div>
              <footer className="text-center terms__footer">
                <a className='position-relative d-inline-block text-decoration-none' href="/">
                  <div className="position-relative d-flex align-items-center svg-container">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.8337 10H4.16699" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M10.0003 15.8337L4.16699 10.0003L10.0003 4.16699" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <span className='d-inline-block font-italic text-uppercase'>Wróć</span>
                  </div>
                </a>
              </footer>
            </div>
          </div>
        </section>
      </main>
    </RootLayout>
  );
};

export default TermsPage;
