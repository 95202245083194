import React, { useState } from "react";
import { Button } from "react-bootstrap";
import NavItem from "./NavItem";
import "./AdminSidebarNav.scss";
import { useDispatch } from "react-redux";
import { useCurrentPathNav } from "./functions/useCurrentPathNav";
import { unAuthUser } from "../../actions/auth";
import { AllowedRoles } from "../../actions/wp";
import {
  ChevronIcon,
  HomeFillingIcon,
  ChatFillIcon,
  ReportFillIcon,
  UserFillIcon,
  TargetFillIcon,
  PakietyFillIcon,
  HumanFillIcon,
  PlayFillIcon,
  RestaurantFillIcon,
  SettingsFillIcon,
  PowerIcon,
} from "../../assets/images/svg/Icons";
import links from "../../constants/links";
import { useWpSelector } from "../../selectors";

type SidebarNavProps = {
  pathName: string;
};

const SidebarNav: React.FC<SidebarNavProps> = ({ pathName }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const { currentUser } = useWpSelector();

  const isAdmin = currentUser?.roles.includes(AllowedRoles.admin);

  const { renderCurrentPath } = useCurrentPathNav(true);

  const handleShowMobileNav = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  const dispatch = useDispatch();

  const Logout = React.useCallback(() => {
    dispatch(
      unAuthUser(() => {
        window.location.reload();
      })
    );
  }, [dispatch]);

  return (
    <aside className={`dashboard__sidebar ${mobileNavOpen ? "show" : ""}`}>
      <div
        onClick={handleShowMobileNav}
        className={`dashboard__nav_toggle ${mobileNavOpen && "open"}`}
      >
        <span>{renderCurrentPath()} </span>
        <span
          className={`dashboard__nav_toggle-chevron ${mobileNavOpen && "open"}`}
        >
          <ChevronIcon />
        </span>
      </div>
      <nav className={`dashborad__nav ${mobileNavOpen ? "show" : ""}`}>
        {isAdmin ? (
          <ul className="sidebar-nav-wr">
            <li onClick={handleShowMobileNav}>
              <NavItem
                toPath={links.dashboard}
                linkText="Widok główny"
                curPathName={pathName}
                iconClass="fa-user"
                Icon={HomeFillingIcon}
              />
            </li>
            <li onClick={handleShowMobileNav}>
              <NavItem
                toPath={links.trainerMessages}
                linkText="Wiadomości"
                curPathName={pathName}
                iconClass="fa-bullseye"
                Icon={ChatFillIcon}
              />
            </li>
            <li onClick={handleShowMobileNav}>
              <NavItem
                toPath={links.trainerRaports}
                linkText="raporty"
                curPathName={pathName}
                iconClass="fa-cube fa-regular"
                Icon={ReportFillIcon}
              />
            </li>
            <li onClick={handleShowMobileNav}>
              <NavItem
                toPath={links.trainerClients}
                linkText="Lista Klientów"
                activePath={true}
                curPathName={pathName}
                iconClass="fa-child"
                Icon={UserFillIcon}
              />
            </li>
          </ul>
        ) : (
          <>
            <ul className="sidebar-nav-wr">
              <li onClick={handleShowMobileNav}>
                <NavItem
                  toPath={links.dashboard}
                  linkText="Twój profil"
                  curPathName={pathName}
                  iconClass="fa-user"
                  Icon={HomeFillingIcon}
                />
              </li>
              <li onClick={handleShowMobileNav}>
                <NavItem
                  toPath={links.panelTarget}
                  linkText="Twój cel"
                  curPathName={pathName}
                  iconClass="fa-bullseye"
                  Icon={TargetFillIcon}
                />
              </li>
              <li onClick={handleShowMobileNav}>
                <NavItem
                  toPath={links.panelPackages}
                  linkText="Twoje pakiety"
                  curPathName={pathName}
                  iconClass="fa-cube fa-regular"
                  Icon={PakietyFillIcon}
                />
              </li>
              <li onClick={handleShowMobileNav}>
                <NavItem
                  toPath={links.panelBody}
                  linkText="Twoje ciało"
                  curPathName={pathName}
                  iconClass="fa-child"
                  Icon={HumanFillIcon}
                />
              </li>
            </ul>
            <ul className="sidebar-nav-wr">
              <li onClick={handleShowMobileNav}>
                <NavItem
                  toPath={links.panelMessages}
                  linkText="Wiadomości"
                  curPathName={pathName}
                  iconClass="fa-message"
                  Icon={ChatFillIcon}
                />
              </li>
              <li onClick={handleShowMobileNav}>
                <NavItem
                  toPath={links.panelRaports}
                  linkText="Raporty"
                  curPathName={pathName}
                  iconClass="fa-circle-play"
                  Icon={ReportFillIcon}
                />
              </li>
              <li onClick={handleShowMobileNav}>
                <NavItem
                  toPath={links.panelVideo}
                  linkText="Ćwiczenia wideo"
                  curPathName={pathName}
                  iconClass="fa-file"
                  Icon={PlayFillIcon}
                />
              </li>
              <li onClick={handleShowMobileNav}>
                <NavItem
                  toPath={links.panelDiet}
                  linkText="Kreator diet"
                  curPathName={pathName}
                  iconClass="fa-info"
                  Icon={RestaurantFillIcon}
                />
              </li>
              {currentUser?.meta.plan === "30" && (
                <li onClick={handleShowMobileNav}>
                  <NavItem
                    toPath={links.panelTraining}
                    Icon={HumanFillIcon}
                    curPathName={pathName}
                    iconClass="fa-child"
                    linkText="Trening"
                  />
                </li>
              )}
            </ul>
          </>
        )}
        <ul className="sidebar-nav-wr">
          <li onClick={handleShowMobileNav}>
            <NavItem
              toPath={isAdmin ? links.trainerSettings : links.panelSettings}
              linkText="Ustawienia"
              curPathName={pathName}
              iconClass="fa-dashboard"
              Icon={SettingsFillIcon}
            />
          </li>
          <li onClick={handleShowMobileNav}>
            <Button
              onClick={() => Logout()}
              title="Wyloguj"
              variant="outline-light"
              className="nav-link btn-logout"
              data-test="logout-btn"
            >
              <PowerIcon />
              Wyloguj
            </Button>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default SidebarNav;
