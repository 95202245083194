import {
  Field,
  Form as FormikForm,
  Formik,
  FormikHelpers,
  useFormikContext,
} from "formik";
import { FC, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { object, string } from "yup";
import { fetchMessages, postMessage } from "../../../../../actions/wp";
import { ChatFillIcon } from "../../../../../assets/images/svg/Icons";
import FormField from "../../../../../components/Form/FormField";
import Loader from "../../../../../components/Loader/Loader";
import { useWpSelector } from "../../../../../selectors";
import "./UserMessages.scss";

interface IUserMessages {
  id: number | null;
}

const UserMessages: FC<IUserMessages> = ({ id }) => {
  const { currentUser } = useWpSelector();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { messages } = useWpSelector();
  const listRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (id !== null) {
      dispatch(
        fetchMessages(String(id), (loading) => {
          setIsLoading(loading);
        })
      );

      interval = setInterval(() => {
        dispatch(
          fetchMessages(String(id), (loading) => {
            setIsLoading(loading);
          })
        );
      }, 100000); // Powtarzaj co 10 minuty
    }

    return () => clearInterval(interval);
  }, [dispatch, id]);

  useEffect(() => {
    if (listRef.current) {
      const scrollableList = listRef.current;
      scrollableList.scrollTop = scrollableList.scrollHeight;
    }
  }, [messages, isLoading]);

  const sortMsg = messages?.sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateA.getTime() - dateB.getTime();
  });
  return (
    <div className="message_wr_admin">
      {id ? (
        <div className="message_content_admin">
          <ul className="message_content_admin-list" ref={listRef}>
            {isLoading ? (
              <Loader />
            ) : (
              sortMsg?.map((msg) => {
                return (
                  <li
                    key={msg.id}
                    className={`${
                      +msg.senderId !== id
                        ? "message__author"
                        : "message__caller"
                    }`}
                  >
                    {msg.text.split("\n").map((item, key) => {
                      return (
                        <span key={key}>
                          {item}
                          <br />
                        </span>
                      );
                    })}
                  </li>
                );
              })
            )}
          </ul>
          <div className="message__form_wrapper">
            <Formik
              initialValues={{ text: "" }}
              className="message__form"
              validationSchema={MessagePostValidation}
              onSubmit={(
                values: any,
                actions: FormikHelpers<{ text: string }>
              ) => {
                if (values.text && currentUser) {
                  dispatch(
                    postMessage(
                      {
                        receiverId: id,
                        senderId: currentUser.id,
                        text: values.text,
                      },
                      (res: any) => {
                        if (res.status < 300) {
                          actions.setSubmitting(false);
                          actions.setValues({ text: "" });
                          dispatch(fetchMessages(String(id), () => {}));
                        }
                      }
                    )
                  );
                } else {
                  actions.setSubmitting(false);
                }
              }}
            >
              <FormikForm>
                <Field
                  name="text"
                  type="textarea"
                  placeholder="Aa"
                  rows="2"
                  component={FormField}
                />
                <SubmitButton />
              </FormikForm>
            </Formik>
          </div>
        </div>
      ) : (
        <div className="cap">
          <ChatFillIcon />
        </div>
      )}
    </div>
  );
};

const SubmitButton = () => {
  const { submitForm, isSubmitting } = useFormikContext();

  return (
    <Button
      className="send_button"
      variant="primary"
      onClick={submitForm}
      disabled={isSubmitting}
    >
      {isSubmitting ? (
        <>
          <span className="spinner-grow spinner-grow-sm" />
        </>
      ) : (
        "WYŚLIJ"
      )}
    </Button>
  );
};

const MessagePostValidation = object().shape({
  text: string().required(""),
});

export default UserMessages;
