import { Field, Form as FormikForm, Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import LinkButton from "../../components/Buttons/LinkButton";
import { object, string } from "yup";
import { authUser, FormValues } from "../../actions/auth";
import { fetchCurrentUser } from "../../actions/wp";
import FormField from "../../components/Form/FormField";
import Loader from "../../components/Loader/Loader";
import { initialValues } from "./data";
import links from "../../constants/links";

const SignInForm = () => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ): void => {
    dispatch(
      authUser(values, (response) => {
        if (response instanceof Error) {
          setErrorMessage("Niepoprawna nazwa użytkownika lub hasło");
          actions.setSubmitting(false);
        } else {
          dispatch(fetchCurrentUser());
        }
      })
    );
  };

  return (
    <Container className="form-register__container">
      <Formik
        onSubmit={handleSubmit}
        validationSchema={SignFormSchemaValidation}
        initialValues={initialValues}
      >
        {({ isSubmitting }) => {
          return (
            <FormikForm className="form-inner">
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
              <Field
                type="text"
                label="WPISZ NAZWĘ UŻYTKOWNIKA"
                name="username"
                placeholder="[LOGIN]"
                component={FormField}
                icon="user"
              />
              <Field
                type="password"
                name="password"
                label="WPISZ HASŁO"
                placeholder="[HASŁO]"
                component={FormField}
                icon="eye"
              />
              <Form.Group className="text-center button_wr">
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? <Loader variant="light" /> : "Zaloguj"}
                </Button>
                <LinkButton
                  href={links.home}
                  text="Strona Główna"
                  type="link"
                  variant="secondary"
                />
              </Form.Group>
            </FormikForm>
          );
        }}
      </Formik>
    </Container>
  );
};

const SignFormSchemaValidation = object().shape({
  username: string().required("To pole jest wymagane"),
  password: string().required("To pole jest wymagane"),
});

export default SignInForm;
