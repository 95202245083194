import React, { FC, useState } from "react";
import { ChevronIcon } from "../../../../../assets/images/svg/Icons";
import "./SingleExercise.scss";

interface IExercise {
  exercise: any;
  open: boolean;
}

const SingleDay: FC<IExercise> = ({ exercise, open }) => {
  const [isOpen, setIsOpen] = useState(open ? open : false);

  const handleClickOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={`day_wr ${isOpen ? "open" : ""} `}>
      <header className="day_wr__header" onClick={handleClickOpen}>
        <div className="bold">{exercise.name}</div>
        <span className={`toggle ${isOpen ? "open" : ""}`}>
          {isOpen ? "zwiń" : "rozwiń"}
          <ChevronIcon />
        </span>
      </header>
      {isOpen && (
        <ul>
          {exercise?.exercises?.map((item: any, idx: number) => (
            <li className="exercise_wr" key={`${item.name}-${idx}`}>
              <span>{item.name}</span>
              <span className="indent">
                Ilość serii: <span className="bold">{item.series}</span>
              </span>
              <span className="indent">
                Ilość powtórzeń: <span className="bold">{item.repeat}</span>{" "}
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SingleDay;
