import { Button } from "react-bootstrap";
import { PhotoIcon } from "../../../../../assets/images/svg/Icons";
import Loader from "../../../../../components/Loader/Loader";
import { useWpSelector } from "../../../../../selectors";
import SettingsForm from "../../Ustawienia/components/SettingsForm";
import "../TrainerSettingsPage.scss";

const TrainerSettingsForm = () => {
  const { currentUser } = useWpSelector();
  const avatarUrl =
    currentUser && currentUser.avatar_urls ? currentUser.avatar_urls[96] : null;

  const handleUpdatePhoto = () => {};
  return currentUser ? (
    <div className="settings__form">
      <div className="account__info">
        <div className="position-relative d-flex justify-content-center align-items-center account__avatar">
          {avatarUrl ? <img src={avatarUrl} alt="Account avatar" /> : "..."}
        </div>
        <div className="text-center account__info__details">
          <span className="d-block">
            {currentUser ? currentUser.user_email : "..."}
          </span>
          <span className="account__created_at">
            <time>{currentUser ? currentUser.user_registered : "..."}</time>
          </span>
        </div>
        <Button variant="secondary" type="button" onClick={handleUpdatePhoto}>
          Zmień zdjęcie
          <div className="svg-container">
            <PhotoIcon />
          </div>
        </Button>
        <span className="d-block text-center">
          Zdjęcie powinno być w formacie .jpg lub .png.{" "}
          <strong>Maksymalny rozmiar 3mb.</strong>
        </span>
      </div>
      <SettingsForm currentUser={currentUser} />
    </div>
  ) : (
    <Loader />
  );
};

export default TrainerSettingsForm;
