import axios from "axios";
import { FC, useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import Constants from "../../constants";
import { Post } from "../../actions/wp";
import { EyeIcon } from "../../assets/images/svg/Icons";
import { formatDate } from "../../functions/formatDate";
import RaportsPerviewModal from "../../pages/Admin/pages/Raporty/Modal/RaportsPerviewModal";
import Loader from "../Loader/Loader";
import "./TrainerRaports.scss";

interface ITrainerClients {
  raports: Post[];
  isLoading: boolean;
  error: string;
  displayName?: boolean;
  currentUser: any;
}

const TrainerRaports: FC<ITrainerClients> = ({
  raports,
  error,
  isLoading,
  displayName = true,
  currentUser,
}) => {
  const token = localStorage.getItem("_wp_react_typescipt_token");
  const [showModal, setShowModal] = useState(false);
  const [initialValues, setInitialValues] = useState<any>();
  const [filteredClients, setFilteredClients] = useState<any[]>([]);
  const [isClientLoading, setIsClientLoading] = useState(true);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(`${Constants.apiUri}/clients`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log("API Response:", response.data); // Logowanie pełnej odpowiedzi

        const clients = response.data.users; // Zapisz użytkowników z właściwości `users`

        // Sprawdź, czy clients jest tablicą
        if (Array.isArray(clients)) {
          const filtered = clients.filter(
            (client: any) => [1652, 1653].includes(Number(client.meta.plan))
          );
          setFilteredClients(filtered);
        } else {
          console.error("Unexpected data format: clients is not an array");
        }
      } catch (error) {
        console.error("Error fetching clients:", error);
      } finally {
        setIsClientLoading(false);
      }
    };

    fetchClients();
  }, [token]);

  useEffect(() => {
    console.log("Filtered Clients", filteredClients);
  }, [filteredClients]);

  // Filtrowanie raportów w zależności od ID użytkownika
  const filteredReports =
    currentUser.id === 234
      ? raports.filter((raport: any) =>
          filteredClients.some((client) => client.id === raport.author)
        )
      : raports.filter((raport: any) =>
          !filteredClients.some((client) => client.id === raport.author)
        );

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <div>
      {isClientLoading || isLoading ? (
        <Loader />
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : (
        <div className={`list`}>
          <header className={`list_header ${!displayName ? "short" : ""}`}>
            {displayName && (
              <div className="text-uppercase list_header__item">
                Imię i nazwisko
              </div>
            )}
            <div className="text-uppercase list_header__item">Dołączył/a</div>
            <div className="text-uppercase list_header__item">Typ</div>
            <div className="text-uppercase list_header__item">Tytuł</div>
            <div className="text-uppercase list_header__item">Akcje</div>
          </header>
          <div className="list__wrapper">
            {filteredReports?.map((raport: any) => {
              return (
                <div
                  className={`list_item ${!displayName ? "short" : ""}`}
                  key={raport.id}
                >
                  {displayName && (
                    <span className="d-block d-flex align-items-center">
                      <span className="info__mobile">Imię i nazwisko:</span>
                      <span>{raport?.author_details?.name}</span>
                    </span>
                  )}
                  <time className="d-block d-flex align-items-center">
                    <span className="info__mobile">Dołączył/a:</span>{" "}
                    <time>
                      {formatDate(raport?.meta?.report_start_date)} -{" "}
                      {formatDate(raport?.meta?.report_end_date)}
                    </time>
                  </time>
                  <span className="d-block d-flex align-items-center">
                    <span className="info__mobile">Typ:</span> Raport
                  </span>
                  <span className="d-block d-flex align-items-center">
                    <span className="info__mobile">Tytuł:</span>
                    <span className="item_title">
                      {raport.title.rendered}
                    </span>
                  </span>
                  <div className="d-flex justify-content-between align-items-center item__col item__col--right list_item__details">
                    <button
                      type="button"
                      className="p-0"
                      onClick={() => {
                        setInitialValues(raport);
                        handleShow();
                      }}
                    >
                      <span>podgląd</span>
                      <EyeIcon />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          {initialValues && (
            <RaportsPerviewModal
              raport={initialValues}
              handleClose={handleClose}
              showModal={showModal}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TrainerRaports;
