import axios from "axios";
import { useEffect, useState } from "react";
import { useWpSelector } from "../../../../../selectors";
import Button from "../../../../../components/Buttons/LinkButton";
import TrainerRaports from "../../../../../components/TrainerRaports/TrainerRaports";
import Constants from "../../../../../constants";
import links from "../../../../../constants/links";
import "./LatestRaports.scss";

const LatestRaports = () => {
  const { currentUser } = useWpSelector();
  const token = localStorage.getItem("_wp_react_typescipt_token");
  const [raports, setRaports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  //   &per_page=5&orderby=date&order=desc
  useEffect(() => {
    const fetchLatestUsers = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${Constants.apiUri}/reports`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            per_page: 5, // Liczba użytkowników do pobrania
            orderby: "date", // Sortowanie według daty rejestracji
            order: "desc",
          },
        });
        setRaports(response.data);
      } catch (err) {
        setError("Przepraszamy, coś poszło nie tak, spróbuj ponownie później");
      } finally {
        setIsLoading(false);
      }
    };

    fetchLatestUsers();
  }, [token]);
  return (
    <div className="raports__list">
      <h3 className="trainer__dashboard-second-heading">
        OSTATNIO DODANE RAPORTY
      </h3>
      <TrainerRaports
        error={error}
        isLoading={isLoading}
        raports={raports}
        currentUser={currentUser}
      />
      <div className="cust__list_btn">
        <Button
          href={links.trainerRaports}
          text="Zobacz wszystkie"
          type="link"
          variant="secondary"
        />
      </div>
    </div>
  );
};

export default LatestRaports;
