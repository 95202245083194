import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { User, AllowedRoles } from "../../actions/wp";
import { ReactComponent as LogoSvg } from "../../assets/images/Logo_svg.svg";
import HeaderLogo from "../../assets/images/png/logoV2.png";
import {
  FacebookIcon,
  InstaIcon,
  MessageIcon,
  StarIcon,
  UserFillIcon,
} from "../../assets/images/svg/Icons";
import links from "../../constants/links";
import "./Header.scss";

type HeaderProps = {
  currentUser?: User;
  unAuthUser?: () => void;
};

const Header: React.FC<HeaderProps> = React.memo(({ currentUser }) => {
  const [isOpen, setIsOpen] = useState(false);

  const isAdmin = currentUser?.roles.includes(AllowedRoles.admin);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const body = document.body;

    if (isOpen) {
      body.classList.add("no-scroll");
    } else {
      body.classList.remove("no-scroll");
    }

    return () => {
      body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  return (
    <header className="header">
      <div className="header-outer-wrapper">
        <div className="header-left-side">
          <a href="https://www.google.com/search?sa=X&sca_esv=600640614&hl=pl-PL&tbm=lcl&sxsrf=ACQVn0-rZg0ZsxZ8VzyJucDMnyHP1b3rGw:1705983905760&q=Health+and+Body+Academy+Opinie&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxI2NTc3NzU0MjawNDI1MrMwMjEy3cDI-IpRziM1MackQyExL0XBKT-lUsExOTElNbdSwb8gMy8zdRErAQUAc4yW-FwAAAA&rldimm=5777512309252682425&ved=2ahUKEwj62Mel1fKDAxXNa_EDHRRSD2cQ9fQKegQIEhAF&biw=1920&bih=993&dpr=1#lkt=LocalPoiReviews" target='_blank' rel="noreferrer">
            <div className="header-opinion-wr">
              <span>+140 opinii</span>
              <span>
                <span>5,0</span>
                <StarIcon />
              </span>
            </div>
          </a>
          <div className="left-nav">
            <Link to={links.packages}>PAKIETY</Link>
            <Link to={links.aboutUs}>O MNIE</Link>
            <Link to={links.metamorphoses}>WASZE METAMORFOZY</Link>
          </div>
        </div>
        <div className="logo-wr">
          <Link to={links.home}>
            <img src={HeaderLogo} alt="logo" />
          </Link>
        </div>
        <div className="right-nav">
          <div className="right-nav-social">
            <a
              href="https://www.facebook.com/healthandbodyacademy"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon />
            </a>
            <a
              href="https://www.instagram.com/healthandbodyacademy/"
              target="_blank"
              rel="noreferrer"
            >
              <InstaIcon />
            </a>
            <a
              href="mailto:info@healthandbodyacademy.com"
              target="_blank"
              rel="noreferrer"
            >
              <MessageIcon />
            </a>
          </div>
          <div className="right-nav-links">
            <Link to={links.faq}>FAQ</Link>
            <Link to={links.blog}>BLOG</Link>
            <Link to={links.contact}>KONTAKT</Link>
            <Link
              to={currentUser ? links.dashboard : links.signIn}
              className="panel-link"
            >
              <span>
                {currentUser
                  ? `${isAdmin ? "PANEL TRENERA" : "TWOJ PANEL"}`
                  : "ZALOGUJ SIĘ"}
              </span>
              <UserFillIcon />
            </Link>
          </div>
        </div>
        <div className={`burger-menu ${isOpen ? "open" : ""}`}>
          <div className="burger-icon" onClick={handleToggle}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <div className="menu">
            <div className="menu__logo">
              <Link to="/dashboard" onClick={handleToggle}>
                <img src={HeaderLogo} alt="logo" />
              </Link>
            </div>
            <ul>
              <li onClick={handleToggle}>
                <Link to={links.packages}>PAKIETY</Link>
              </li>
              <li onClick={handleToggle}>
                <Link to={links.aboutUs}>O MNIE</Link>
              </li>
              <li onClick={handleToggle}>
                <Link to={links.metamorphoses}>WASZE METAMORFOZY</Link>
              </li>
              <li onClick={handleToggle}>
                <Link to={links.faq}>FAQ</Link>
              </li>
              <li onClick={handleToggle}>
                <Link to={links.blog}>BLOG</Link>
              </li>
              <li onClick={handleToggle}>
                <Link to={links.contact}>KONTAKT</Link>
              </li>
              <Link
                to={currentUser ? links.dashboard : links.signIn}
                className="panel-link"
                onClick={handleToggle}
              >
                <span>
                  {currentUser
                    ? `${isAdmin ? "PANEL TRENERA" : "TWOJ PANEL"}`
                    : "ZALOGUJ SIĘ"}
                </span>
                <UserFillIcon />
              </Link>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
});

export default Header;
