import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { HeroBorders } from "../../../assets/images/svg/borderLines";
import { ArrowIcon, IconX, PlayIcon } from "../../../assets/images/svg/Icons";
import {
  AnimatedArrows,
  MobileAnimatedArrows,
  SmallAnimatedArrows,
} from "../../../components/AnimatedArrows/AnimatedArrows";
import Button from "../../../components/Buttons/LinkButton";
import HeroImage from "../../../assets/images/png/hero-1.png";
import PageHeading from "../../../components/headings/PageHeading";
import links from "../../../constants/links";

import "./HeroSection.scss";

const HeroSection = () => {
  const [isVideoVisible, setVideoVisible] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handleVideoEnd = () => {
    setVideoVisible(false);
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
    }
  };

  const toggleVideo = () => {
    setVideoVisible(!isVideoVisible);

    if (!isVideoVisible) {
      videoRef.current?.play();
    } else {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    }
  };

  return (
    <section className="hero">
      <div
        className="position-absolute w-100 h-100 overflow-hidden hero__video"
        aria-hidden={!isVideoVisible}
      >
        <video
          ref={videoRef}
          className="w-100 h-100"
          onClick={toggleVideo}
          onEnded={handleVideoEnd}
        >
          <source
            src="http://panel.healthandbodyacademy.com/wp-content/uploads/2024/07/hero-video-1.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div className="container">
        <div className="d-flex w-100 hero__wrapper">
          <div className="hero__content">
            <MobileAnimatedArrows />
            <MobileAnimatedArrows />
            <MobileAnimatedArrows />
            <header className="d-flex align-items-center hero__header">
              <h1 className="font-italic my-0">KONIEC Z PÓŁŚRODKAMI</h1>
              <IconX />
            </header>
            <PageHeading variant="h2">
              TRENUJ<span className="w-100">POD OKIEM</span>MISTRZA.
            </PageHeading>
            <div className="d-inline-flex hero__buttons">
              <Button
                type="link"
                href={links.signUp}
                text="KUP PAKIET"
                variant="primary"
              />
              <span className="d-inline-block font-italic">LUB</span>
              <div
                onClick={toggleVideo}
                className="d-flex align-items-center hero__video-trigger"
              >
                <PlayIcon />
                <span className="font-italic text-uppercase">
                  Zobacz trening
                </span>
              </div>
            </div>
          </div>
          <div className="d-none d-lg-flex justify-content-between hero__cards">
            <div className="hero__card">
              <Link to={`${links.aboutUs}#michal`}>
                <div className="card__wrapper">
                  <header className="d-flex justify-content-between align-items-center card__header">
                    <h4 className="font-italic m-0">
                      MICHAŁ <br />
                      BOBYK
                    </h4>
                    <div className="d-flex justify-content-center align-items-center svg-container">
                      <ArrowIcon />
                    </div>
                  </header>
                  <div className="d-none d-xl-block card__content">
                    <p className="m-0">
                      5 tytułów w kulturystyce, w tym Mistrz Polski w 2018 roku
                      oraz Mistrz Świata w 2019 roku.
                    </p>
                  </div>
                </div>
              </Link>
              {/* </a> */}
            </div>
          </div>
        </div>
      </div>
      <div className="position-absolute hero__image">
        <SmallAnimatedArrows />
        <SmallAnimatedArrows />
        <AnimatedArrows />
        <SmallAnimatedArrows />
        <SmallAnimatedArrows />
        <SmallAnimatedArrows />
        <div className="position-absolute border-line">
          <HeroBorders />
        </div>
        <img src={HeroImage} alt="TRENUJ POD OKIEM MISTRZA" />
      </div>
    </section>
  );
};

export default HeroSection;
