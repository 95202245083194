import PageHeading from "../headings/PageHeading";
import SlimBox from "../../assets/images/png/partners/SlimBox.png";
import Doping24 from "../../assets/images/png/partners/Doping24.png";
import "./OurPartners.scss";

const OurPartners = () => {
  return (
    <section className="our-partners">
      <div className="container">
        <div className="position-relative our-partners__wrapper">
          <header className="our-partners__header">
            <PageHeading variant="h2">PARTNERZY</PageHeading>
          </header>
          <div className="d-flex justify-content-center align-items-center our-partners__content">
            <div className="partner">
              <a className="d-block text-center text-decoration-none" href="https://www.slimbox.com.pl" target="_blank" rel="noreferrer">
                <img src={SlimBox} alt="SlimBox" />
                <span className='d-block partner-name'>SlimBox</span>
              </a>
            </div>
            <div className="partner">
              <a className="d-block text-center text-decoration-none" href="https://www.doping24.pl" target="_blank" rel="noreferrer">
                <img src={Doping24} alt="Doping24" />
                <span className='d-block partner-name'>Doping24.pl</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurPartners;

