import React, { useRef, useState, useEffect } from 'react';
import PageHeading from "../../../components/headings/PageHeading";
import { TrophyIcon, CalendarIcon2, LiftIcon, StarIcon2, PlusOne } from "../../../assets/images/svg/Icons";
import "./NumbersSection.scss";

const NumbersSection: React.FC = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const [countStart, setCountStart] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCountStart(true);
        }
      });
    }, { threshold: 1.0 });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const animateValue = (obj: HTMLElement, start: number, end: number, duration: number) => {
    let startTimestamp: number | null = null;
    const originalText = obj.textContent;

    const step = (timestamp: number) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.innerText = formatNumber(Math.floor(progress * (end - start) + start), originalText);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      } else {
        obj.innerText = formatNumber(end, originalText);
      }
    };
    window.requestAnimationFrame(step);
  };

  const formatNumber = (num: number, originalText: string | null) => {
    if (!originalText) return num.toString();

    const hasComma = originalText.includes(',');
    const hasDot = originalText.includes('.');

    let formattedNumber = num.toString();

    if (hasComma) {
      const decimalPlaces = originalText.split(',')[1]?.length || 0;
      formattedNumber = num.toFixed(decimalPlaces).replace('.', ',');
    }

    if (hasDot && !hasComma) {
      const decimalPlaces = originalText.split('.')[1]?.length || 0;
      formattedNumber = num.toFixed(decimalPlaces);
    }

    if (originalText.startsWith('+')) {
      formattedNumber = '+' + formattedNumber;
    }

    if (originalText.endsWith('%')) {
      formattedNumber = formattedNumber + '%';
    }

    return formattedNumber;
  };

  useEffect(() => {
    if (countStart) {
      document.querySelectorAll('.number').forEach((num) => {
        const value = num.textContent;
        if (value) {
          const endValue = parseFloat(value.replace(/[^0-9.]/g, ''));
          animateValue(num as HTMLElement, 0, endValue, 2000);
        }
      });
    }
  }, [countStart]);

  return (
    <section className="numbers" ref={sectionRef}>
      <div className="container">
        <div className="numbers__wrapper">
          <header className='numbers__header'>
            <PageHeading variant="h2">POPROWADZĘ CIĘ DO SUKCESU</PageHeading>
            <PageHeading variant="h3">WIEM CO ROBIĘ</PageHeading>
          </header>
          <div className="d-flex flex-wrap justify-content-between numbers__cols">
            <div className="text-center numbers__col">
              <div className="col__wrapper">
                <header className='col__header'>
                  <span className='font-italic number'>10</span>
                </header>
                <p>Tytułów w sportach fitness</p>
                <TrophyIcon />
              </div>
            </div>
            <div className="text-center numbers__col">
              <div className="col__wrapper">
                <header className='col__header'>
                  <span className='font-italic number'>+20</span>
                </header>
                <p>Lat w profesjonalnym sporcie</p>
                <CalendarIcon2 />
              </div>
            </div>
            <div className="text-center numbers__col">
              <div className="col__wrapper">
                <header className='col__header'>
                  <span className='font-italic number'>+320</span>
                </header>
                <p>Waszych udanych przemian</p>
                <LiftIcon />
              </div>
            </div>
            <div className="text-center numbers__col">
              <div className="col__wrapper">
                <header className='col__header'>
                  <span className='font-italic number'>5.0</span>
                </header>
                <p>Śr. ocena usług w Google</p>
                <StarIcon2 />
              </div>
            </div>
            <div className="text-center numbers__col">
              <div className="col__wrapper">
                <header className='col__header'>
                  <span className='font-italic number'>100%</span>
                </header>
                <p>Skuteczności moich programów</p>
                <PlusOne />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NumbersSection;

