import { Field, Form as FormikForm, Formik, FormikHelpers } from "formik";
// import { Button } from "react-bootstrap";
import { bool, object, string } from "yup";
import AgnieszkaStopka from "../../../assets/images/png/agnieszka-stopka.png";
import MichalStopka from "../../../assets/images/png/michal-stopka.png";
import { InstaIcon2 } from "../../../assets/images/svg/Icons";
import FormField from "../../../components/Form/FormField";
import PageHeading from "../../../components/headings/PageHeading";
import Loader from "../../../components/Loader/Loader";
import "./ContactForm.scss";
import axios from "axios";
import Constants from "../../../constants";
import Button from "../../../components/Buttons/LinkButton";
import ContactFormThanksModal from "../../../components/ContactFormThanksModal/ContactFormThanksModal";
import { useState } from "react";

export const initValuesContactForm = {
  name: "",
  surname: "",
  email: "",
  phone: "",
  message: "",
  termsOne: false,
  termsTwo: false,
};

const ContactForm = () => {
  const token = localStorage.getItem("_wp_react_typescipt_token");
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleSubmit = async (values: any, actions: FormikHelpers<any>) => {
    await axios
      .post(`${Constants.apiUri}/send-email`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status < 300) {
          actions.resetForm();
          handleShow();
        }
      })
      .catch((error) => {});
  };

  return (
    <section className="contact-form">
      <div className="container">
        <div className="contact-form__wrapper">
          <div className="form">
            <div className="form__inner">
              <header className="d-flex form__header">
                <div className="form__avatar">
                  <img src={MichalStopka} alt="MICHAŁ BOBYK" />
                </div>
                <div className="form__details">
                  <PageHeading variant="h3">KONTAKT DO MICHAŁA</PageHeading>
                  <div className="form__contact">
                    <a className="text-decoration-none" href="tel:530266311">
                      tel. +48 530 266 311
                    </a>
                  </div>
                  <div className="form__social">
                    <a
                      className="d-inline-flex align-items-center text-decoration-none"
                      href="https://www.instagram.com/michalbobyk"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <InstaIcon2 />
                      <span>/michalbobyk</span>
                    </a>
                  </div>
                </div>
              </header>
              <div>
                <Formik
                  onSubmit={handleSubmit}
                  validationSchema={contactFormValidationSchema}
                  initialValues={{
                    ...initValuesContactForm,
                    to: "info@healthandbodyacademy.com",
                  }}
                >
                  {({ isSubmitting }) => {
                    return (
                      <FormikForm className="form__wrapper">
                        <div className="form__wrapper_group">
                          <Field
                            type="text"
                            label="IMIĘ"
                            name="name"
                            placeholder="Twoje imię tutaj"
                            component={FormField}
                            contactForm={true}
                          />
                          <Field
                            type="text"
                            label="NAZWISKO"
                            name="surname"
                            placeholder="Twoje nazwisko tutaj"
                            component={FormField}
                            contactForm={true}
                          />
                        </div>
                        <div className="form__wrapper_group">
                          <Field
                            type="email"
                            label="E-MAIL"
                            name="email"
                            placeholder="np. jankowalski@poczta.pl"
                            component={FormField}
                            contactForm={true}
                          />
                          <Field
                            type="phone"
                            label="TELEFON (OPCJONALNE)"
                            name="phone"
                            placeholder="Podaj 9-cyfrowy numer"
                            component={FormField}
                            contactForm={true}
                          />
                        </div>
                        <Field
                          name="message"
                          type="textarea"
                          label="TWOJA WIADOMOŚĆ"
                          rows="5"
                          maxLength="800"
                          component={FormField}
                          placeholder="Hej. Jestem zainteresowany treningiem z Tobą. Odzewij się do mnie, proszę najszybciej jak to możliwe."
                        />
                        <Field
                          name="termsOne"
                          checkboxValue="Akceptuję regulamin"
                          component={FormField}
                          type="checkbox"
                        />
                        <Field
                          name="termsTwo"
                          checkboxValue="Akceptuję politykę prywatności"
                          component={FormField}
                          type="checkbox"
                        />
                        <div className="btn_wr">
                          <Button
                            variant="primary"
                            submit={true}
                            disabled={isSubmitting}
                            type="button"
                            color="blue"
                          >
                            {isSubmitting ? (
                              <Loader variant="light" />
                            ) : (
                              "ZATWIERDŹ I WYŚLIJ"
                            )}
                          </Button>
                        </div>
                      </FormikForm>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const contactFormValidationSchema = object().shape({
  name: string().required("To pole jest wymagane"),
  surname: string().required("To pole jest wymagane"),
  email: string().required("To pole jest wymagane"),
  phone: string().notRequired(),
  termsOne: bool()
    .oneOf([true], "Musisz zaakceptować warunki użytkowania")
    .required("Zaznaczenie tego pola jest wymagane"),
  termsTwo: bool()
    .oneOf([true], "Musisz zaakceptować warunki użytkowania")
    .required("Zaznaczenie tego pola jest wymagane"),
  message: string().required("To pole jest wymagane"),
});

export default ContactForm;
