import { FC } from "react";
import { CheckIcon } from "../../../assets/images/svg/Icons";
import Button from "../../../components/Buttons/LinkButton";
import links from "../../../constants/links";
import "./PackageItem.scss";

export interface IPackagepackage_item {
  id: number;
  title: string;
  duration: string;
  desc: string;
  includes: { id: number; text: string; strong: boolean }[];
  price: string;
  img: string;
  target: string;
  bestseller?: boolean;
  bestPrice?: boolean;
}

const Packagepackage_item: FC<IPackagepackage_item> = ({
  desc,
  duration,
  id,
  img,
  includes,
  price,
  title,
  target,
  bestseller,
  bestPrice,
}) => {
  return (
    <div className="package_item">
      <div className="package_item__head">
        <span className="text">
          <span>PAKIET</span> {title}
        </span>
        {bestseller && <div className="tag">BESTSELLER</div>}
        {bestPrice && <div className="tag bestseller">NAJLEPSZA CENA</div>}
      </div>
      <div className="package_item__image">
        <img src={img} alt={`${title}-img`} />
      </div>
      <div className="package_item__content">
        <span>
          Czas trwania: <b>{duration}</b>{" "}
        </span>
        <span className="target">
          Dla kogo: <b>{target}</b>{" "}
        </span>
        <p className="package_item__content-desc">{desc}</p>
        <ul>
          {includes.map((package_item) => {
            return (
              <li
                key={package_item.id}
                className={`${package_item.strong ? "strong" : ""}`}
              >
                <span className="icon_wr">
                  <CheckIcon />
                </span>
                {package_item.text}
              </li>
            );
          })}
        </ul>
        <span className="package_item__content-transaction">
          <span className="price">
            Cena: <span>{price} ZŁ</span>
          </span>
          <div className="btn_wr">
            <Button
              href={links.signUp}
              text="KUP TERAZ"
              type="link"
              variant="primary"
            />
          </div>
        </span>
      </div>
    </div>
  );
};

export default Packagepackage_item;
