import { Link, useLocation } from "react-router-dom";
import "./PagesBreadcrumbs.scss";

const PagesBreadcrumbs = () => {
  const { pathname } = useLocation();
  const pathnames = pathname.split("/").filter((x) => x);

  return (
    <section className="breadcrumbs breadcrumbs--page">
      <div className="container">
        <div className="d-flex justify-content-center align-items-center flex-wrap breadcrumbs__wrapper">
          <Link to="/">Strona główna</Link>
          {pathnames.map((name, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
            const isLast = index === pathnames.length - 1;

            const prettyName = name
              .replace(/-/g, " ")
              .replace(/^\w/, (c) => c.toUpperCase());

            return (
              <div className='d-flex align-items-center' key={`${name}-${index}`}>
                <div className="svg-container">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 12L10 8L6 4"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {isLast ? (
                  <span className="d-block" key={name}>
                    {prettyName}
                  </span>
                ) : (
                  <Link key={name} to={routeTo}>
                    {prettyName}
                  </Link>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default PagesBreadcrumbs;
