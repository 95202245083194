import React, { useEffect, useState, useRef } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import transformation1 from "../../../assets/images/png/transformations/transformation-1.png";
import transformation2 from "../../../assets/images/png/transformations/transformation-2.png";
import transformation3 from "../../../assets/images/png/transformations/transformation-3.png";
import transformation4 from "../../../assets/images/png/transformations/transformation-4.png";
import transformation5 from "../../../assets/images/png/transformations/transformation-5.png";
import transformation6 from "../../../assets/images/png/transformations/transformation-6.png";
import transformation7 from "../../../assets/images/png/transformations/transformation-7.png";
import transformation8 from "../../../assets/images/png/transformations/transformation-8.png";
import transformation9 from "../../../assets/images/png/transformations/transformation-9.jpg";
import {
  BicepsIconLeft,
  BicepsIconRight,
  CarouselArrowIcon,
} from "../../../assets/images/svg/Icons";
import PageHeading from "../../../components/headings/PageHeading";
import "./ResultsSliderSection.scss";
import { FC } from "react";

interface IResultsSlider {
  title?: string;
  desc?: string;
  darkBg?: boolean;
}

const ResultsSlider: FC<IResultsSlider> = ({
  title = "ZOBACZ REZULTATY!",
  desc = "Trening pod moją opieką to wyzwanie, które zmieni Wasze życie na lepsze. To czas, żeby zrealizować swoje marzenia o zdrowym ciele. Pomogłem już setkom ludzi takim jak Wy.",
  darkBg = false,
}) => {
  const splideRef = useRef<Splide | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const numberOfSlides = 6;

  const splideOptions = {
    autoWidth: true,
    arrows: false,
    pagination: false,
    gap: 24,
    perPage: 1,
    classes: {
      arrows: "slider__arrows",
      arrow: "slider__arrow",
      prev: "slider__arrow--prev",
      next: "slider__arrow--next",
    },
  };

  const handlePrevClick = () => {
    if (splideRef.current?.splide) {
      const currentIndex = splideRef.current.splide.index;
      if (currentIndex > 0) {
        splideRef.current.splide.go("<");
      }
    }
  };

  const handleNextClick = () => {
    if (splideRef.current?.splide) {
      const currentIndex = splideRef.current.splide.index;
      if (currentIndex < numberOfSlides - 1) {
        splideRef.current.splide.go(">");
      }
    }
  };

  useEffect(() => {
    const splideInstance = splideRef.current?.splide;

    if (splideInstance) {
      const handleMove = () => {
        const currentIndex = splideInstance.index ?? 0;
        const actualIndex = currentIndex % numberOfSlides;
        setActiveIndex(actualIndex);
      };

      splideInstance.on("move", handleMove);

      return () => {
        if (splideInstance) {
          splideInstance.off("move");
        }
      };
    }
  }, [numberOfSlides]);

  const selectSlide = (index: number) => {
    if (splideRef.current?.splide) {
      splideRef.current.splide.go(index);
    }
  };

  return (
    <section className={`results ${darkBg ? "dark" : ""}`}>
      <div className="container">
        <div className="results__wrapper">
          <header className="results__header">
            <div className="header__content">
              <span className="d-block font-italic text-center">
                TO DZIAŁA!
              </span>
              <div className="heading__wrapper">
                <BicepsIconLeft />
                <PageHeading variant="h2">{title}</PageHeading>
                <BicepsIconRight />
              </div>
              <p>{desc}</p>
            </div>
          </header>
          <div className={`results__slider ${darkBg ? "dark" : ""}`}>
            <Splide ref={splideRef} options={splideOptions}>
              <SplideSlide>
                <div className="slide__wrapper">
                  <div className="slide__content">
                    <img src={transformation1} alt="transformation" />
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="slide__wrapper">
                  <div className="slide__content">
                    <img src={transformation2} alt="transformation" />
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="slide__wrapper">
                  <div className="slide__content">
                    <img src={transformation3} alt="transformation" />
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="slide__wrapper">
                  <div className="slide__content">
                    <img src={transformation4} alt="transformation" />
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="slide__wrapper">
                  <div className="slide__content">
                    <img src={transformation5} alt="transformation" />
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="slide__wrapper">
                  <div className="slide__content">
                    <img src={transformation6} alt="transformation" />
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="slide__wrapper">
                  <div className="slide__content">
                    <img src={transformation7} alt="transformation" />
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="slide__wrapper">
                  <div className="slide__content">
                    <img src={transformation8} alt="transformation" />
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="slide__wrapper">
                  <div className="slide__content">
                    <img src={transformation9} alt="transformation" />
                  </div>
                </div>
              </SplideSlide>
            </Splide>
            <div className="position-relative d-flex justify-content-center align-items-center slider__navigation">
              <div className="slider__arrows">
                <button
                  className="position-absolute px-0 slider__arrow slider__arrow--prev"
                  onClick={handlePrevClick}
                >
                  <CarouselArrowIcon />
                </button>
                <button
                  className="position-absolute px-0 slider__arrow slider__arrow--next"
                  onClick={handleNextClick}
                >
                  <CarouselArrowIcon />
                </button>
              </div>
              <div className="slider__pagination">
                {Array.from({ length: numberOfSlides }, (_, index) => (
                  <button
                    key={index}
                    className={`px-0 item ${
                      index === activeIndex ? "active" : ""
                    }`}
                    onClick={() => selectSlide(index)}
                  >
                    <span className="d-inline-block rounded-circle"></span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResultsSlider;
