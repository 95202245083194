type Plan = {
  [key: string]: {
    name: string;
    exercises: { name: string; series: number; repeat: string }[];
  };
};

export const TwoDaysPlanWoman: Plan = {
  day1: {
    name: "Dzień pierwszy",
    exercises: [
      {
        name: "Wykroki z hantelkami",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Martwy ciąg z hantlami",
        series: 3,
        repeat: "12-8",
      },
      {
        name: "Rozpiętki leżąc na ławce poziomej z hantelkami /lub rozpiętki na maszynie butterfly",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Wznosy ramion na boki stojąc z hantelkami",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Prostowanie ramion stojąc linka wyciągu górnego",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Spięcia brzucha na maszynie",
        series: 3,
        repeat: "20-10",
      },
    ],
  },
  day2: {
    name: "Dzień drugi - nogi, 'pull' ruchy przyciągające, brzuch",
    exercises: [
      {
        name: "Wyprosty nóg siedząc na maszynie",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Przysiad ze sztangą na karku",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Hip thrusty ze sztangą",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Ściąganie drążka wyciągu górnego nachwytem",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Wiosłowanie sztangą w opadzie tułowia",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Uginanie ramion ze sztangą stojąc",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Wznosy nóg przy drabinkach",
        series: 3,
        repeat: "20-10",
      },
    ],
  },
};

export const ThreeDaysPlanWoman: Plan = {
  day1: {
    name: "Dzień pierwszy – nogi, 'push' ruchy wypychające, brzuch",
    exercises: [
      {
        name: "Wykroki chodzone z hantelkami",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Martwy ciąg z hantlami",
        series: 3,
        repeat: "12-8",
      },
      {
        name: "Rozpiętki leżąc na ławce poziomej z hantelkami /lub rozpiętki na maszynie butterfly",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Wyciskanie hantli siedząc nad głowę",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Wznosy ramion na boki stojąc z hantelkami",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Wznosy nóg przy drabinkach",
        series: 3,
        repeat: "20-10",
      },
    ],
  },
  day2: {
    name: "Dzień drugi – nogi, 'pull' ruchy przyciągające, brzuch",
    exercises: [
      {
        name: "Przysiad ze sztangą na karku",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Hip thrusty ze sztangą",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Ściąganie drążka nachwytem na mięśnie pleców",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Wiosłowanie sztangą w opadzie tułowia /lub wiosłowanie na maszynie hummer w poziomie",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Przyciąganie linki wyciągu dolnego chwytem neutralnym",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Spięcia brzucha na maszynie",
        series: 3,
        repeat: "20-10",
      },
    ],
  },
  day3: {
    name: "Dzień trzeci – nogi, ramiona, brzuch",
    exercises: [
      {
        name: "Martwy ciąg sumo ze sztangą",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Uginanie nóg na maszynie leżąc",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Prostowanie ramion stojąc linka wyciągu górnego",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Uginanie ramion ze sztangą stojąc",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Wznosy nóg przy drabinkach",
        series: 3,
        repeat: "20-10",
      },
      {
        name: "Skręty tułowia stojąc z drążkiem na karku",
        series: 3,
        repeat: "15-10",
      },
    ],
  },
};

export const FourDaysPlanWoman: Plan = {
  day1: {
    name: "Dzień pierwszy – nogi, brzuch",
    exercises: [
      {
        name: "Uginanie nóg na maszynie leżąc",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Hip thrusty ze sztangą",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Zakroki ze sztangą na suwnicy „Smith”",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Spięcia brzucha na maszynie",
        series: 3,
        repeat: "20-10",
      },
      {
        name: "Skręty tułowia stojąc z drążkiem na karku",
        series: 3,
        repeat: "15-10",
      },
    ],
  },
  day2: {
    name: "Dzień drugi - 'push' ruchy wypychowe (klatka, barki, triceps)",
    exercises: [
      {
        name: "Rozpiętki leżąc na ławce poziomej z hantelkami /lub rozpiętki na maszynie butterfly",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Wyciskanie hantli leżąc na ławce skos dodatni",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Wznosy ramion na boki stojąc z hantelkami",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Wyciskanie hantli siedząc nad głowę",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Prostowanie ramion stojąc z linka wyciągu górnego",
        series: 3,
        repeat: "15-10",
      },
    ],
  },
  day3: {
    name: "Dzień trzeci – nogi, brzuch",
    exercises: [
      {
        name: "Wyprosty nóg siedząc na maszynie",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Przysiad ze sztangą na karku",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Wykroki chodzone z hantelkami",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Wznosy nóg przy drabinkach",
        series: 3,
        repeat: "20-10",
      },
      {
        name: "Skręty tułowia stojąc z drążkiem na karku",
        series: 3,
        repeat: "15-10",
      },
    ],
  },
  day4: {
    name: "Dzień czwarty – 'pull' ruchy przyciągające (plecy, biceps)",
    exercises: [
      {
        name: "ściąganie drążka nachwytem na mięśnie pleców",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Wiosłowanie sztangą w opadzie tułowia /lub wiosłowanie na maszynie hummer w poziomie",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Przyciąganie linki wyciągu dolnego chwytem neutralnym",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Przyciąganie liny wyciągu górnego w bramie do ud",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Uginanie ramion ze sztangą stojąc",
        series: 3,
        repeat: "15-10",
      },
    ],
  },
};

export const TwoDaysPlanMan: Plan = {
  day1: {
    name: "Dzień pierwszy 'push' – ruchy wypychowe (klatka, barki, triceps) + brzuch",
    exercises: [
      {
        name: "Wyciskanie sztangi lezac na ławce poziomej",
        series: 3,
        repeat: "8-12",
      },
      {
        name: "Wyciskanie hantli lezac na lawce skos dodatni",
        series: 3,
        repeat: "10-15",
      },
      {
        name: "Rozpietki lezac na lawce poziomej z hantelkami /lub rozpietki na maszynie butterfly",
        series: 3,
        repeat: "10-15",
      },
      {
        name: "Wyciskanie hantli siedzac nad glowe /lub wyciskanie na maszynie hummer w pionie",
        series: 3,
        repeat: "8-12",
      },
      {
        name: "Wznosy ramion na boki stojąc z hantelkami",
        series: 3,
        repeat: "8-12",
      },
      {
        name: "Prostowanie ramion stojąc z linka wyciągu górnego",
        series: 3,
        repeat: "8-12",
      },
      {
        name: "Prostowanie hantelka jednoracz w oparciu o ławeczkę",
        series: 3,
        repeat: "8-12",
      },
      {
        name: "Spięcia brzucha na maszynie",
        series: 3,
        repeat: "8-12",
      },
    ],
  },
  day2: {
    name: "Dzień drugi 'pull' – ruchy przyciągające (plecy, biceps) + nogi",
    exercises: [
      {
        name: "Ściąganie drążka wyciągu górnego nachwytem",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Wiosłowanie sztangą w opadzie tułowia /lub wiosłowanie na maszynie hummer w poziomie",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Martwy ciąg ze sztangą /lub wyprosty z opadu na ławce rzymskiej",
        series: 3,
        repeat: "12-8",
      },
      {
        name: "Wyprosty nóg siedząc na maszynie",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Przysiad ze sztangą na karku /lub przysiad na hak-maszynie",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Wykroki z hantelkami",
        series: 3,
        repeat: "20-15 na każdą nogę",
      },
      {
        name: "Uginanie ramion ze sztangą stojąc",
        series: 3,
        repeat: "12-8",
      },
      {
        name: "Zgięcia ramion ze sztangą na modlitewniku",
        series: 3,
        repeat: "15-10",
      },
    ],
  },
};

export const ThreeDaysPlanMan: Plan = {
  day1: {
    name: "Dzień pierwszy 'push' – ruchy wypychowe (klatka, barki, triceps)",
    exercises: [
      {
        name: "Wyciskanie sztangi lezac na ławce poziomej ",
        series: 3,
        repeat: "8-12",
      },
      {
        name: "Wyciskanie hantli lezac na lawce skos dodatni",
        series: 3,
        repeat: "10-15",
      },
      {
        name: "Rozpietki lezac na lawce poziomej z hantelkami /lub rozpietki na maszynie butterfly",
        series: 3,
        repeat: "10-15",
      },
      {
        name: "Wyciskanie hantli siedzac nad glowe /lub wyciskanie na maszynie hummer w pionie",
        series: 3,
        repeat: "8-12",
      },
      {
        name: "Wznosy ramion na boki stojac z hantelkami",
        series: 3,
        repeat: "10-15",
      },
      {
        name: "Prostowanie ramion stojac z linka wyciagu gornego",
        series: 3,
        repeat: "10-15",
      },
      {
        name: "prostowanie hantelka jednoracz w oparciu o ławeczkę",
        series: 3,
        repeat: "10-15",
      },
    ],
  },
  day2: {
    name: "Dzień drugi 'pull' – ruchy przyciagajace (plecy, biceps, tyl barków)",
    exercises: [
      {
        name: "Ściaganie drazka wyciagu gornego nachwytem",
        series: 3,
        repeat: "10-15",
      },
      {
        name: "Wioslowanie sztanga w opadzie tlowia /lub wiosłowanie na maszynie hummer w poziomie",
        series: 3,
        repeat: "10-15",
      },
      {
        name: "Przyciaganie w pionie na maszynie hummer",
        series: 3,
        repeat: "10-15",
      },
      {
        name: "Odwodzenie ramion na barki w opadzie tułowania",
        series: 3,
        repeat: "10-15",
      },
      {
        name: "Martwy ciag ze sztanga /lub wyprosty z opadu na lawce rzymskiej",
        series: 3,
        repeat: "8-12",
      },
      {
        name: "Zgiecia ramion z hantelkami podchwytem stojac",
        series: 3,
        repeat: "8-12",
      },
      {
        name: "Zgiecia ramion ze sztanga na modlitewniku",
        series: 3,
        repeat: "10-15",
      },
    ],
  },
  day3: {
    name: "Dzień trzeci 'legs' – nogi i brzuch",
    exercises: [
      {
        name: "Wyprosty nóg siedzac na maszynie",
        series: 3,
        repeat: "10-15",
      },
      {
        name: "Uginanie nóg na maszynie leżąc",
        series: 3,
        repeat: "10-15",
      },
      {
        name: "Przysiad ze sztanga na karku /lub przysiad na hak-maszynie",
        series: 3,
        repeat: "10-15",
      },
      {
        name: "Wykroki z hantelkami",
        series: 3,
        repeat: "15-20 na nogę",
      },
      {
        name: "Wspiecia na palce na suwnicy (łydki)",
        series: 3,
        repeat: "10-20",
      },
      {
        name: "Wznosy nóg przy drabinkach",
        series: 3,
        repeat: "10-20",
      },
    ],
  },
};

export const FourDaysPlanMan: Plan = {
  day1: {
    name: "Dzień pierwszy – klatka + bicepsy",
    exercises: [
      {
        name: "Wyciskanie sztangi leżąc na ławce poziomej",
        series: 3,
        repeat: "12-8",
      },
      {
        name: "Wyciskanie hantli leżąc na ławce skos dodatni",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Wyciskanie na maszynie hummer",
        series: 3,
        repeat: "12-18",
      },
      {
        name: "Rozpiętki leżąc na ławce poziomej z hantelkami /lub rozpiętki na maszynie butterfly",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Zgięcia ramion hantelkami chwytem młotkowym",
        series: 3,
        repeat: "12-8",
      },
      {
        name: "Zgięcia ramion ze sztangą łamaną na modlitewniku",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Zgięcia ramion oburącz w bramie",
        series: 3,
        repeat: "15-10",
      },
    ],
  },
  day2: {
    name: "Dzień drugi – plecy + brzuch",
    exercises: [
      {
        name: "Ściąganie drążka wyciągu górnego nachwytem",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Wiosłowanie sztangą w opadzie tułowia /lub wiosłowanie na maszynie hummer w poziomie",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Przyciąganie w pionie na maszynie hummer",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Martwy ciąg ze sztangą /lub wyprosty z opadu na ławce rzymskiej",
        series: 3,
        repeat: "12-8",
      },
      {
        name: "Przyciąganie lin wyciągu górnego w bramie do ud",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Spięcia brzucha na maszynie",
        series: 3,
        repeat: "20-10",
      },
      {
        name: "Wznosy nóg przy drabinkach",
        series: 3,
        repeat: "20-10",
      },
    ],
  },
  day3: {
    name: "Dzień trzeci – barki + tricepsy",
    exercises: [
      {
        name: "Wyciskanie hantli siedząc nad głowę /lub wyciskanie na maszynie hummer w pionie",
        series: 3,
        repeat: "12-8",
      },
      {
        name: "Wyciskanie na barki na maszynie hammer",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Wznosy ramion na boki stojąc z hantelkami",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Odwodzenie ramion na barki w opadzie tulowania",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Prostowanie ramion stojąc z linką wyciągu górnego",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Wyciskanie francuskie sztangą łamaną od czoła leżąc na ławce poziomej",
        series: 3,
        repeat: "12-8",
      },
      {
        name: "Prostowanie hantelka jednoracz w oparciu o ławeczkę",
        series: 3,
        repeat: "15-10",
      },
    ],
  },
  day4: {
    name: "Dzień czwarty – nogi + łydki",
    exercises: [
      {
        name: "Wyprosty nóg siedząc na maszynie",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Zgięcia nóg leżąc na maszynie",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Przysiad ze sztangą na karku /lub przysiad na hak-maszynie",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Wykroki z hantelkami",
        series: 3,
        repeat: "20-15 na nogę",
      },
      {
        name: "Wypychanie jednonoz na maszynie przodem",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Przywodzenie nóg siedząc na maszynie",
        series: 3,
        repeat: "15-10",
      },
      {
        name: "Wspięcia na palce na suwnicy (łydki)",
        series: 3,
        repeat: "20-10",
      },
    ],
  },
};
