import { Field } from "formik";
import React from "react";
import "./InputField.scss";

interface IInputField {
  type: string;
  name: string;
  rows?: number;
  cols?: number;
  defaultValue?: string;
  label: string;
  placeholder?: string;
  labelIcon?: string;
  span?: string;
  component: any;
}

const InputField: React.FC<IInputField> = (props) => {
  return <Field {...props} />;
};

export default InputField;
