import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchProducts } from "../../actions/wp";
import { useWpSelector } from "../../selectors";
import Loader from "../Loader/Loader";

import ProductList from "./ProductList";
import "./Products.scss";

interface IProducts {
  onClick: (name: string) => void;
  addToCart: (productId: number) => void;
}

const Products: React.FC<IProducts> = ({ onClick, addToCart }) => {
  const dispatch = useDispatch();
  const { products } = useWpSelector();

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  return (
    <div className="plans">
      <div className="d-flex flex-wrap plans__wrapper">
        {products && products.length > 0 ? (
          <ProductList
            onClick={onClick}
            addToCart={addToCart}
            products={products}
          />
        ) : (
          <div className="loading-overlay">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default Products;
