import RootLayout from "../../layouts/RootLayout";
import Hero from "./sections/Hero";
import Metamorphoses from "./sections/MetamorphosesSection";
import Cta from "../../components/Cta/Cta";
import PagesBreadcrumbs from "../../components/PagesBreadcrumbs/PagesBreadcrumbs";

const MetamorphosesPage = () => {
  return (
    <RootLayout>
      <main id="main">
        <PagesBreadcrumbs />
        <Hero />
        <Metamorphoses />
        <Cta />
      </main>
    </RootLayout>
  );
};

export default MetamorphosesPage;
