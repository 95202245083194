export const formatDate = (dateStr: string) => {
  const date = new Date(dateStr.split(".").reverse().join("-"));
  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();
  let year = date.getFullYear().toString().substr(-2);

  day = day.length < 2 ? "0" + day : day;
  month = month.length < 2 ? "0" + month : month;

  return `${day}.${month}.${year}`;
};
