import RootLayout from "../../layouts/RootLayout";
import PagesBreadcrumbs from "../../components/PagesBreadcrumbs/PagesBreadcrumbs";
import Hero from "./sections/Hero";
import ContactForm from "./sections/ContactForm";

const ContactPage = () => {
  return (
    <RootLayout>
      <main id="main">
        <PagesBreadcrumbs />
        <Hero />
        <ContactForm />
      </main>
    </RootLayout>
  );
};

export default ContactPage;
