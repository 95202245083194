const baseUri = "https://panel.healthandbodyacademy.com";
// const baseUri = "http://localhost/health-and-body-academy/wordpress";

const Constants = {
  baseUri,
  apiUri: `${baseUri}/wp-json/wp/v2`,
  jwtAuthUri: `${baseUri}/wp-json/jwt-auth/v1/token`,
  WC: `${baseUri}/wp-json/wc/v3`,
  consumer_key: `ck_87a6076f0326b3f5612636f58614616f259afa8e`,
  consumer_secret: `cs_d0cd03eefde52f7dbd25b5d191ce5cae278ba103`,
  guestName: `guest`,
  guestPasswd: `guest`,
  p24_username: `235900`,
  p24_userpass: `d67871bbb966990bec17ac2906b26d05`,
  p24_crc: "929c4f4c66ca54eb",
};

export default Constants;
