import axios from 'axios';
import React, { useState, useEffect } from 'react';
import * as CryptoJS from 'crypto-js';
import RootLayout from "../../layouts/RootLayout";
import Constants from "../../constants";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import PageHeading from "../../components/headings/PageHeading";
import "./ThankYou.scss";

const ThankYou = () => {
  const [transactionStatus, setTransactionStatus] = useState<boolean | null>(null);

  useEffect(() => {
    const getQueryParam = (param: string) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    };

    const id = getQueryParam('id');
    const sessionId = getQueryParam('sessionId');
    if (sessionId && id) {
      const apiUrl = `${Constants.baseUri}/wp-json/myapp/v1/get-transaction/?sessionId=${sessionId}`;
      const username = `${Constants.p24_username}`;
      const password = `${Constants.p24_userpass}`;
      const basicAuth = 'Basic ' + btoa(username + ':' + password);
      const headers = { 'Authorization': basicAuth };

      axios.get(apiUrl)
      .then(response => {
        const transactionData = response.data;
        if (transactionData.data.status === 1) {
          setTransactionStatus(true);
          updateOrderStatus(id, 'completed');
        }
        else {
          setTransactionStatus(false);
        }
      })
      .catch(error => {
        console.error('Błąd podczas pobierania danych transakcji:', error);
      });
    }
  }, []);

  const updateOrderStatus = async (orderId: string, newStatus: string) => {
    const wcApiUrl = `${Constants.WC}/orders/${orderId}`;
    const consumerKey = Constants.consumer_key;
    const consumerSecret = Constants.consumer_secret;
    const basicAuth = 'Basic ' + btoa(`${consumerKey}:${consumerSecret}`);
    const headers = { 'Authorization': basicAuth };

    try {
      const updateResponse = await axios.put(
        wcApiUrl,
        { status: newStatus },
        { headers }
      );
    } catch (error) {
      console.error('Błąd aktualizacji statusu zamówienia:', error);
    }
  };

  return (
    <RootLayout>
      <main id="main">
        <section className='thank-you'>
          <div className="thank-you__wrapper">
            <div className="container">

              {transactionStatus === null && (
                <>
                  <PageHeading variant="h1">
                    TRANSAKCJA <span>PRZETWARZANA!</span>
                  </PageHeading>
                  <p className="text-center form-final-paragraph">
                    Twoja transakcja jest obecnie przetwarzana. Dostęp do konta będzie możliwy,
                    jak tylko transakcja zostanie zaksięgowana.
                  </p>
                </>
              )}

              {transactionStatus === true && (
                <>
                  <PageHeading variant="h1">
                    <span>SUPER!</span> ZACZYNAMY
                  </PageHeading>
                  <p className="text-center form-final-paragraph">
                    Dzięki za zaufanie! Twoje konto zostało aktywowane. Możesz teraz przejść
                    do Panelu Klienta aby podejrzeć swój program treningowy lub wygenerować
                    plan żywieniowy.
                    <strong> Nie czekaj na motywację i zacznij już teraz!</strong>
                  </p>
                </>
              )}

              {transactionStatus === false && (
                <>
                  <PageHeading variant="h1">
                    TRANSAKCJA <span>NIEUDANA</span>
                  </PageHeading>
                  <p className="text-center form-final-paragraph">
                    Niestety, wystąpił problem z przetwarzaniem Twojej transakcji.
                    Prosimy o kontakt z obsługą klienta lub spróbuj ponownie później.
                  </p>
                </>
              )}

              <div className="wr-buttons-final-step">
                <Link to="/">
                  <Button variant="secondary" className="btn-final-primary">
                    STRONA GŁOWNA
                  </Button>
                </Link>
                <Link to="/logowanie">
                  <Button variant="primary">PANEL KLIENTA</Button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </main>
    </RootLayout>
  );
};

export default ThankYou;
