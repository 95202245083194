import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import { useWpSelector } from "../../../../../selectors";
import { FileDockIcon } from "../../../../../assets/images/svg/Icons";
import TrainerRaports from "../../../../../components/TrainerRaports/TrainerRaports";
import Constants from "../../../../../constants";
import "./ClientRaports.scss";

interface IClientRaports {
  userId: number;
}

const ClientRaports: FC<IClientRaports> = ({ userId }) => {
  const { currentUser } = useWpSelector();
  const token = localStorage.getItem("_wp_react_typescipt_token");
  const [raports, setRaports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchLatestRaports = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${Constants.apiUri}/reports?author=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              //   per_page: itemsPerPage, // Liczba użytkowników do pobrania
              orderby: "date", // Sortowanie według daty rejestracji
              order: "desc",
              //   page: currentPage,
            },
          }
        );

        setRaports(response.data);
        setError("");
      } catch (err) {
        setError("Przepraszamy, coś poszło nie tak, spróbuj ponownie później");
      } finally {
        setIsLoading(false);
      }
    };

    fetchLatestRaports();
  }, [token, userId]);

  return (
    <div className="user__raports raports">
      <span className="raports__amount">
        <FileDockIcon />
        Raporty ({raports.length})
      </span>
      <TrainerRaports
        raports={raports}
        isLoading={isLoading}
        error={error}
        displayName={false}
        currentUser={currentUser}
      />
    </div>
  );
};

export default ClientRaports;
