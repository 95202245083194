import RootLayout from "../../layouts/RootLayout";
import Hero from "./sections/Hero";
import FaqForm from "./sections/FaqForm";
import PagesBreadcrumbs from "../../components/PagesBreadcrumbs/PagesBreadcrumbs";

const FaqPage = () => {
  return (
    <RootLayout>
      <main id="main">
        <PagesBreadcrumbs />
        <Hero />
        <FaqForm />
      </main>
    </RootLayout>
  );
};

export default FaqPage;
