import React from "react";
import { useLocation } from "react-router-dom";
import {
  HomeFillingIcon,
  TargetFillIcon,
  PackageIcon,
  HumanFillIcon,
  ChatFillIcon,
  ReportFillIcon,
  PlayFillIcon,
  RestaurantFillIcon,
  SettingsFillIcon,
  UserFillIcon,
} from "../../../assets/images/svg/Icons";
import links from "../../../constants/links";

export const useCurrentPathNav = (withSvg: boolean) => {
  const { pathname } = useLocation();
  const renderCurrentPath = () => {
    switch (String(pathname)) {
      case String(links.dashboard):
        return (
          <span>
            {withSvg && <HomeFillingIcon />}
            <span>Twój Panel</span>
          </span>
        );
      case links.panelTarget:
        return (
          <span>
            {withSvg && <TargetFillIcon />}
            <span>Twój Cel</span>
          </span>
        );
      case links.panelPackages:
        return (
          <span>
            {withSvg && <PackageIcon />}
            <span>Twoje Pakiety</span>
          </span>
        );
      case links.panelBody:
        return (
          <span>
            {withSvg && <HumanFillIcon />}
            <span>Twoje Ciało</span>
          </span>
        );
      case links.panelMessages:
      case links.trainerMessages:
        return (
          <span>
            {withSvg && <ChatFillIcon />}
            <span>Wiadomości</span>
          </span>
        );
      case links.panelRaports:
      case links.trainerRaports:
        return (
          <span>
            {withSvg && <ReportFillIcon />}
            <span>Raporty</span>
          </span>
        );
      case links.panelVideo:
        return (
          <span>
            {withSvg && <PlayFillIcon />}
            <span>Ćwiczenia Wideo</span>
          </span>
        );
      case links.panelDiet:
        return (
          <span>
            {withSvg && <RestaurantFillIcon />}
            <span>Kreator Diet</span>
          </span>
        );
      case links.panelSettings:
      case links.trainerSettings:
        return (
          <span>
            {withSvg && <SettingsFillIcon />}
            <span>Ustawienia</span>
          </span>
        );
      case links.trainerClients:
        return (
          <span>
            {withSvg && <UserFillIcon />}
            <span>Lista Klientów</span>
          </span>
        );
      case links.panelTraining:
        return (
          <span>
            {withSvg && <HumanFillIcon />}
            <span>Trening</span>
          </span>
        );
      default:
        return "";
    }
  };

  return {
    renderCurrentPath,
  };
};
