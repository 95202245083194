import { useWpSelector } from "../../../../selectors";
import TrainingPlan from "./elements/TrainingPlan";
import "./TrainingPage.scss";

const TrainingPage = () => {
  const { currentUser } = useWpSelector();

  const renderCardioInfo = () => {
    let alertText = "";
    switch (currentUser?.meta.goal) {
      case "20":
        alertText =
          "Pamiętaj aby do każdego treningu dodać 10 minut treningu cardio";
        break;
      case "18":
        alertText =
          "Pamiętaj aby do każdego treningu dodać 15-20 minut treningu cardio";
        break;
      case "9":
        alertText =
          "Pamiętaj aby do każdego treningu dodać 35-40 minut treningu cardio";
        break;
      default:
        alertText =
          "Pamiętaj aby do każdego treningu dodać około 20 minut treningu cardio";
        break;
    }

    return <div className="p-2 alert_training__page">{alertText}</div>;
  };

  return (
    <section className="mb-4 pb-4">
      <p>{renderCardioInfo()}</p>
      <TrainingPlan />
    </section>
  );
};

export default TrainingPage;
