import React from "react";
import { Link } from "react-router-dom";
import "./LinkButton.scss";

interface IButton {
  type: "button" | "link";
  submit?: boolean;
  onClick?: () => void;
  text?: string;
  variant: "primary" | "secondary";
  href?: string;
  color?: "red" | "blue";
  children?: any;
  disabled?: boolean;
}

const Button: React.FC<IButton> = ({
  href,
  text,
  variant,
  type,
  onClick,
  color,
  submit,
  children,
  disabled,
}) => {
  if (type === "link") {
    return (
      <Link
        to={href ? href : ""}
        className={`${
          variant === "primary"
            ? "button_link_primary"
            : "button_link_secondary"
        } ${color === "blue" ? "blue" : ""}`}
      >
        {text ? text : children}
      </Link>
    );
  }

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type={submit ? "submit" : "button"}
      className={`${
        variant === "primary" ? "button_link_primary" : "button_link_secondary"
      } ${color === "blue" ? "blue" : ""}`}
    >
      {text ? text : children}
    </button>
  );
};

export default Button;
