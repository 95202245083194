import React from 'react';
import AccountInfo from './AccountInfo';

const Introduction = () => {
  return (
    <AccountInfo />
  );
};

export default Introduction;
