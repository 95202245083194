import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useWpSelector } from "../../selectors";
import { fetchGoals } from "../../actions/wp";
import GoalList from "./GoalList";
import "./Goals.scss";
import Loader from "../Loader/Loader";

interface IGoals {
  onClick: (name: string) => void;
}

const Goals: React.FC<IGoals> = ({ onClick }) => {
  const dispatch = useDispatch();
  const { goals } = useWpSelector();

  useEffect(() => {
    dispatch(fetchGoals());
  }, [dispatch]);

  return (
    <div className="goals">
      <div className="d-flex flex-wrap justify-content-center goals__wrapper">
        {goals.length > 0 ? (
          <GoalList onClick={onClick} goals={goals} />
        ) : (
          <div className="loading-overlay">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default Goals;
