import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { AllowedRoles, fetchUsers, User } from "../../../../actions/wp";
import { UserFillIcon, ChevronIcon } from "../../../../assets/images/svg/Icons";
import Loader from "../../../../components/Loader/Loader";
import { useWpSelector } from "../../../../selectors";
import UserMessages from "./elements/UserMessages";
import "./TrainerMessagesPage.scss";

const TrainerMessagesPage = () => {
  const [userMessageId, setUserMessageId] = useState<null | number>(null);
  const [usersListShow, setUsersListShow] = useState(false);
  const dispatch = useDispatch();
  const { users } = useWpSelector();

  useEffect(() => {
    dispatch(fetchUsers((loading: boolean) => {}));

    return () => {
      setUserMessageId(null);
    };
  }, [dispatch]);

  const handleClickUser = (id: number) => {
    setUserMessageId(id);
    setUsersListShow(false);
  };

  const handleShowUsersList = () => {
    setUsersListShow(!usersListShow);
  };

  const clientsWithChat = users?.filter(
    (usr) =>
      !usr.roles.includes(AllowedRoles.admin) &&
      usr.meta.plan !== "30" &&
      !usr.roles.includes(AllowedRoles.guest) &&
      usr.roles.length > 0
  );

  const displayUser = () => {
    return clientsWithChat?.find((item) => item.id === userMessageId)?.name;
  };

  return (
    <div className="messages">
      <div className={`messages__users ${usersListShow ? "show" : ""}`}>
        <div className={`current__message_user `} onClick={handleShowUsersList}>
          <span className="current__message_user-name">
            <UserFillIcon />
            {userMessageId ? displayUser() : "Wybierz podopiecznego"}
          </span>
          <span
            className={`current__message_user-icon ${
              usersListShow ? "show" : ""
            }`}
          >
            <ChevronIcon />
          </span>
        </div>
        <ul className={`users__list ${usersListShow ? "show" : ""}`}>
          {clientsWithChat ? (
            clientsWithChat?.map((user: User) => {
              const avatarUrl = user.avatar_urls ? user.avatar_urls[96] : null;

              return (
                <li
                  key={user.id}
                  onClick={() => handleClickUser(user.id)}
                  className="users__list-item"
                >
                  <div className="position-relative d-flex justify-content-center align-items-center ">
                    {avatarUrl ? (
                      <img src={avatarUrl} alt="Account avatar" />
                    ) : (
                      "..."
                    )}
                  </div>
                  {user.name}
                </li>
              );
            })
          ) : (
            <Loader />
          )}
        </ul>
      </div>
      <div className="messages__content">
        <UserMessages id={userMessageId} />
      </div>
    </div>
  );
};

export default TrainerMessagesPage;
