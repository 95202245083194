import { FC } from "react";
import { ChevronIcon } from "../../assets/images/svg/Icons";
import "./Pagination.scss";

interface IPagination {
  currentPage: number;
  onNextPage: () => void;
  onPrevPage: () => void;
  onClickPage: (page: number) => void;
  onClickItemsQuantity: (quantity: number) => void;
  itemsQuantityAvaliabl: number[];
  quantityPerPage: number;
  totalPages: number;
}

const Pagination: FC<IPagination> = ({
  currentPage,
  onNextPage,
  onPrevPage,
  onClickPage,
  itemsQuantityAvaliabl,
  onClickItemsQuantity,
  quantityPerPage,
  totalPages,
}) => {
  const isNotFirstPage = currentPage !== 1;
  return (
    <div className="pagination__wrapper">
      <div className="pagination__left">
        {" "}
        <button
          disabled={!isNotFirstPage}
          className="pagination_arrow"
          onClick={onPrevPage}
        >
          <ChevronIcon />
        </button>
        <div className="pagination__items_wr">
          <button
            className={`pagination_item ${currentPage === 1 ? "active" : ""}`}
            onClick={() =>
              onClickPage(isNotFirstPage ? currentPage - 1 : currentPage)
            }
          >
            {isNotFirstPage ? currentPage - 1 : currentPage}
          </button>
          <button
            onClick={() =>
              onClickPage(!isNotFirstPage ? currentPage + 1 : currentPage)
            }
            className={`pagination_item ${currentPage !== 1 ? "active" : ""}`}
            disabled={
              isNotFirstPage
                ? currentPage > totalPages
                : currentPage + 1 > totalPages
            }
          >
            {isNotFirstPage ? currentPage : currentPage + 1}
          </button>
          <button
            onClick={() =>
              onClickPage(!isNotFirstPage ? currentPage + 2 : currentPage + 1)
            }
            className={`pagination_item`}
            disabled={
              isNotFirstPage
                ? currentPage + 1 > totalPages
                : currentPage + 2 > totalPages
            }
          >
            {isNotFirstPage ? currentPage + 1 : currentPage + 2}
          </button>
        </div>
        <button
          className="pagination_arrow"
          onClick={onNextPage}
          disabled={currentPage === totalPages}
        >
          <ChevronIcon />
        </button>
      </div>
      <div className="pagination__right">
        <span>Wyświetlaj na stronie:</span>
        {itemsQuantityAvaliabl.map((item, idx) => {
          return (
            <div
              key={`${item}-${idx}`}
              onClick={() => onClickItemsQuantity(item)}
              className={`pagination__right_item ${
                quantityPerPage === item ? "active" : ""
              }`}
            >
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Pagination;
