import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Form, Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  Formik,
  FormikHelpers,
  Form as FormikForm,
  Field,
  useFormikContext,
} from "formik";
import * as Yup from "yup";
import "./AddNew.scss";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import {
  NewPostData,
  publishPost,
  publishPostWithImages,
  uploadImages,
} from "../../../../../actions/wp";
import { CloseIcon } from "../../../../../assets/images/svg/Icons";
import FormField from "../../../../../components/Form/FormField";
// import RaportsModal from "../Modal/RaportsModal";

interface AddNewFormProps {
  showModal: boolean;
  handleClose: () => void;
  handleShow: () => void;
}

const initialValues: NewPostData = {
  title: "",
  content: "",
  status: "publish",
  meta: {
    report_type: "",
    report_start_date: "",
    report_end_date: "",
    report_points_mood: "",
    report_points_energy: "",
    report_points_comfort: "",
    report_points_hunger: "",
    report_points_reliability: "",
  },
};

const AddNewForm: React.FC<AddNewFormProps> = ({
  showModal,
  handleClose,
  handleShow,
}) => {
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const startDatePickerRef = useRef<any>(null);
  const endDatePickerRef = useRef<any>(null);

  const openStartDatePicker = () => {
    if (startDatePickerRef.current !== null) {
      startDatePickerRef.current.setOpen(true);
    }
  };

  const openEndDatePicker = () => {
    if (endDatePickerRef.current !== null) {
      endDatePickerRef.current.setOpen(true);
    }
  };

  const startDay = format(startDate, "dd");
  const startMonth = format(startDate, "MM");
  const startYear = format(startDate, "yyyy");
  const endDay = format(endDate, "dd");
  const endMonth = format(endDate, "MM");
  const endYear = format(endDate, "yyyy");

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      setStartDate(date);
      if (date > endDate) {
        setEndDate(date);
      }
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      setEndDate(date);
      if (date < startDate) {
        setStartDate(date);
      }
    }
  };

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      setSelectedFiles((prevFiles) => [...prevFiles, ...filesArray]);
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
    }
  };

  const validationSchema = Yup.object({
    meta: Yup.object({
      report_points_mood: Yup.string().required("Wymagane"),
      report_points_energy: Yup.string().required("Wymagane"),
      report_points_comfort: Yup.string().required("Wymagane"),
      report_points_hunger: Yup.string().required("Wymagane"),
      report_points_reliability: Yup.string().required("Wymagane"),
    }),
  });

  const SubmitButton = () => {
    const { submitForm, isSubmitting } = useFormikContext();

    return (
      <Button variant="primary" onClick={submitForm} disabled={isSubmitting}>
        {isSubmitting ? (
          <>
            <span className="spinner-grow spinner-grow-sm" />
            Ładowanie...
          </>
        ) : (
          "ZATWIERDŹ I WYŚLIJ"
        )}
      </Button>
    );
  };

  const handleRemoveImg = (name: string) => {
    const filtredImages = selectedFiles.filter((img) => img.name !== name);

    setSelectedFiles(filtredImages);
  };

  // return (
  //   <RaportsModal
  //     type="new"
  //     initialValues={initialValues}
  //     handleClose={handleClose}
  //     showModal={showModal}
  //   />
  // );

  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <div className="modal-wrapper">
          <div className="d-flex justify-content-end">
            <Button variant="none" type="button" onClick={handleClose}>
              <CloseIcon />
            </Button>
          </div>
          <header className="position-relative d-block pt-0 border-0 modal-header">
            <h3 className="text-center font-italic modal-title">
              UTWÓRZ RAPORT
            </h3>
            <ul className="d-flex justify-content-center list-unstyled m-0">
              <li>Bądź ze sobą szczery</li>
              <li>Odnotuj zmiany w samopoczuciu</li>
              <li>Zajmie to tylko kilka minut</li>
            </ul>
          </header>
          <Modal.Body>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={(
                values: NewPostData,
                actions: FormikHelpers<NewPostData>
              ) => {
                const formattedStartDate = format(startDate, "dd.MM.yyyy");
                const formattedEndDate = format(endDate, "dd.MM.yyyy");

                if (values.meta) {
                  values.meta.report_start_date = formattedStartDate;
                  values.meta.report_end_date = formattedEndDate;
                }

                if (selectedFiles.length > 0) {
                  dispatch(
                    uploadImages(selectedFiles, () => {
                      setSelectedFiles([]);
                    })
                  );
                }
                // Wywołanie nowej funkcji, która zajmuje się publikacją posta i przesyłaniem obrazów
                dispatch(publishPostWithImages(values, selectedFiles));

                // Oczyszczenie wybranych plików po zakończeniu operacji
                setSelectedFiles([]);

                // Zakończenie operacji Formika
                actions.setSubmitting(false);
              }}
            >
              {({ isSubmitting, setFieldValue, values }) => (
                <>
                  <FormikForm className="form">
                    <Form.Group className="form-group--half">
                      <label className="d-block form-label">Typ raportu:</label>
                      <div className="form-field d-flex overflow-hidden">
                        <button
                          type="button"
                          className={
                            values?.meta?.report_type === "Tygodniowy"
                              ? "form-button form-button--active"
                              : "form-button"
                          }
                          onClick={() =>
                            setFieldValue("meta.report_type", "Tygodniowy")
                          }
                        >
                          Tygodniowy
                        </button>
                        <button
                          type="button"
                          className={
                            values?.meta?.report_type === "Miesięczny"
                              ? "form-button form-button--active"
                              : "form-button"
                          }
                          onClick={() =>
                            setFieldValue("meta.report_type", "Miesięczny")
                          }
                        >
                          Miesięczny
                        </button>
                      </div>
                    </Form.Group>
                    <Form.Group className="d-flex flex-column form-group--half">
                      <label className="d-block form-label">
                        okres raportu:
                      </label>
                      <div className="form-group__dates">
                        <div className="form-field--date">
                          <span>Od</span>
                          <div className="d-flex justify-content-between">
                            <input
                              className="form-control"
                              type="text"
                              value={startDay}
                              onClick={openStartDatePicker}
                              readOnly
                            />
                            <input
                              className="form-control"
                              type="text"
                              value={startMonth}
                              onClick={openStartDatePicker}
                              readOnly
                            />
                            <input
                              className="form-control"
                              type="text"
                              value={startYear}
                              onClick={openStartDatePicker}
                              readOnly
                            />
                            <DatePicker
                              ref={startDatePickerRef}
                              selected={startDate}
                              onChange={handleStartDateChange}
                              dateFormat="dd MM yyyy"
                            />
                          </div>
                        </div>
                        <div className="form-field--date">
                          <span>Do</span>
                          <div className="d-flex justify-content-between">
                            <input
                              className="form-control"
                              type="text"
                              value={endDay}
                              onClick={openEndDatePicker}
                              readOnly
                            />
                            <input
                              className="form-control"
                              type="text"
                              value={endMonth}
                              onClick={openEndDatePicker}
                              readOnly
                            />
                            <input
                              className="form-control"
                              type="text"
                              value={endYear}
                              onClick={openEndDatePicker}
                              readOnly
                            />
                            <DatePicker
                              ref={endDatePickerRef}
                              selected={endDate}
                              onChange={handleEndDateChange}
                              dateFormat="dd MM yyyy"
                            />
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                    <Field
                      type="text"
                      name="title"
                      label="Tytuł raportu"
                      placeholder="Tytuł, który pozwoli wyróżnić Ci ten raport np. “Ciężki tydzień, początek przygody.”"
                      component={FormField}
                    />
                    <Field
                      name="content"
                      type="textarea"
                      label="treść raportu"
                      placeholder="Maks. 800 znaków."
                      rows="5"
                      maxlength="800"
                      component={FormField}
                    />
                    <Form.Group>
                      <div className="form-label__wrapper">
                        <label htmlFor="" className="form-label">
                          dodaj zdjęcia
                        </label>
                        <div className="form__images">
                          {selectedFiles.length
                            ? selectedFiles.map((img, idx) => {
                                return (
                                  <div
                                    key={`${img.size}-${idx}`}
                                    className="img_preview"
                                  >
                                    <span
                                      onClick={() => handleRemoveImg(img.name)}
                                    >
                                      <CloseIcon />
                                    </span>
                                    <img
                                      src={URL.createObjectURL(img)}
                                      alt="Podgląd"
                                    />
                                  </div>
                                );
                              })
                            : ""}
                        </div>
                      </div>
                      {selectedFiles.length < 6 ? (
                        <div className="d-flex flex-column justify-content-center align-items-center form-field form-field--media">
                          <input
                            type="file"
                            multiple
                            onChange={handleFileChange}
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            accept=".png, .jpg, .jpeg"
                          />
                          <span className="d-block">
                            Kliknij przycisk aby dodać zdjęcie, lub upuść pliki
                            tutaj.
                          </span>
                          <button
                            className="custom-file-upload"
                            onClick={handleButtonClick}
                          >
                            DODAJ ZDJĘCIE
                          </button>
                          <span className="d-block">
                            Max 6 zdjęć. Dopuszczalne formaty: .jpeg, .png,
                            .heic
                          </span>
                        </div>
                      ) : null}
                    </Form.Group>
                    <div
                      className="d-flex flex-wrap align-items-center form-group"
                      role="group"
                    >
                      <label className="d-block w-100">
                        1. Jak oceniasz swoje ogólne samopoczucie w tym
                        okresie?*
                      </label>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_mood_1"
                          name="meta.report_points_mood"
                          value="Świetnie"
                        />
                        <label htmlFor="report_points_mood_1">Świetnie</label>
                      </div>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_mood_2"
                          name="meta.report_points_mood"
                          value="Dobrze"
                        />
                        <label htmlFor="report_points_mood_2">Dobrze</label>
                      </div>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_mood_3"
                          name="meta.report_points_mood"
                          value="Średnio"
                        />
                        <label htmlFor="report_points_mood_3">Średnio</label>
                      </div>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_mood_4"
                          name="meta.report_points_mood"
                          value="Źle"
                        />
                        <label htmlFor="report_points_mood_4">Źle</label>
                      </div>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_mood_5"
                          name="meta.report_points_mood"
                          value="Fatalnie"
                        />
                        <label htmlFor="report_points_mood_5">Fatalnie</label>
                      </div>
                    </div>
                    <div
                      className="d-flex flex-wrap align-items-center form-group"
                      role="group"
                    >
                      <label className="d-block w-100">
                        2. Jak oceniasz swój poziom energii w tym okresie?*
                      </label>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_energy_1"
                          name="meta.report_points_energy"
                          value="Świetnie"
                        />
                        <label htmlFor="report_points_energy_1">Świetnie</label>
                      </div>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_energy_2"
                          name="meta.report_points_energy"
                          value="Dobrze"
                        />
                        <label htmlFor="report_points_energy_2">Dobrze</label>
                      </div>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_energy_3"
                          name="meta.report_points_energy"
                          value="Średnio"
                        />
                        <label htmlFor="report_points_energy_3">Średnio</label>
                      </div>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_energy_4"
                          name="meta.report_points_energy"
                          value="Źle"
                        />
                        <label htmlFor="report_points_energy_4">Źle</label>
                      </div>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_energy_5"
                          name="meta.report_points_energy"
                          value="Fatalnie"
                        />
                        <label htmlFor="report_points_energy_5">Fatalnie</label>
                      </div>
                    </div>
                    <div
                      className="d-flex flex-wrap align-items-center form-group"
                      role="group"
                    >
                      <label className="d-block w-100">
                        3. Jak oceniasz swój komfort żołądkowy / trawienny w tym
                        okresie?*
                      </label>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_comfort_1"
                          name="meta.report_points_comfort"
                          value="Świetnie"
                        />
                        <label htmlFor="report_points_comfort_1">
                          Świetnie
                        </label>
                      </div>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_comfort_2"
                          name="meta.report_points_comfort"
                          value="Dobrze"
                        />
                        <label htmlFor="report_points_comfort_2">Dobrze</label>
                      </div>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_comfort_3"
                          name="meta.report_points_comfort"
                          value="Średnio"
                        />
                        <label htmlFor="report_points_comfort_3">Średnio</label>
                      </div>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_comfort_4"
                          name="meta.report_points_comfort"
                          value="Źle"
                        />
                        <label htmlFor="report_points_comfort_4">Źle</label>
                      </div>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_comfort_5"
                          name="meta.report_points_comfort"
                          value="Fatalnie"
                        />
                        <label htmlFor="report_points_comfort_5">
                          Fatalnie
                        </label>
                      </div>
                    </div>
                    <div
                      className="d-flex flex-wrap align-items-center form-group"
                      role="group"
                    >
                      <label className="d-block w-100">
                        4. Czy byłeś/aś głodny w tym okresie?*
                      </label>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_hunger_1"
                          name="meta.report_points_hunger"
                          value="Nigdy"
                        />
                        <label htmlFor="report_points_hunger_1">Nigdy</label>
                      </div>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_hunger_2"
                          name="meta.report_points_hunger"
                          value="Rzadko"
                        />
                        <label htmlFor="report_points_hunger_2">Rzadko</label>
                      </div>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_hunger_3"
                          name="meta.report_points_hunger"
                          value="Od czasu do czasu"
                        />
                        <label htmlFor="report_points_hunger_3">
                          Od czasu do czasu
                        </label>
                      </div>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_hunger_4"
                          name="meta.report_points_hunger"
                          value="Często"
                        />
                        <label htmlFor="report_points_hunger_4">Często</label>
                      </div>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_hunger_5"
                          name="meta.report_points_hunger"
                          value="Cały czas"
                        />
                        <label htmlFor="report_points_hunger_5">
                          Cały czas
                        </label>
                      </div>
                    </div>
                    <div
                      className="d-flex flex-wrap align-items-center form-group"
                      role="group"
                    >
                      <label className="d-block w-100">
                        5. Jak rzetelnie przestrzegałeś planu treningowego i
                        diety (w skali 0-5)?*
                      </label>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_reliability_1"
                          name="meta.report_points_reliability"
                          value="0"
                        />
                        <label htmlFor="report_points_reliability_1">0</label>
                      </div>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_reliability_2"
                          name="meta.report_points_reliability"
                          value="1"
                        />
                        <label htmlFor="report_points_reliability_2">1</label>
                      </div>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_reliability_3"
                          name="meta.report_points_reliability"
                          value="2"
                        />
                        <label htmlFor="report_points_reliability_3">2</label>
                      </div>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_reliability_4"
                          name="meta.report_points_reliability"
                          value="3"
                        />
                        <label htmlFor="report_points_reliability_4">3</label>
                      </div>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_reliability_5"
                          name="meta.report_points_reliability"
                          value="4"
                        />
                        <label htmlFor="report_points_reliability_5">4</label>
                      </div>
                      <div>
                        <Field
                          type="radio"
                          id="report_points_reliability_6"
                          name="meta.report_points_reliability"
                          value="5"
                        />
                        <label htmlFor="report_points_reliability_6">5</label>
                      </div>
                    </div>
                  </FormikForm>
                  <div className="modal-submit">
                    <SubmitButton />
                  </div>
                </>
              )}
            </Formik>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default AddNewForm;
