import { NewUserData } from "../../../actions/wp";

export const initialValues: NewUserData = {
  first_name: "",
  last_name: "",
  username: "",
  email: "",
  password: "",
  nickname: "",
  stepData: {
    gender: "",
    height: 0,
    age: 0,
    weight: 0,
    weight_current: 0,
    weight_target: 0,
    phone: 0,
    goal: "",
    plan: "",
    injury: "",
    preferences: "",
    important: "",
    calories: 0,
    carbs: 0,
    fats: 0,
    protein: 0,
    training_days: 0,
  },
  roles: [],
};

export const gender = [{ name: "Mężczyzna" }, { name: "Kobieta" }];

export const goals = [
  { name: "Redukcja" },
  { name: "Rekompozycja" },
  { name: "Masa" },
];

export const plans = [
  { name: "Adaptacja" },
  { name: "Turbo" },
  { name: "Super Charged" },
  { name: "X-TREME" },
];
