import React from "react";
import "./AnimatedArrows.scss";

const AnimatedArrows = () => {
  return (
    <div className="d-inline-flex align-items-center animated-arrows">
      <svg
        width="54"
        height="53"
        viewBox="0 0 54 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.2028 50.5583L18.9134 51.269L19.0516 50.2735L20.9476 36.6132L20.9818 36.3668L20.8059 36.1909L17.0437 32.4287L16.8684 32.2534L16.6227 32.2869L2.93247 34.153L1.93363 34.2892L2.64645 35.002L6.13991 38.4955L6.32176 38.6773L6.57545 38.6351L16.1992 37.0354L14.5995 46.6592L14.5573 46.9129L14.7392 47.0947L18.2028 50.5583Z"
          stroke="white"
        />
        <path
          d="M31.6378 37.1233L32.3485 37.8339L32.4866 36.8385L34.3827 23.1781L34.4169 22.9317L34.241 22.7558L30.4788 18.9937L30.3034 18.8183L30.0577 18.8518L16.3675 20.718L15.3687 20.8541L16.0815 21.5669L19.5749 25.0604L19.7568 25.2422L20.0105 25.2001L29.6343 23.6003L28.0345 33.2241L27.9924 33.4778L28.1742 33.6597L31.6378 37.1233Z"
          stroke="white"
        />
        <path
          d="M45.0729 23.6882L45.7835 24.3989L45.9217 23.4034L47.8177 9.74308L47.8519 9.49669L47.676 9.32079L43.9138 5.5586L43.7384 5.38324L43.4927 5.41674L29.8025 7.2829L28.8037 7.41906L29.5165 8.13187L33.01 11.6253L33.1918 11.8072L33.4455 11.765L43.0693 10.1653L41.4696 19.7891L41.4274 20.0428L41.6093 20.2246L45.0729 23.6882Z"
          stroke="white"
        />
      </svg>
    </div>
  );
};

const SmallAnimatedArrows = () => {
  return (
    <div className="d-inline-flex align-items-center animated-arrows animated-arrows--smal">
      <svg
        width="34"
        height="33"
        viewBox="0 0 34 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5759 31.8455L12.0235 32.2931L12.1105 31.6661L13.3048 23.0618L13.3263 22.9066L13.2155 22.7958L10.8458 20.4261L10.7354 20.3156L10.5806 20.3367L1.95746 21.5122L1.32832 21.5979L1.7773 22.0469L3.97775 24.2474L4.0923 24.3619L4.25209 24.3353L10.3139 23.3277L9.30627 29.3895L9.2797 29.5493L9.39425 29.6639L11.5759 31.8455Z"
          stroke="white"
          strokeWidth="0.629877"
        />
        <path
          d="M20.0383 23.3831L20.4859 23.8307L20.573 23.2037L21.7672 14.5994L21.7888 14.4442L21.678 14.3334L19.3082 11.9637L19.1978 11.8532L19.043 11.8743L10.4199 13.0498L9.79074 13.1355L10.2397 13.5845L12.4402 15.785L12.5547 15.8995L12.7145 15.8729L18.7763 14.8653L17.7687 20.9271L17.7421 21.0869L17.8567 21.2015L20.0383 23.3831Z"
          stroke="white"
          strokeWidth="0.629877"
        />
        <path
          d="M28.5007 14.9207L28.9483 15.3683L29.0354 14.7413L30.2296 6.13697L30.2512 5.98177L30.1404 5.87098L27.7706 3.50126L27.6602 3.39081L27.5054 3.41191L18.8823 4.58736L18.2531 4.67312L18.7021 5.12211L20.9026 7.32256L21.0171 7.4371L21.1769 7.41054L27.2387 6.40291L26.2311 12.4647L26.2045 12.6245L26.3191 12.7391L28.5007 14.9207Z"
          stroke="white"
          strokeWidth="0.629877"
        />
      </svg>
    </div>
  );
};

const MobileAnimatedArrows = () => {
  return (
    <div className="d-inline-flex align-items-center animated-arrows animated-arrows--mobile">
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.07895 24.9804L9.41866 25.3201L9.48471 24.8442L10.3911 18.314L10.4074 18.1962L10.3234 18.1121L8.52487 16.3136L8.44104 16.2298L8.32357 16.2458L1.77906 17.1379L1.30157 17.203L1.64233 17.5437L3.31235 19.2138L3.39929 19.3007L3.52056 19.2805L8.12116 18.5158L7.35642 23.1164L7.33626 23.2377L7.42319 23.3246L9.07895 24.9804Z"
          stroke="white"
          strokeWidth="0.478044"
        />
        <path
          d="M15.5015 18.5578L15.8412 18.8975L15.9073 18.4217L16.8136 11.8914L16.83 11.7736L16.7459 11.6895L14.9474 9.89106L14.8636 9.80723L14.7461 9.82324L8.2016 10.7153L7.72412 10.7804L8.06487 11.1212L9.7349 12.7912L9.82183 12.8782L9.94311 12.858L14.5437 12.0933L13.779 16.6939L13.7588 16.8151L13.8457 16.9021L15.5015 18.5578Z"
          stroke="white"
          strokeWidth="0.478044"
        />
        <path
          d="M21.924 12.1353L22.2638 12.475L22.3298 11.9991L23.2362 5.46889L23.2525 5.3511L23.1684 5.26701L21.37 3.46852L21.2861 3.38469L21.1687 3.4007L14.6241 4.29281L14.1467 4.3579L14.4874 4.69866L16.1574 6.36869L16.2444 6.45562L16.3657 6.43546L20.9663 5.67072L20.2015 10.2713L20.1814 10.3926L20.2683 10.4795L21.924 12.1353Z"
          stroke="white"
          strokeWidth="0.478044"
        />
      </svg>
    </div>
  );
};

export { AnimatedArrows, SmallAnimatedArrows, MobileAnimatedArrows };
