import { Field } from "formik";
import FormField from "../../../../../components/Form/FormField";

const StepFour = () => {
  return (
    <section>
      <h2 className="form-heading text-center">UTWÓRZ KONTO</h2>
      <Field
        type="text"
        name="first_name"
        label="IMIĘ:"
        placeholder="[IMIĘ]"
        component={FormField}
      />
      <Field
        type="text"
        name="last_name"
        label="NAZWISKO:"
        placeholder="[NAZWISKO]"
        component={FormField}
      />
      <Field
        type="text"
        name="address"
        label="ADRES:"
        placeholder="[ADRES]"
        component={FormField}
      />
      <div className="form-group--row d-flex">
        <Field
          type="text"
          name="city"
          label="MIASTO:"
          placeholder="[MIASTO]"
          component={FormField}
        />
        <Field
          type="text"
          name="postal_code"
          label="KOD POCZTOWY:"
          placeholder="[KOD]"
          component={FormField}
        />
      </div>
      <Field
        type="email"
        name="email"
        label="E-MAIL"
        placeholder="[ADRES E-MAIL]"
        icon="email"
        component={FormField}
      />
      <Field
        type="password"
        name="password"
        label="HASŁO"
        placeholder="[MIN. 6 ZNAKÓW]"
        icon="lock"
        component={FormField}
      />
      <Field
        type="text"
        name="discount"
        label="KOD RABATOWY:"
        placeholder="[KOD RABATOWY]"
        component={FormField}
      />
    </section>
  );
};

export default StepFour;
