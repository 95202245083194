import React from "react";
import { useDispatch } from "react-redux";

import { Form as FormikForm, Formik } from "formik";
import * as Yup from "yup";
import { Button, Form } from "react-bootstrap";
import "./SettingsForm.scss";
import { updateUser, User } from "../../../../../actions/wp";
import InputField from "../../../../../components/Form/InputField";
import FormField from "../../../../../components/Form/FormField";

interface ISettingsForm {
  currentUser: User;
}

const SettingsForm: React.FC<ISettingsForm> = ({ currentUser }) => {
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    password: Yup.string(),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password")],
      "Hasła muszą być takie same"
    ),
    gender: Yup.string().required("Wybór płci jest wymagany"),
  });

  const handleUpdateUser = (values: any) => {
    if (values.password !== values.confirmPassword) {
      alert("Hasła nie są takie same!");
      return;
    }

    const fullName = `${values.name} ${values.last_name}`;

    const updatedUserData = {
      name: fullName,
      first_name: values.name,
      last_name: values.last_name,
      password: values.password,
      meta: {
        phone: values.phone,
        age: values.age,
        gender: values.gender,
        goal: values.goal,
        height: values.height,
        plan: values.plan,
        weight: values.weight,
      },
    };

    dispatch(updateUser(currentUser.id.toString(), updatedUserData));
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: currentUser.name || "",
        last_name: currentUser.last_name || "",
        phone: currentUser.meta.phone || "",
        password: "",
        confirmPassword: "",
        gender: currentUser.meta.gender || "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleUpdateUser}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <FormikForm className="form">
          <div className="form__wrapper">
            <InputField
              type="text"
              name="name"
              label="IMIĘ"
              placeholder="[IMIE]"
              component={FormField}
            />
            <InputField
              type="text"
              name="last_name"
              label="NAZWISKO"
              placeholder="[NAZWISKO]"
              component={FormField}
            />
            <InputField
              type="phone"
              name="phone"
              label="TELEFON"
              placeholder="[TELEFON]"
              component={FormField}
            />
            <Form.Group className="form-group-row">
              <label className="form-label">Twoja płeć:</label>
              <div className="form-field d-flex overflow-hidden">
                <button
                  type="button"
                  className={
                    values.gender === "Mężczyzna"
                      ? "form-button form-button--active"
                      : "form-button"
                  }
                  onClick={() => setFieldValue("gender", "Mężczyzna")}
                >
                  Mężczyzna
                </button>
                <button
                  type="button"
                  className={
                    values.gender === "Kobieta"
                      ? "form-button form-button--active"
                      : "form-button"
                  }
                  onClick={() => setFieldValue("gender", "Kobieta")}
                >
                  Kobieta
                </button>
              </div>
            </Form.Group>
            <InputField
              type="password"
              name="password"
              label="ZMIEŃ HASŁO"
              placeholder="[NOWE HASŁO]"
              component={FormField}
            />
            <InputField
              type="password"
              name="confirmPassword"
              label="POWTÓRZ HASŁO"
              placeholder="[POWTÓRZ NOWE HASŁO]"
              component={FormField}
            />
          </div>
          <Button variant="secondary" type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Trwa zapisywanie..." : "Zapisz zmiany"}
          </Button>
        </FormikForm>
      )}
    </Formik>
  );
};

export default SettingsForm;
