import React, { useRef, useState } from "react";
import PageHeading from "../../../components/headings/PageHeading";
//@ts-ignore
import background from "../../../assets/images/png/how-it-works-bg.png";
import background2 from "../../../assets/images/png/how-it-works-bg-2.png";
import WidthLayout from "../../../layouts/WidthLayout";
import { CurrentStageIcon, PlayIcon } from "../../../assets/images/svg/Icons";
import "./HowItWorksSection.scss";

const steps: {
  id: number;
  title: string;
  content: string;
  extraContent?: string;
}[] = [
  {
    id: 1,
    title: "WYPEŁNIASZ KRÓTKI FORMULARZ",
    content:
      "Podajesz podstawowe parametry takie jak płeć, wiek, wzrost i waga oraz określasz swój cel.",
  },
  {
    id: 2,
    title: "OPŁACASZ WYBRANY PAKIET",
    content:
      "Tworzysz konto i opłacasz program najlepiej dopasowany do Twoich potrzeb i celów.",
  },
  {
    id: 3,
    title: "OTRZYMUJESZ PLAN TRENINGOWY",
    content:
      "W zależności od pakietu dostajesz też dietę, dostęp do kreatora posiłków i autorskiej bazy wideo instruktażowych.",
  },
  {
    id: 4,
    title: "MONITORUJESZ Z NAMI TWÓJ PROGRES ",
    content:
      "Oceniam Twój postęp na podstawie zdjęć i stale opiekuje się Tobą dzięki panelowi czatu.*",
  },
  {
    id: 5,
    title: "CIESZYSZ SIĘ Z NOWEJ SYLWETKI",
    content:
      "100% klientów przestrzegających moich zaleceń osiąga swoje cele. To dotyczy także Ciebie!",
  },
];

const HowItWorksSection = () => {
  const agnieszkaVideoRef = useRef<HTMLVideoElement>(null);
  const michalVideoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentPlayingVideo, setCurrentPlayingVideo] = useState<string | null>(
    null
  );

  const playVideo = (
    videoRef: React.RefObject<HTMLVideoElement>,
    videoId: string
  ) => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
      setCurrentPlayingVideo(videoId);
    }
  };

  const togglePlay = () => {
    if (agnieszkaVideoRef.current && !agnieszkaVideoRef.current.paused) {
      agnieszkaVideoRef.current.pause();
      agnieszkaVideoRef.current.currentTime = 0;
    }
    if (michalVideoRef.current && !michalVideoRef.current.paused) {
      michalVideoRef.current.pause();
      michalVideoRef.current.currentTime = 0;
    }
    setIsPlaying(false);
    setCurrentPlayingVideo(null);
  };

  const handleVideoEnd = () => {
    setIsPlaying(false);
    setCurrentPlayingVideo(null);
  };

  const videoBackgroundClass = isPlaying
    ? "video__background hidden"
    : "video__background";
  const agnieszkaVideoClass =
    currentPlayingVideo === "agnieszka" ? "visible" : "";
  const michalVideoClass = currentPlayingVideo === "michal" ? "visible" : "";

  return (
    <WidthLayout>
      <section id="workssection" className="section__wrapper">
        <p className="paragraph__workssection">ZACZYNAMY!</p>
        <PageHeading variant="h2">JAK TO DZIAŁA?</PageHeading>
        <div className="content__wrapper">
          <div className="video__wrapper">
            <div
              className="video-triggers"
              onClick={() => playVideo(michalVideoRef, "michal")}
            >
            </div>
            <div className={videoBackgroundClass}>
              <div className='position-absolute text-center'>
                <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M39.0833 75.25L75.25 52L39.0833 28.75V75.25ZM52 103.667C44.8528 103.667 38.1361 102.31 31.85 99.598C25.5639 96.8855 20.0958 93.2042 15.4458 88.5542C10.7958 83.9042 7.11458 78.4361 4.40208 72.15C1.68958 65.8639 0.333328 59.1473 0.333328 52C0.333328 44.8528 1.68958 38.1362 4.40208 31.85C7.11458 25.5639 10.7958 20.0959 15.4458 15.4459C20.0958 10.7959 25.5639 7.11462 31.85 4.40212C38.1361 1.68962 44.8528 0.333374 52 0.333374C59.1472 0.333374 65.8639 1.68962 72.15 4.40212C78.4361 7.11462 83.9042 10.7959 88.5542 15.4459C93.2042 20.0959 96.8854 25.5639 99.5979 31.85C102.31 38.1362 103.667 44.8528 103.667 52C103.667 59.1473 102.31 65.8639 99.5979 72.15C96.8854 78.4361 93.2042 83.9042 88.5542 88.5542C83.9042 93.2042 78.4361 96.8855 72.15 99.598C65.8639 102.31 59.1472 103.667 52 103.667Z" fill="white"/>
                </svg>
                <span className="d-none d-lg-block font-italic text-uppercase">ZOBACZ W AKCJI</span>
              </div>
            </div>
            <video
              ref={michalVideoRef}
              onClick={() => togglePlay()}
              className={michalVideoClass}
              width="100%"
              height="auto"
              onEnded={handleVideoEnd}
            >
              <source
                src="https://panel.healthandbodyacademy.com/wp-content/uploads/2024/01/michal_intro.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <div className="description__wrapper">
            {steps.map((step) => {
              return (
                <div className="single__step" key={step.id}>
                  <div className="step__icon">
                    <>
                      <CurrentStageIcon />
                      <span className="position-absolute step__number">
                        {step.id}
                      </span>
                    </>
                  </div>
                  <span className="step__title">{step.title}</span>
                  <span className="step__content">{step.content}</span>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </WidthLayout>
  );
};

export default HowItWorksSection;
