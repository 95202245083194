import React from "react";
import Loader from "../../../../components/Loader/Loader";
import { useWpSelector } from "../../../../selectors";

import TargetForm from "./components/TargetForm";
import "./TargetPage.scss";

const TargetPage = () => {
  const { currentUser } = useWpSelector();

  return (
    <div className="dashboard__target">
      {currentUser ? (
        <div className="target">
          <div className="target__form">
            <TargetForm currentUser={currentUser} />
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default TargetPage;
