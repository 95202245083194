import axios, { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import Constants from "../constants";
import {
  SuccessMessageAction,
  ClearMessageAction,
  WarningMessageAction,
  UnAuthUserAction,
  tokenHeaderWithParams,
} from "./auth";

import {
  ActionUserTypes,
  ActionAuthTypes,
  ActionWPTypes,
  ActionMessagesTypes,
} from "./types";
import { tokenHeader } from "./auth";

export enum AllowedRoles {
  admin = "administrator",
  user = "author",
  author = "author",
  guest = "guest",
}

export interface StepData {
  gender: string;
  height: number;
  age: number;
  weight: number;
  weight_current: number;
  weight_target: number;
  phone: number;
  goal: string;
  plan: string;
  injury: string;
  preferences: string;
  important: string;
  calories: number;
  carbs: number;
  fats: number;
  protein: number;
  training_days: number;
}

export interface NewUserData {
  [x: string]: any;
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  password: string;
  roles: string[];
  stepData: StepData;
  nickname: string;
}

export interface CreateUserAction {
  type: ActionUserTypes.createUser;
}

export interface IMessage {
  id: number;
  created_at: string;
  senderId: number;
  revicerId: number;
  text: string;
}

export interface User {
  id: number;
  name: string;
  first_name: string;
  last_name: string;
  description: string;
  url: string;
  user_email: string;
  user_registered: string;
  nickname: string;
  roles: AllowedRoles[];
  avatar_urls: {
    [size: number]: string;
  };
  meta: {
    age?: number;
    gender?: string;
    goal?: string;
    height?: number;
    plan?: string;
    weight?: number;
    weight_current?: number;
    weight_target?: number;
    phone?: number;
    injury?: string;
    preferences?: string;
    important?: string;
    calories?: number;
    carbs?: number;
    fats?: number;
    protein?: number;
    training_days?: number;
  };
}

interface Rendered {
  rendered: string;
}

export interface Post {
  meta: any;
  id: number;
  title: Rendered;
  content: Rendered;
  excerpt: Rendered;
  modified: string;
  date: string;
}

export interface FetchPostAction {
  type: ActionWPTypes.fetchPost;
  payload: Post;
}

export interface FetchCurrentUserAction {
  type: ActionWPTypes.fetchCurrentUser;
  payload: User;
}

export interface FetchPostsAction {
  type: ActionWPTypes.fetchPosts;
  payload: Post[];
}

export interface DeletePostAction {
  type: ActionWPTypes.deletePost;
  payload: number;
}

export interface UpdatePostAction {
  type: ActionWPTypes.updatePost;
}

export interface PublishPostAction {
  type: ActionWPTypes.publishPost;
}

export interface PostMessageAction {
  type: ActionWPTypes.postMessage;
}

export interface NewMessageData {
  senderId: number;
  receiverId: number;
  text: string;
}

export interface NewPostData {
  id?: number;
  title: string;
  content: string;
  status?: string;
  meta?: {
    report_type?: string;
    report_start_date?: string;
    report_end_date?: string;
    report_points_mood?: string;
    report_points_energy?: string;
    report_points_comfort?: string;
    report_points_hunger?: string;
    report_points_reliability?: string;
  };
}

export type UpdatePostType = (
  id: string,
  newPostData: any,
  callback: () => void
) => void;

export interface TestingMessageAction {
  type: ActionMessagesTypes.forTesting;
}

export interface TestingWPAction {
  type: ActionWPTypes.forTesting;
}

export interface Goal {
  id: number;
  title: Rendered;
  acf: GoalAcf;
}

interface GoalAcf {
  cpt_goals: GoalList;
}

interface GoalList {
  list: GoalList[];
  icon: string;
}

interface GoalList {
  item: string;
}

export interface FetchGoalsAction {
  type: ActionWPTypes.fetchGoals;
  payload: Goal[];
}

export interface Product {
  id: number;
  name: string;
  meta_data: ProductMetaData[];
  acf: ProductAcf;
}

interface ProductMetaData {
  id: number;
  key: string;
  value: string | { day: number; month: string };
}

interface ProductAcf {
  acf_product: ProductAcfList;
}

interface ProductAcfList {
  description: string;
  text: string;
  list: AcfProductListItem[];
}

interface AcfProductListItem {
  item: string;
}

export interface FetchProductsAction {
  type: ActionWPTypes.fetchProducts;
  payload: Product[];
}

export type FetchPlanType = (id: string) => void;

export interface Plan {
  id: number;
  title: Rendered;
  acf: PlanAcf;
}

interface PlanAcf {
  cpt_plans: PlanList;
}

interface PlanList {
  bestseller: boolean;
  list: PlanList[];
  original_price: string;
  premium_price: boolean;
  remotely: boolean;
  new: boolean;
  price: string;
  description: string;
  text: string;
  time: string;
}

interface PlanList {
  item: string;
}

export interface FetchPlansAction {
  type: ActionWPTypes.fetchPlans;
  payload: Plan[];
}

export interface FetchUsersAction {
  type: ActionWPTypes.fetchUsers;
  payload: User[];
}

export interface FetchGoalAction {
  type: ActionWPTypes.fetchGoal;
  payload: Goal;
}

export interface FetchMessagesAction {
  type: ActionWPTypes.fetchMessages;
  payload: IMessage[];
}

export interface FetchPlanAction {
  type: ActionWPTypes.fetchPlan;
  payload: Plan;
}

export interface UpdateUserAction {
  type: ActionUserTypes.updateUser;
  payload: User;
}

export type UpdateUserType = (
  id: string,
  updatedUserData: Partial<User>
) => void;

export type FetchGoalType = (id: string) => void;

export type FetchMessagesType = (id: string) => void;

export type FetchPostType = (id: string) => void;

export const fetchCurrentUser = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get<User>(
        `${Constants.apiUri}/users/me`,
        tokenHeader()
      );
      dispatch<FetchCurrentUserAction>({
        type: ActionWPTypes.fetchCurrentUser,
        payload: response.data,
      });
    } catch (error) {
      dispatch<UnAuthUserAction>({ type: ActionAuthTypes.unAuthUser });
      // dispatch<ClearMessageAction>({ type: ActionMessagesTypes.clearMsg });
    }
  };
};

export const fetchPost: FetchPostType = (id) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(`${Constants.apiUri}/posts/${id}`);
      dispatch<FetchPostAction>({
        type: ActionWPTypes.fetchPost,
        payload: response.data,
      });
    } catch (error) {
      // console.log(error);
    }
  };
};

export const fetchPosts = (userId: string | number) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get<Post[]>(
        `${Constants.apiUri}/reports?author=${userId}`
      );
      dispatch<FetchPostsAction>({
        type: ActionWPTypes.fetchPosts,
        payload: response.data,
      });
      dispatch<ClearMessageAction>({ type: ActionMessagesTypes.clearMsg });
    } catch (error) {
      // console.log(error);
    }
  };
};

export const fetchReports = (userId: string | number) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get<Post[]>(
        `${Constants.apiUri}/author-reports?userId=${userId}`,
        tokenHeader()
      );

      dispatch<FetchPostsAction>({
        type: ActionWPTypes.fetchPosts,
        payload: response.data,
      });
      dispatch<ClearMessageAction>({ type: ActionMessagesTypes.clearMsg });
    } catch (error) {
      // console.log(error);
    }
  };
};

export const updatePost: UpdatePostType = (id, newPostData, callback) => {
  return async (dispatch: Dispatch) => {
    try {
      await axios.post(
        `${Constants.apiUri}/posts/${id}`,
        newPostData,
        tokenHeader()
      );
      dispatch<UpdatePostAction>({ type: ActionWPTypes.updatePost });
      dispatch<SuccessMessageAction>({
        type: ActionMessagesTypes.successMsg,
        payload: "The post was successfully updated!",
      });
    } catch (error) {
      // console.log(error);
    }
    callback();
  };
};

export const deletePost = (id: number, callback: () => void) => {
  return async (dispatch: Dispatch) => {
    try {
      await axios.delete(`${Constants.apiUri}/reports/${id}`, tokenHeader());
      dispatch<DeletePostAction>({
        type: ActionWPTypes.deletePost,
        payload: id,
      });
      dispatch<WarningMessageAction>({
        type: ActionMessagesTypes.warningMsg,
        payload: "The post was successfully deleted.",
      });
    } catch (error) {
      // console.log("Error");
    }
    callback();
  };
};

export const uploadImages = (
  images: File[],
  callback: (imageIds: number[]) => void
) => {
  return async (dispatch: Dispatch) => {
    let allUploadedSuccessfully = true;
    let uploadedImageIds = [];

    for (const image of images) {
      try {
        const formData = new FormData();
        formData.append("file", image);

        const response = await axios.post(
          `${Constants.apiUri}/media`,
          formData,
          tokenHeader()
        );
        uploadedImageIds.push(response.data.id);
      } catch (error) {
        console.error("Error uploading image: ", image.name, error);
        allUploadedSuccessfully = false;
        break;
      }
    }

    if (allUploadedSuccessfully) {
      dispatch<SuccessMessageAction>({
        type: ActionMessagesTypes.successMsg,
        payload: "All images uploaded successfully.",
      });
    } else {
      dispatch<WarningMessageAction>({
        type: ActionMessagesTypes.warningMsg,
        payload: "Some images could not be uploaded.",
      });
    }

    callback(uploadedImageIds);
  };
};

export const publishPost = (newPostData: NewPostData, callback: () => void) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post<NewPostData>(
        `${Constants.apiUri}/reports`,
        newPostData,
        tokenHeader()
      );

      // console.log("Response from the server: ", response);

      if (response.data && response.data.id) {
        // console.log("Created post ID: ", response.data.id);
      }

      const postIdFromHeader = response.headers["Location"];
      if (postIdFromHeader) {
        // console.log("Created post ID from header: ", postIdFromHeader);
      }

      dispatch<PublishPostAction>({ type: ActionWPTypes.publishPost });
      dispatch<SuccessMessageAction>({
        type: ActionMessagesTypes.successMsg,
        payload: "You have successfully published a post.",
      });
    } catch (error) {
      dispatch<WarningMessageAction>({
        type: ActionMessagesTypes.warningMsg,
        payload: "Sorry, we are having problem adding the new post.",
      });
      // console.log(error);
    }

    callback();
  };
};

export const publishPostWithImages = (
  newPostData: NewPostData,
  selectedImages: File[]
) => {
  return async (dispatch: Dispatch) => {
    try {
      const postResponse = await axios.post<NewPostData>(
        `${Constants.apiUri}/reports`,
        newPostData,
        tokenHeader()
      );

      const postId = postResponse.data.id;
      // console.log("Created post ID: ", postId);

      if (!postId) {
        throw new Error("Post ID not received");
      }

      dispatch<PublishPostAction>({ type: ActionWPTypes.publishPost });
      dispatch<SuccessMessageAction>({
        type: ActionMessagesTypes.successMsg,
        payload: "You have successfully published a post.",
      });

      if (selectedImages.length > 0) {
        let uploadedImageIds = [];

        for (const image of selectedImages) {
          const formData = new FormData();
          formData.append("file", image);

          const imageResponse = await axios.post(
            `${Constants.apiUri}/media`,
            formData,
            tokenHeader()
          );
          uploadedImageIds.push(imageResponse.data.id);
        }

        await axios.post(
          `${Constants.apiUri}/acf/v3/reports/${postId}`,
          {
            acf: {
              cpt_reports_gallery: uploadedImageIds,
            },
          },
          tokenHeader()
        );

        // console.log("Images uploaded and ACF field updated");
      }
    } catch (error) {
      console.error("Error in publishing post or uploading images: ", error);
      dispatch<WarningMessageAction>({
        type: ActionMessagesTypes.warningMsg,
        payload:
          "There was a problem with publishing the post or uploading images.",
      });
    }
  };
};

export const createUser = (
  userData: NewUserData,
  callback: (res: AxiosResponse) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      const userDataWithMeta = {
        first_name: userData.first_name,
        last_name: userData.last_name,
        username: userData.email,
        email: userData.email,
        password: userData.password,
        roles: [],
        meta: {
          gender: userData.stepData.gender,
          height: userData.stepData.height,
          age: userData.stepData.age,
          weight: userData.stepData.weight,
          weight_current: userData.stepData.weight_current,
          weight_target: userData.stepData.weight_target,
          phone: userData.stepData.phone,
          goal: userData.stepData.goal,
          plan: userData.stepData.plan,
          injury: userData.stepData.injury,
          preferences: userData.stepData.preferences,
          important: userData.stepData.important,
          calories: userData.stepData.calories,
          carbs: userData.stepData.carbs,
          fats: userData.stepData.fats,
          protein: userData.stepData.protein,
          training_days: userData.stepData.training_days,
        },
      };

      const authResponse = await axios.post(`${Constants.jwtAuthUri}`, {
        username: `${Constants.guestName}`,
        password: `${Constants.guestPasswd}`,
      });

      const response = await axios.post<NewUserData>(
        `${Constants.apiUri}/users`,
        userDataWithMeta,
        {
          headers: {
            Authorization: `Bearer ${authResponse.data.token}`,
          },
        }
      );

      dispatch<CreateUserAction>({ type: ActionUserTypes.createUser });
      dispatch<SuccessMessageAction>({
        type: ActionMessagesTypes.successMsg,
        payload: "User successfully created.",
      });

      callback(response);
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        if (error.response) callback(error.response);
      }
      dispatch<WarningMessageAction>({
        type: ActionMessagesTypes.warningMsg,
        payload: "Error creating user.",
      });
    }
  };
};

export const fetchGoals = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get<Goal[]>(`${Constants.apiUri}/goals`);
      dispatch<FetchGoalsAction>({
        type: ActionWPTypes.fetchGoals,
        payload: response.data,
      });
      dispatch<ClearMessageAction>({ type: ActionMessagesTypes.clearMsg });
    } catch (error) {
      // console.log(error);
    }
  };
};

export const fetchProducts = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get<Product[]>(
        `${Constants.WC}/products?consumer_key=${Constants.consumer_key}&consumer_secret=${Constants.consumer_secret}&order=asc&orderby=menu_order`
      );
      dispatch<FetchProductsAction>({
        type: ActionWPTypes.fetchProducts,
        payload: response.data,
      });
      dispatch<ClearMessageAction>({ type: ActionMessagesTypes.clearMsg });
    } catch (error) {
      console.error(error);
    }
  };
};

export const fetchMessages = (
  id: string,
  callback: (loading: boolean) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true);
    try {
      const response = await axios.get<IMessage[]>(
        `${Constants.apiUri}/messages/`,
        {
          params: {
            senderId: id,
            receiverId: id,
          },
        }
      );

      dispatch<FetchMessagesAction>({
        type: ActionWPTypes.fetchMessages,
        payload: response.data,
      });
    } catch (error) {
      // console.log(error);
    } finally {
      callback(false);
    }
  };
};

export const postMessage = (
  newMessageData: NewMessageData,
  callback: (res: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post<NewMessageData>(
        `${Constants.apiUri}/messages`,
        newMessageData,
        tokenHeader()
      );
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};

export const fetchGoal: FetchGoalType = (id) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(`${Constants.apiUri}/goals/${id}`);
      dispatch<FetchGoalAction>({
        type: ActionWPTypes.fetchGoal,
        payload: response.data,
      });
    } catch (error) {
      // console.log(error);
    }
  };
};

export const fetchPlans = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get<Plan[]>(`${Constants.apiUri}/plans`);
      dispatch<FetchPlansAction>({
        type: ActionWPTypes.fetchPlans,
        payload: response.data,
      });
      dispatch<ClearMessageAction>({ type: ActionMessagesTypes.clearMsg });
    } catch (error) {
      // console.log(error);
    }
  };
};

export const fetchPlan: FetchPlanType = (id) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(`${Constants.apiUri}/plans/${id}`);
      dispatch<FetchPlanAction>({
        type: ActionWPTypes.fetchPlan,
        payload: response.data,
      });
    } catch (error) {
      // console.log(error);
    }
  };
};

export const fetchUsers = (callback: (loading: boolean) => void) => {
  return async (dispatch: Dispatch) => {
    callback(true);
    try {
      const response = await axios.get<User[]>(
        `${Constants.apiUri}/users`,
        tokenHeaderWithParams({ per_page: 100 })
      );
      dispatch<FetchUsersAction>({
        type: ActionWPTypes.fetchUsers,
        payload: response.data,
      });
    } catch (error) {
      // console.log(error);
    } finally {
      callback(false);
    }
  };
};

export const updateUser: UpdateUserType = (id, updatedUserData) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.put<User>(
        `${Constants.apiUri}/users/${id}`,
        updatedUserData,
        tokenHeader()
      );
      dispatch<UpdateUserAction>({
        type: ActionUserTypes.updateUser,
        payload: response.data,
      });
      dispatch<SuccessMessageAction>({
        type: ActionMessagesTypes.successMsg,
        payload: "User data successfully updated.",
      });
    } catch (error) {
      // console.log(error);
    }
  };
};
