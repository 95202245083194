import PageHeading from "../../../components/headings/PageHeading";
import {
  CupIcon,
  CheckIcon2,
  CheckIcon3,
} from "../../../assets/images/svg/Icons";
import Agnieszka from "../../../assets/images/png/about-us-1.png";
import Michal from "../../../assets/images/png/about-us-2.png";
import "./AboutUsSection.scss";

const AboutUsSection = () => {

    return (
      <section className="about-us">
        <div className="container">
          <div className='about-us__wrapper'>
            <div id="michal" className='about-us__cols about-us__cols--reverse'>
              <div className='about-us__col about-us__col--text'>
                <header className='col__header'>
                  <PageHeading variant="h2">
                    MICHAŁ&nbsp;<span>BOBYK</span>
                  </PageHeading>
                  <p>Specjalizuje się w treningach modelujących sylwetkę, redukujących tkankę tłuszczową oraz budujących masę mięśniową. Często uczestniczy w szkoleniach w temacie dietetyki, suplementacji oraz treningu w kształtowaniu sylwetki.</p>
                  <p>Jest również wykładowcą Akademii sportowej Niva Fitness. Prowadzi te szkolenia oraz kursy dla trenerów personalnych.</p>
                  <div className="d-flex align-items-center">
                    <CupIcon />
                    <span>Osiągnięcia:</span>
                  </div>
                </header>
                <ul className="list-unstyled px-0">
                  <li className="d-flex align-items-center">
                    <CheckIcon3 />
                    <span className="d-inline-block text-left">
                      Mistrz Świata w Kulturystyce 2019
                    </span>
                  </li>
                  <li className="d-flex align-items-center">
                    <CheckIcon3 />
                    <span className="d-inline-block text-left">
                      Mistrz Polski w Kulturystyce 2019
                    </span>
                  </li>
                  <li className="d-flex align-items-center">
                    <CheckIcon3 />
                    <span className="d-inline-block text-left">
                      Mistrz Polski w Kulturystyce 2018 roku
                    </span>
                  </li>
                  <li className="d-flex align-items-center">
                    <CheckIcon3 />
                    <span className="d-inline-block text-left">
                      5 miejsce na zawodach Biogenix Challenge 2017 roku
                    </span>
                  </li>
                  <li className="d-flex align-items-center">
                    <CheckIcon3 />
                    <span className="d-inline-block text-left">
                      Vice Mistrz Polski w Kulturystyce 2017 roku
                    </span>
                  </li>
                  <li className="d-flex align-items-center">
                    <CheckIcon3 />
                    <span className="d-inline-block text-left">
                      Mistrz Polski w Kulturystyce w kategorii Debiuty 2016
                    </span>
                  </li>
                </ul>
              </div>
              <div className='about-us__col about-us__col--media'>
                <img src={Michal} alt="MICHAŁ BOBYK" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default AboutUsSection;
