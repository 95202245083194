import React from "react";
import { useFormikContext } from "formik";
import { NewUserData, Product } from "../../actions/wp";
import Item from "./Item";

type PlanListProps = {
  products: Product[];
  onClick: (name: string) => void;
  addToCart: (productId: number) => void;
};

const ProductList: React.FC<PlanListProps> = ({
  products,
  onClick,
  addToCart,
}) => {
  const formik = useFormikContext<NewUserData>();
  return (
    <>
      {products.map((product) => {
        return (
          <Item
            product={product}
            key={product.id}
            onClick={onClick}
            addToCart={addToCart}
            formValues={formik.values}
          />
        );
      })}
    </>
  );
};

export default ProductList;
