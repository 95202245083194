import React, { FC } from "react";
import { Container, Spinner } from "react-bootstrap";

interface ILoader {
  variant?: "light";
}

const Loader: FC<ILoader> = ({ variant }) => {
  return (
    <Container className="d-flex justify-content-center align-items-center w-100 h-100 bg-red-500">
      <Spinner animation="grow" variant={variant ? variant : "danger"} />
    </Container>
  );
};

export default Loader;
