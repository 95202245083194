import React, { FC, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { fetchPlans, User } from "../../actions/wp";
import { EyeIcon } from "../../assets/images/svg/Icons";
import links from "../../constants/links";
import { formatDate } from "../../functions/formatDate";
import { useWpSelector } from "../../selectors";
import Loader from "../Loader/Loader";
import ClientPackage from "./elements/ClientPackage";
import "./TrainerClients.scss";

interface ITrainerClients {
  clients: User[] | [];
  isLoading: boolean;
  error: string;
  currentUser: any; // Add the currentUser prop
}

const TrainerClients: FC<ITrainerClients> = ({
  clients,
  error,
  isLoading,
  currentUser,
}) => {
  const dispatch = useDispatch();
  const { plans } = useWpSelector();

  useEffect(() => {
    dispatch(fetchPlans());
  }, [dispatch]);

  // Filter clients based on the currentUser and their plan ID
  const filteredClients = currentUser?.id === 234
    ? clients.filter((client: User) => [1652, 1653].includes(Number(client.meta.plan)))
    : clients.filter((client: User) => ![1652, 1653].includes(Number(client.meta.plan)));

  return (
    <div>
      {!isLoading ? (
        !error ? (
          <div className="list">
            <header className="list_header">
              <div className="text-uppercase list_header__item">
                Imię i nazwisko
              </div>
              <div className="text-uppercase list_header__item">
                Dołączył/a
              </div>
              <div className="text-uppercase list_header__item">Pakiet</div>
              <div className="text-uppercase list_header__item">
                Pakiet wygasa
              </div>
              <div className="text-uppercase list_header__item">Akcje</div>
            </header>
            <div className="list__wrapper">
              {filteredClients?.map((client: User) => (
                <div className="list_item" key={client.id}>
                  <span className="d-block d-flex align-items-center">
                    <span className="info__mobile">Imię i nazwisko:</span>
                    <span>{client.name}</span>
                  </span>
                  <time className="d-block d-flex align-items-center">
                    <span className="info__mobile">Dołączył/a:</span>{" "}
                    <time>{formatDate(client.user_registered)}</time>
                  </time>
                  <span className="d-block d-flex align-items-center">
                    <span className="info__mobile">Pakiet:</span>
                    <ClientPackage id={client.meta.plan} plans={plans} />
                  </span>
                  <span className="d-block d-flex align-items-center">
                    <span className="info__mobile">Pakiet wygasa:</span>
                    <span className="item_title">11.24.2024</span>
                  </span>
                  <div className="d-flex justify-content-between align-items-center item__col item__col--right list_item__details">
                    <Link
                      to={`${links.trainerClients}/${client.id}`}
                      className="p-0 text-decoration-none "
                    >
                      <span>podgląd</span>
                      <EyeIcon />
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <Alert variant="danger">{error}</Alert>
        )
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default TrainerClients;
