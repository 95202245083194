import React from "react";
import { DietPlan } from "../DietsCreatorPage";
//@ts-ignore
import fontBarlow from "../../../../../assets/fonts/Barlow-Regular.ttf";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

interface IDietDoc {
  content: DietPlan;
}

Font.register({
  family: "Barlow",
  src: fontBarlow,
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    fontFamily: "Barlow",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  image: {
    position: "absolute",
    minWidth: "100%",
    minHeight: "100%",
    height: "100%",
    width: "100%",
    zIndex: -1,
  },
  title: {
    fontSize: 20,
    textAlign: "center",
    backgroundColor: "#f7f7f7",
    padding: 10,
    marginBottom: 10,
  },
  paragraph: {
    fontSize: 12,
    textAlign: "center",
    color: '#333',
    marginTop: 20,
  },
  day: {
    fontSize: 16,
    textAlign: "center",
    backgroundColor: "#f7f7f7",
    padding: 10,
    marginBottom: 10,
  },
  meal: {
    fontSize: 12,
    margin: 10,
    padding: 10,
    backgroundColor: "#fff",
    borderBottom: "1pt solid #ddd",
  },
});

const DietDoc: React.FC<IDietDoc> = ({ content }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>HEALTH AND BODY ACADEMY</Text>
          {Object.keys(content.days).map((day) => {
            const meals = content.days[Number(day)].meals;
            return (
              <div key={day}>
                <Text style={styles.day}>{`Dzien: ${day}`}</Text>
                {Object.keys(meals).map((meal) => (
                  <Text
                    key={`${day}-${meal}`}
                    style={styles.meal}
                  >{`Posilek ${meal}: ${meals[+meal]}`}</Text>
                ))}
              </div>
            );
          })}
          <Text style={styles.paragraph}>
            Pamiętaj proszę, żeby codziennie wypijać od 2 do 4 litrów wody. W ciągu całego dnia zjeść 300-400g najlepiej świeżych warzyw, rozłożonych mniej więcej po równo do każdego posiłku. Dodać małego owoca, np jabłko lub banana, do posiłku potreningowego. 2-3 razy w tygodniu jeść ryby.
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default DietDoc;
