import RootLayout from "../../layouts/RootLayout";
import Hero from "./sections/Hero";
import AboutUsSection from "./sections/AboutUsSection";
import NumbersSection from "./sections/NumbersSection";
import Cta from "../../components/Cta/Cta";
import PagesBreadcrumbs from "../../components/PagesBreadcrumbs/PagesBreadcrumbs";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const AboutUsPage = () => {
  const location = useLocation();

  useEffect(() => {
    const target = location.hash;
    if (target === "#agnieszka" || target === "#michal") {
      const currentTarget = target.replace(/#/g, "");
      const element = document.getElementById(currentTarget);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <RootLayout>
      <main id="main">
        <PagesBreadcrumbs />
        <Hero />
        <AboutUsSection />
        <NumbersSection />
        <Cta />
      </main>
    </RootLayout>
  );
};

export default AboutUsPage;
