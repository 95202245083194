import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { Alert } from "react-bootstrap";

import "./Reports.scss";
import { fetchPosts, deletePost } from "../../../../../actions/wp";
import {
  EyeIcon,
  EditIcon,
  CloseIconSmall,
} from "../../../../../assets/images/svg/Icons";
import Loader from "../../../../../components/Loader/Loader";
import { formatDate } from "../../../../../functions/formatDate";
import { useWpSelector } from "../../../../../selectors";
import RaportsPerviewModal from "../Modal/RaportsPerviewModal";

// type ReportsProps = {
//   sortOrder: string;
// };

const Reports: React.FC<{ sortOrder: string }> = ({ sortOrder }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [initialValues, setInitialValues] = useState<any>();

  const { currentUser, posts } = useWpSelector();

  const [warningMsgStatus, setWarningMsgStatus] = useState(false);
  const [postToDelete, setPostToDelete] = useState<number | null>(null);

  useEffect(() => {
    if (currentUser) {
      dispatch(fetchPosts(currentUser.id));
    }
  }, [currentUser, dispatch]);

  const handleDelete = (postId: number) => {
    setPostToDelete(postId);
    setWarningMsgStatus(true);
  };

  const confirmDelete = () => {
    if (postToDelete !== null) {
      dispatch(deletePost(postToDelete, () => {}));
    }
    setWarningMsgStatus(false);
  };

  const sortedPosts = useMemo(() => {
    if (sortOrder === "newest") {
      return [...posts].sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
    }
    return [...posts].sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );
  }, [posts, sortOrder]);

  return (
    <div className="reports__list">
      {warningMsgStatus && (
        <Alert
          variant="warning"
          onClose={() => setWarningMsgStatus(false)}
          dismissible
        >
          Czy na pewno chcesz usunąć ten post?
          <button onClick={confirmDelete}>Tak</button>
          <button onClick={() => setWarningMsgStatus(false)}>Nie</button>
        </Alert>
      )}
      {posts ? (
        <>
          <header className="d-none d-xl-flex reports__list__header">
            <div className="header__item">
              <span className="d-inline-block text-uppercase header__text">
                Okres
              </span>
              <span className="d-inline-block text-uppercase header__text">
                Typ
              </span>
              <span className="d-inline-block text-uppercase header__text">
                Tytuł
              </span>
            </div>
            <div className="header__item" aria-label="Akcje">
              <span className="d-inline-block text-uppercase header__text">
                Akcje
              </span>
            </div>
          </header>
          <div className="reports__list__wrapper">
            {sortedPosts.map((post) => (
              <div className="item" key={post.id}>
                <div className="position-relative item__col item__col--left">
                  <time className="d-block">
                    {formatDate(post.meta.report_start_date)} -{" "}
                    {formatDate(post.meta.report_end_date)}
                  </time>
                  <span className="d-block">{post.meta.report_type}</span>
                  <h4 className="item-title">{post.title.rendered}</h4>
                </div>
                <div className="d-flex justify-content-between align-items-center item__col item__col--right">
                  <button
                    type="button"
                    className="d-flex align-items-center p-0"
                    onClick={() => {
                      setInitialValues(post);
                      setShowModal(true);
                    }}
                  >
                    <span>podgląd</span>
                    <EyeIcon />
                  </button>
                  <button
                    type="button"
                    className="d-flex align-items-center p-0"
                  >
                    <span>edytuj</span>
                    <EditIcon />
                  </button>
                  <button
                    type="button"
                    className="d-flex align-items-center p-0"
                    onClick={() => handleDelete(post.id)}
                  >
                    <span>usuń</span>
                    <CloseIconSmall />
                  </button>
                </div>
              </div>
            ))}
          </div>
          {initialValues && (
            <RaportsPerviewModal
              raport={initialValues}
              handleClose={() => setShowModal(false)}
              showModal={showModal}
            />
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Reports;
