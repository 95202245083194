import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { Form as FormikForm, Formik } from "formik";

import "./DetailsForm.scss";
import { User, updateUser } from "../../../../../actions/wp";
import FormField from "../../../../../components/Form/FormField";
import InputField from "../../../../../components/Form/InputField";

interface IDetailsForm {
  currentUser: User;
}

const DetailsForm: React.FC<IDetailsForm> = ({ currentUser }) => {
  const dispatch = useDispatch();

  const handleUpdateUser = (values: any) => {
    const updatedUserData = {
      meta: {
        age: values.age,
        height: values.height,
        weight: values.weight,
        injury: values.injury,
        preferences: values.preferences,
        important: values.important,
      },
    };

    dispatch(updateUser(currentUser.id.toString(), updatedUserData));
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        age: currentUser.meta.age || "",
        height: currentUser.meta.height || "",
        weight: currentUser.meta.weight || "",
        injury: currentUser.meta.injury || "",
        preferences: currentUser.meta.preferences || "",
        important: currentUser.meta.important || "",
      }}
      onSubmit={handleUpdateUser}
    >
      {({ isSubmitting }) => (
        <FormikForm className="form">
          <div className="form__wrapper">
            <InputField
              type="number"
              name="height"
              label="WZROST"
              placeholder={`${currentUser ? currentUser.meta.height : "[CM]"}`}
              labelIcon="height"
              component={FormField}
            />
            <InputField
              type="number"
              name="weight"
              label="WAGA bieżąca"
              placeholder="[KG]"
              labelIcon="scale"
              component={FormField}
            />
            <InputField
              type="number"
              name="age"
              label="WIEK"
              placeholder="[LATA]"
              labelIcon="birth"
              component={FormField}
            />
            <InputField
              type="text"
              name="injury"
              label="KONTUZJE"
              placeholder="Wpisz Twoje kontuzje"
              labelIcon="bone"
              component={FormField}
              span="Tutaj wypisz wszystkie kontuzje, które mogą mieć wpływ na treningi."
            />
            <InputField
              type="text"
              name="preferences"
              label="PREFERENCJE ŻYWNOŚCIOWE"
              placeholder="Wpisz Twoje preferencje"
              labelIcon="restaurant"
              span="Jeśli jesteś wegetarianinem, weganem lub preferujesz konkretny rodzaj diety - to dobre miejsce aby o tym wspomnieć."
              component={FormField}
            />
            <InputField
              type="textarea"
              name="important"
              rows={3}
              label="WAŻNE"
              placeholder=""
              labelIcon="info"
              span="W tym polu wpisz wszystkie ważne informacje dotyczące Twoich preferencji, ograniczeń motorycznych lub żywieniowych np. uczulenia, uszkodzenia ciała itp."
              component={FormField}
            />
          </div>
          <Button variant="secondary" type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Trwa zapisywanie..." : "Zapisz zmiany"}
          </Button>
        </FormikForm>
      )}
    </Formik>
  );
};

export default DetailsForm;
