import { Field, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { NewUserData } from "../../../../../actions/wp";
import FormField from "../../../../../components/Form/FormField";
import { gender } from "../../data";
import { StepProps } from "../../interfaces";

const StepOne: React.FC<StepProps> = () => {
  const formik = useFormikContext<NewUserData>();

  useEffect(() => {
    !formik.values.stepData.gender &&
      formik.setFieldValue("stepData.gender", gender[0].name);
  }, [formik]);

  return (
    <>
      <h2 className="form-heading text-center">
        ZACZYNAMY <span>TWOJĄ</span> PRZEMIANĘ
      </h2>
      <Form.Group className="form-group-row">
        <label className="form-label">Twoja płeć:</label>
        <div className="form-field d-flex overflow-hidden">
          {gender.map((el) => (
            <button
              type="button"
              key={el.name}
              onClick={() => {
                formik.setFieldValue("stepData.gender", el.name);
              }}
              className={`form-button ${
                formik.values.stepData.gender === el.name
                  ? "form-button--active"
                  : ""
              }`}
            >
              {el.name}
            </button>
          ))}
        </div>
      </Form.Group>
      <Field
        type="number"
        name="stepData.height"
        label="Wzrost:"
        placeholder="[CM]"
        icon="user"
        component={FormField}
        value={formik.values.stepData.height}
      />
      <Field
        type="number"
        name="stepData.age"
        label="Wiek:"
        placeholder="[LATA]"
        icon="calendar"
        component={FormField}
        value={formik.values.stepData.age}
      />
      <Field
        type="number"
        name="stepData.weight"
        label="Waga:"
        placeholder="[KG]"
        icon="own"
        component={FormField}
        value={formik.values.stepData.weight}
      />
      <Form.Group>
        <div
          className="d-flex flex-wrap align-items-center form-group-training"
          role="group"
        >
          <label className="d-block w-100 label">
            Ilość treningów w tygodniu:
          </label>
          <div>
            <Field
              type="radio"
              id="report_points_mood_3"
              name="stepData.training_days"
              value="2"
            />
            <label htmlFor="report_points_mood_3">2</label>
          </div>
          <div>
            <Field
              type="radio"
              id="report_points_mood_4"
              name="stepData.training_days"
              value="3"
            />
            <label htmlFor="report_points_mood_4">3</label>
          </div>
          <div>
            <Field
              type="radio"
              id="report_points_mood_5"
              name="stepData.training_days"
              value="4"
            />
            <label htmlFor="report_points_mood_5">4</label>
          </div>
        </div>
      </Form.Group>
      {/* <Field
        type="number"
        name="stepData.training_days"
        label="Dni treningowe w tygodniu"
        placeholder="[3]"
        component={FormField}
        value={formik.values.stepData.training_days}
      /> */}
    </>
  );
};

export default StepOne;
