import adaptacjaImg from "../../../assets/images/png/adaptacja.png";
import turboImg from "../../../assets/images/png/turbo.png";
import superChargedImg from "../../../assets/images/png/super-charged.png";
import xtremeImg from "../../../assets/images/png/x-treme.png";
import domowyImg from "../../../assets/images/png/domowy.png";
import zdroweSerceImg from "../../../assets/images/png/zdrowe-serce.png";

export const mockedPackages = [
  {
    id: 1,
    title: "ADAPTACJA",
    duration: "4 tygodni",
    target:
      "Pierwszy raz z H&B Academy, Powracający do formy po dłuższej przerwie",
    desc: "To oferta przygotowana specjalnie dla zaczynających przygodę z Health & Body Academy. Miesiąc adaptacyjny pozwoli Ci na zrównoważony trening, dzięki któremu z łatwością rozpoczniesz intensywny cykl treningowy pod moimi skrzydłami. Polecam!",
    includes: [
      {
        id: 1,
        text: "Zawiera pakiet darmowych gadżetów marki Health and Body Academy: bidon i ręcznik!",
        strong: true,
      },
      {
        id: 2,
        text: "Uniwersalny plan treningowy miesięcznego okresu adaptacji",
        strong: false,
      },
      {
        id: 3,
        text: "Dostęp do wybranych autorskich klipów instruktażowych, dzięki którym poprawnie wykonasz każde ćwiczenie.",
        strong: false,
      },
      {
        id: 4,
        text: "Dostęp do intuicyjnego kreatora posiłków. Ja liczę Twoje zapotrzebowanie. Ty wybierasz co i kiedy jesz. To takie proste!",
        strong: false,
      },
      {
        id: 5,
        text: "Automatycznie przekalkulowane zapotrzebowanie makroskładników oparte o własny algorytm Michała. ",
        strong: false,
      },
    ],
    price: "299.00",
    img: adaptacjaImg,
    bestseller: true,
  },
  {
    id: 2,
    title: "Turbo",
    duration: "3 miesiące",
    target:
      "Absolwenci pakietu ADAPTACJA, Osoby niepewne swojej motywacji do dłuższego cyklu treningowego",
    desc: "To pierwszy pakiet uwalniający pełne możliwości treningu z H&B Academy. Największą przewagą nad pakietem ADAPTACJA jest dostęp do panelu czatu z trenerem oraz posiłki indywidualnie rozpisane przez Michała.",
    includes: [
      {
        id: 1,
        text: "Monitorowanie rozwoju w oparciu o cyfrowe raporty i zdjęcia.",
        strong: true,
      },
      {
        id: 2,
        text: "Osobisty kontakt z trenerem przez okno czatu przez 7 dni w tygodniu.",
        strong: true,
      },
      {
        id: 3,
        text: "Indywidualne prowadzenie ze specjalnie dostosowaną dietą oraz treningami przez Michała",
        strong: true,
      },
      {
        id: 4,
        text: "Automatycznie przekalkulowane zapotrzebowanie makroskładników oparte o własny algorytm Michała. ",
        strong: false,
      },
      {
        id: 5,
        text: "Analiza zmian po ćwiczeniach i stosowaniu diety w miesiącu adaptacyjnym (opcjonalne)",
        strong: false,
      },
      {
        id: 6,
        text: "Zawiera pakiet darmowych gadżetów marki Health and Body Academy: bidon i ręcznik!",
        strong: false,
      },
      {
        id: 7,
        text: "Dostęp do wybranych autorskich klipów instruktażowych, dzięki którym poprawnie wykonasz każde ćwiczenie.",
        strong: false,
      },
      {
        id: 8,
        text: "Nieograniczony dostęp do intuicyjnego kreatora posiłków (opcjonalne).",
        strong: false,
      },
    ],
    img: turboImg,
    price: "799.00",
  },
  {
    id: 3,
    title: "Super Charged",
    duration: "6 miesięcy",
    target: "Osoby zmotywowane do dłuższego cyklu treningowego",
    desc: "Półroczny pakiet treningowy to idealne rozwiązanie dla tych, którzy chcą zobaczyć poważne zmiany w swojej sylwetce. Jeśli chcesz osiągnąć spektakularne rezultaty i jednocześnie  jesteś pewny swojej dyscypliny - to pakiet właśnie dla Ciebie.",
    includes: [
      {
        id: 1,
        text: "Godzinna konsultacja video w wybranym przez Ciebie terminie - skonsultuj przyrosty  lub podsumuj trening.",
        strong: true,
      },
      {
        id: 2,
        text: "Monitorowanie rozwoju w oparciu o cyfrowe raporty i zdjęcia.",
        strong: true,
      },
      {
        id: 3,
        text: "Osobisty kontakt z trenerem przez okno czatu przez 7 dni w tygodniu.",
        strong: true,
      },
      {
        id: 4,
        text: "Automatycznie przekalkulowane zapotrzebowanie makroskładników oparte o własny algorytm Michała. ",
        strong: true,
      },
      {
        id: 5,
        text: "Indywidualne prowadzenie ze specjalnie dostosowaną dietą oraz treningami przez Michała",
        strong: false,
      },
      {
        id: 6,
        text: "Analiza zmian po ćwiczeniach i stosowaniu diety w miesiącu adaptacyjnym (opcjonalne)",
        strong: false,
      },
      {
        id: 7,
        text: "Zawiera pakiet darmowych gadżetów marki Health and Body Academy: bidon i ręcznik!",
        strong: false,
      },
      {
        id: 8,
        text: "Dostęp do wybranych autorskich klipów instruktażowych, dzięki którym poprawnie wykonasz każde ćwiczenie.",
        strong: false,
      },
      {
        id: 9,
        text: "Nieograniczony dostęp do intuicyjnego kreatora posiłków (opcjonalne).",
        strong: false,
      },
      {
        id: 10,
        text: "Automatycznie przekalkulowane zapotrzebowanie makroskładników oparte o własny algorytm Michała. ",
        strong: false,
      },
    ],
    img: superChargedImg,
    price: "1299.00",
  },
  {
    id: 4,
    title: "x-treme",
    duration: "12 miesięcy",
    target:
      "Osoby gotowe na roczny cykl treningów, Osoby chcące trenować z nami w stosunkowo najlepszej cenie",
    desc: "To czas na totalną  przemianę! Pakiet X-treme przeznaczony jest dla Ciebie jeśli pragniesz na przestrzeni roku całkowicie przemodelować swoją sylwetkę. Gwarantujemy, że z tym pakietem osiągniesz swoje wymarzone ciało.",
    includes: [
      {
        id: 1,
        text: "Kontrolna sesja treningowa na żywo w celu skorygowania ewentualnych błędów i poprawy techniki",
        strong: true,
      },
      {
        id: 2,
        text: "Godzinna konsultacja video w wybranym przez Ciebie terminie - skonsultuj przyrosty  lub podsumuj trening.",
        strong: true,
      },
      {
        id: 3,
        text: "Monitorowanie rozwoju w oparciu o raporty i zdjęcia.",
        strong: true,
      },
      {
        id: 4,
        text: "Indywidualne prowadzenie ze specjalnie dostosowaną dietą oraz treningami przez Michała",
        strong: true,
      },
      {
        id: 5,
        text: "Indywidualne prowadzenie ze specjalnie dostosowaną dietą oraz treningami przez Michała",
        strong: false,
      },
      {
        id: 6,
        text: "Analiza zmian po ćwiczeniach i stosowaniu diety w miesiącu adaptacyjnym (opcjonalne)",
        strong: false,
      },
      {
        id: 7,
        text: "Zawiera pakiet darmowych gadżetów marki Health and Body Academy: bidon i ręcznik!",
        strong: false,
      },
      {
        id: 8,
        text: "Dostęp do wybranych autorskich klipów instruktażowych, dzięki którym poprawnie wykonasz każde ćwiczenie.",
        strong: false,
      },
      {
        id: 9,
        text: "Nieograniczony dostęp do intuicyjnego kreatora posiłków (opcjonalne).",
        strong: false,
      },
      {
        id: 10,
        text: "Automatycznie przekalkulowane zapotrzebowanie makroskładników oparte o własny algorytm Michała. ",
        strong: false,
      },
    ],
    img: xtremeImg,
    price: "1699.00",
    bestPrice: true,
  },
  {
    id: 5,
    title: "DOMOWY",
    duration: "2 miesiące",
    target:
      "Osoby lubiące ćwiczyć bez wychodzenia z domu",
    desc: "Wolisz ćwiczyć w zaciszu własnego domu? Pakiet Domowy to rozwiązanie dla Ciebie! Dzięki profesjonalnym klipom wideo, które wskażą Ci aktywowane partie ciała i prawidłową technikę, możesz cieszyć się efektywnymi treningami bez wychodzenia z domu. Trenuj na własnych warunkach - oszczędzaj czas i bądź wolny/a!",
    includes: [
      {
        id: 1,
        text: "Spektakularne efekty bez skomplikowanych, profesjonalnych maszyn!",
        strong: true,
      },
      {
        id: 2,
        text: "Równie skuteczny co treningi na siłowni.",
        strong: true,
      },
      {
        id: 3,
        text: "Godzinna konsultacja video w wybranym przez Ciebie terminie - skonsultuj przyrosty  lub podsumuj trening.",
        strong: true,
      },
      {
        id: 4,
        text: "Monitorowanie rozwoju w oparciu o cyfrowe raporty i zdjęcia.",
        strong: true,
      },
      {
        id: 5,
        text: "Osobisty kontakt z trenerem przez okno czatu przez 7 dni w tygodniu.",
        strong: true,
      },
      {
        id: 6,
        text: "Indywidualne prowadzenie ze specjalnie dostosowaną dietą oraz treningami przez Michała",
        strong: true,
      },
      {
        id: 7,
        text: "Zawiera pakiet darmowych gadżetów marki Health and Body Academy: bidon i ręcznik!",
        strong: false,
      },
      {
        id: 8,
        text: "Dostęp do wybranych autorskich klipów instruktażowych, dzięki którym poprawnie wykonasz każde ćwiczenie.",
        strong: false,
      },
      {
        id: 9,
        text: "Nieograniczony dostęp do intuicyjnego kreatora posiłków (opcjonalne).",
        strong: false,
      },
      {
        id: 10,
        text: "Automatycznie przekalkulowane zapotrzebowanie makroskładników oparte o własny algorytm Michała. ",
        strong: false,
      },
    ],
    img: domowyImg,
    price: "599.00",
    bestPrice: false,
  },
  {
    id: 6,
    title: "W GÓRĘ SERCA",
    duration: "1 miesiąc",
    target:
      "Początkujący, Osoby ze słabą kondycją serca, Osoby chcący przede wszystkim zadbać o sportową sylwetkę i długowieczność.",
    desc: "Masz nadciśnienie tętnicze, cukrzycę, jesteś po zawale serca, zażywasz leki i nie wiesz jakie ćwiczenia są dla ciebie bezpieczne? Zachęcam Cię, niezależnie od Twojego wieku, do zadbania o jakość swojego życia. Przekonasz się, że nie trzeba trenować na profesjonalnej siłowni, aby mieć sportową sylwetkę i silne, zdrowe serducho. Wystarczy wam trochę miejsca w garażu lub pokoju, drążek, gumy, ławeczka i pakiet ZDROWE SERCE!",
    includes: [
      {
        id: 1,
        text: "Darmowa wizyta u lekarza kardiologa ze szczegółową analizą stanu zdrowia, kondycji serca, badaniami lekarskimi, badaniami EKG, badaniami wydolności",
        strong: true,
      },
      {
        id: 2,
        text: "Dostęp online do webinarów motywacyjnych i edukacyjnych nt. długowieczności, zdrowego życia i dbania o serce,",
        strong: true,
      },
      {
        id: 3,
        text: "Dostęp do zamkniętej grupy na FB prowadzonej przez lekarza kardiologa, na której możesz zapytać go o porady dot. zdrowego stylu życia,",
        strong: true,
      },
      {
        id: 4,
        text: "Godzinna konsultacja video w wybranym przez Ciebie terminie - skonsultuj przyrosty  lub podsumuj trening.",
        strong: true,
      },
      {
        id: 5,
        text: "Monitorowanie rozwoju w oparciu o raporty i zdjęcia.",
        strong: true,
      },
      {
        id: 6,
        text: "Osobisty kontakt z trenerem przez okno czatu przez 7 dni w tygodniu.",
        strong: true,
      },
      {
        id: 7,
        text: "Indywidualne prowadzenie ze specjalnie dostosowaną dietą oraz treningami przez Michała.",
        strong: true,
      },
      {
        id: 8,
        text: "Analiza zmian po ćwiczeniach i stosowaniu diety w miesiącu adaptacyjnym (opcjonalne)",
        strong: false,
      },
      {
        id: 9,
        text: "Zawiera pakiet darmowych gadżetów marki Health and Body Academy: bidon i ręcznik!",
        strong: false,
      },
      {
        id: 10,
        text: "Dostęp do wybranych autorskich klipów instruktażowych, dzięki którym poprawnie wykonasz każde ćwiczenie.",
        strong: false,
      },
      {
        id: 11,
        text: "Nieograniczony dostęp do intuicyjnego kreatora posiłków (opcjonalne).",
        strong: false,
      },
      {
        id: 12,
        text: "Automatycznie przekalkulowane zapotrzebowanie makroskładników oparte o własny algorytm Michała.",
        strong: false,
      },
    ],
    img: zdroweSerceImg,
    price: "1799.00",
    bestPrice: false,
  }
];
