import { CTAIcon } from "../../assets/images/svg/Icons";
import links from "../../constants/links";
import Button from "../Buttons/LinkButton";
import PageHeading from "../headings/PageHeading";
import "./Cta.scss";

const Cta = () => {
  return (
    <section className="cta">
      <div className="container">
        <div className="position-relative cta__wrapper">
          <header className="position-relative d-flex justify-content-center overflow-hidden cta__header">
            <div className="d-flex align-items-center cta__heading">
              <PageHeading variant="h2">
                <span>NIE CZEKAJ</span>
              </PageHeading>
              <PageHeading variant="h2">
                <span>NIE CZEKAJ</span>
              </PageHeading>
              <PageHeading variant="h2">NIE CZEKAJ</PageHeading>
              <PageHeading variant="h2">
                <span>NIE CZEKAJ</span>
              </PageHeading>
              <PageHeading variant="h2">
                <span>NIE CZEKAJ</span>
              </PageHeading>
            </div>
          </header>
          <PageHeading variant="h2">NA PRZYPŁYW MOTYWACJI!</PageHeading>
          <div className="position-relative text-center cta__content">
            <p>
              Rozpocznij pod moimi skrzydłami drogę do upragnionej sylwetki.
            </p>
            <Button
              type="link"
              href={links.signUp}
              text="KUP PAKIET"
              variant="primary"
              color="red"
            />
          </div>
          <div className="svg-container svg-container--left">
            <CTAIcon />
          </div>
          <div className="svg-container svg-container--right">
            <CTAIcon />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cta;
