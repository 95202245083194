import RootLayout from "../../layouts/RootLayout";
import PagesBreadcrumbs from "../../components/PagesBreadcrumbs/PagesBreadcrumbs";
import PageHeading from "../../components/headings/PageHeading";
import "./CookiesPage.scss";

const CookiesPage = () => {
  return (
    <RootLayout>
      <main id="main">
        <PagesBreadcrumbs />
        <section className='terms'>
          <div className="container">
            <div className="terms__wrapper">
              <header className='terms__header'>
                <PageHeading variant="h1">KONIECZNIE SIĘ ZAPOZNAJ!</PageHeading>
                <PageHeading variant="h2">POLITYKA COOKIES</PageHeading>
              </header>
              <div className="terms__content">
                <ol type="1">
                  <li>Witryna gromadzi w sposób automatyczny informacje, które zawarte są w plikach cookies,</li>
                  <li>Cookies to inaczej pliki tekstowe zarchiwizowane na urządzeniach końcowych użytkowników witryny. Zazwyczaj zawierają informacje o adresie strony, z której pochodzą, unikalny numer oraz czas wygaśnięcia,</li>
                  <li>W ramach witryny stosuje się dwa typy plików cookies. Są to sesyjne i stałe. Te pierwsze są plikami archiwizowanymi jedynie do momentu wyłączenia przeglądarki. Cookies stałe zachowują się przez szczegółowo wyznaczony w parametrach pliku czas bądź też do aż użytkownik ich nie usunie</li>
                </ol>
                <div dangerouslySetInnerHTML={{ __html: `
                  <ol start="4">
                    <li>Wykorzystywanie cookies w celach:</li>
                    <ol type="a">
                      <li>Spersonalizowania potrzeb użytkownika,</li>
                      <li>Czasowego zapamiętywania niektórych ustawień wyszukiwania,</li>
                      <li>Odpowiedniego działania komunikatora czatu</li>
                    </ol>
                    <li>Użytkownik nie jest zobligowany do zatwierdzania zgodny na osadzaniu cookies na jego urządzeniu. Aby dezaktywować obsługę plików typu cookies powinno się wyłączyć tą usługę w ustawieniach przeglądarki. Więcej informacji znajdziecie na witrynach internetowych:</li>
                    <ol type="a">
                      <li>Google Chrome,</li>
                      <li>Mozilla Firefox,</li>
                      <li>Internet Explorer,</li>
                      <li>Safari</li>
                    </ol>
                    <li>Należy się liczyć z faktem, że wyłączenie plików bądź też ograniczenie funkcjonalności plików cookie może znacznie wypłynąć na prawidłowe działanie witryny. Jeśli kontynuujesz użytkowanie serwisu akceptujesz powyższe założenia</li>
                  </ol>
                `}} />
              </div>
              <footer className="text-center terms__footer">
                <a className='position-relative d-inline-block text-decoration-none' href="/">
                  <div className="position-relative d-flex align-items-center svg-container">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.8337 10H4.16699" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M10.0003 15.8337L4.16699 10.0003L10.0003 4.16699" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <span className='d-inline-block font-italic text-uppercase'>Wróć</span>
                  </div>
                </a>
              </footer>
            </div>
          </div>
        </section>
      </main>
    </RootLayout>
  );
};

export default CookiesPage;
