import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";

import RouteHeading from "../../../../../components/headings/RouteHeading";

import { Form as FormikForm, Formik } from "formik";

import "../../../../../components/Goals/Goals.scss";
import "./TargetForm.scss";
import { fetchGoals, updateUser, User } from "../../../../../actions/wp";
import { useWpSelector } from "../../../../../selectors";
import Item from "../../../../../components/Goals/Item";
import InputField from "../../../../../components/Form/InputField";
import FormField from "../../../../../components/Form/FormField";

interface ITargetForm {
  currentUser: User;
}

const TargetForm: React.FC<ITargetForm> = ({ currentUser }) => {
  const dispatch = useDispatch();
  const { goals } = useWpSelector();
  const [activeGoalId, setActiveGoalId] = useState(currentUser.meta.goal);

  useEffect(() => {
    dispatch(fetchGoals());
  }, [dispatch]);

  const handleGoalClick = (
    goalId: string,
    setFieldValue: (field: string, value: any) => void
  ) => {
    setActiveGoalId(goalId);
    setFieldValue("goal", goalId);
  };

  const handleUpdateUser = (values: any) => {
    const updatedUserData = {
      meta: {
        goal: values.goal,
        weight: values.weight,
        weight_current: values.weight_current,
        weight_target: values.weight_target,
      },
    };

    dispatch(updateUser(currentUser.id.toString(), updatedUserData));
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        goal: currentUser.meta.goal || "",
        weight: currentUser.meta.weight || "",
        weight_current: currentUser.meta.weight_current || 0,
        weight_target: currentUser.meta.weight_target || 0,
      }}
      onSubmit={handleUpdateUser}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <FormikForm className="form">
          <div className="form__wrapper">
            <header className="position-relative dashboard__header target__header">
              <RouteHeading>TWÓJ CEL</RouteHeading>
            </header>
            <Form.Group className="form-group-row form-group--full-width">
              <div className="goals">
                {goals.map((goal: any, index: any) => (
                  <Item
                    formValues={values}
                    key={index}
                    goal={goal}
                    isActive={activeGoalId === goal.id.toString()}
                    onClick={() =>
                      handleGoalClick(goal.id.toString(), setFieldValue)
                    }
                  />
                ))}
              </div>
            </Form.Group>
            <header className="position-relative dashboard__header target__header">
              <RouteHeading>SZCZEGÓŁY</RouteHeading>
            </header>
            <InputField
              type="number"
              name="weight"
              label="waga startowa"
              placeholder="[KG]"
              component={FormField}
            />
            <InputField
              type="number"
              name="weight_current"
              label="WAGA bieżąca"
              placeholder="[KG]"
              component={FormField}
            />
            <InputField
              type="number"
              name="weight_target"
              label="waga docelowa"
              placeholder="[KG]"
              component={FormField}
            />
          </div>
          <Button variant="secondary" type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Trwa zapisywanie..." : "Zapisz zmiany"}
          </Button>
        </FormikForm>
      )}
    </Formik>
  );
};

export default TargetForm;
