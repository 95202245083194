import React from "react";
import { Link } from "react-router-dom";

type NavItemProps = {
  curPathName: string;
  toPath: string;
  linkText: string;
  iconClass: string;
  Icon: React.FC;
  activePath?: boolean;
};

const NavItem: React.FC<NavItemProps> = ({
  curPathName,
  toPath,
  linkText,
  iconClass,
  Icon,
  activePath,
}) => {
  return (
    <Link
      to={toPath}
      className={
        curPathName === toPath || (activePath && curPathName.includes(toPath))
          ? `nav-link active`
          : `nav-link`
      }
    >
      <Icon />
      <span className="text-uppercase">{linkText}</span>
    </Link>
  );
};

export default NavItem;
