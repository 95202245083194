import { useFormikContext } from "formik";
import React from "react";
import { Goal, NewUserData } from "../../actions/wp";
import Item from "./Item";

type GoalListProps = {
  goals: Goal[];
  onClick: (name: string) => void;
};

const GoalList: React.FC<GoalListProps> = ({ goals, onClick }) => {
  const formik = useFormikContext<NewUserData>();
  return (
    <>
      {goals.map((goal) => {
        return (
          <Item
            formValues={formik.values.stepData}
            goal={goal}
            key={goal.id}
            onClick={onClick}
          />
        );
      })}
    </>
  );
};

export default GoalList;
