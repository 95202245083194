import React from "react";
import "./WorthElements.scss";
import {
  LoveHandIcon,
  PhoneFooterIcon,
  InfluencerIcon,
  GoalIcon,
} from "../../assets/images/svg/Icons";
import { Link } from "react-router-dom";
import links from "../../constants/links";

const WorthElementsdata = [
  {
    id: 1,
    icon: <InfluencerIcon />,
    text: "<strong>Kontakt z trenerem 7 dni w tygodniu.</strong> Jesteś w dobrych rękach.",
  },
  {
    id: 2,
    icon: <PhoneFooterIcon />,
    text: "<strong>Cały program przemiany w Twoim telefonie.</strong> Wygodniej się nie da!",
  },
  {
    id: 3,
    icon: <LoveHandIcon />,
    text: "<strong>Trenujesz pod okiem mistrza.</strong> Mam ponad 20 lat doświadczenia na siłowni.",
  },
  {
    id: 4,
    icon: <GoalIcon />,
    text: "<strong>Ciągłe monitorowanie Twojej sylwetki</strong> dzięki rozbudowanemu panelowi użytkownika.",
  },
];

interface IWorthSection {
  variant: "primary" | "secondary";
}

const WorthElements: React.FC<IWorthSection> = ({ variant }) => {
  return (
    <footer className="footer-signup-container">
      <div className="footer-signup">
        {WorthElementsdata.map((icon) => (
          <div
            key={icon.id}
            className={`footer-element-wr ${
              variant === "primary" && "primary"
            }`}
          >
            <div className="footer-wrapper-icon">{icon.icon}</div>
            <span dangerouslySetInnerHTML={{ __html: icon.text }}></span>
          </div>
        ))}
      </div>
      {variant === "secondary" && (
        <div className="footer-bottom">
          <span className="footer-signup-copy">
            Copyright @2023 Health And Body Academy. All Rights Reserved.
          </span>
          <nav className="footer-signup-nav">
            <Link to={links.privacyPolicy}>Polityka prywatności</Link>
            <Link to={links.terms}>Regulamin serwisu</Link>
          </nav>
        </div>
      )}
    </footer>
  );
};

export default WorthElements;
