import React, { useState } from 'react';
import PageHeading from "../headings/PageHeading";
import "./Faq.scss";

interface FaqItemProps {
  question: string;
  answer: string;
}

const FaqItem: React.FC<FaqItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className="faq__item">
      <div className="faq__item__wrapper">
        <div className="d-flex faq__question" onClick={toggleOpen}>
          <p className='m-0'>{question}</p>
          <div className="view-more">
            <span className='d-none d-lg-block'>{isOpen ? 'zwiń' : 'rozwiń'}</span>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 7.5L10 12.5L15 7.5" stroke="white" strokeWidth="3" strokeLinecap="square" />
            </svg>
          </div>
        </div>
      </div>
      {isOpen && <div className="faq__answer">{answer}</div>}
    </div>
  );
};

interface FaqProps {
  showHeader?: boolean;
}

const Faq: React.FC<FaqProps> = ({ showHeader = true }) => {
  return (
    <section className="faq">
      <div className="container">
        <div className='position-relative faq__wrapper'>
          {showHeader && (
            <header className='faq__header'>
              <span className="d-block font-italic text-center">ROZWIEWAM WĄTPLIWOŚCI</span>
              <PageHeading variant="h2">MASZ PYTANIE?</PageHeading>
            </header>
          )}
          <div className="faq__content">
            <FaqItem
              question="Czym specjalizuje się trener?"
              answer="Trener skupia się na treningach modelujących sylwetkę, redukujących tkankę tłuszczową, budujących masę mięśniową, a także na dietetyce i suplementacji. Jest również wykładowcą Akademii Sportowej Niva Fitness, prowadząc szkolenia i kursy dla trenerów personalnych."
            />
            <FaqItem
              question="Jakie są korzyści z korzystania z mojej platformy?"
              answer="Platforma Health And Body Academy oferuje mobilne centrum Twojej przemiany, dając dostęp do ponad 100 autorskich wideo instruktażowych, kontakt z trenerem przez 7 dni w tygodniu poprzez panel czatu, kalkulator zapotrzebowania kcal i makroskładników, system monitorowania sylwetki na podstawie raportów i zdjęć, oraz podgląd aktualnego planu treningowego."
            />
            <FaqItem
              question="Co zawiera pakiet ADAPTACJA i jakie są jego zalety?"
              answer="Pakiet ADAPTACJA jest naszym najtańszym pakietem i zawiera darmowe gadżety na siłownię (bidon i ręcznik), spersonalizowany plan treningowy miesięcznej adaptacji, dostęp do wybranych autorskich klipów instruktażowych, kreator posiłków oraz kalkulator makroskładników."
            />
            <FaqItem
              question="Jakie dodatkowe korzyści oferuje pakiet TURBO na 3 miesiące?"
              answer="Pakiet TURBO na 3 miesiące zawiera wszystko, co pakiet ADAPTACJA, plus monitorowanie rozwoju na podstawie raportów i zdjęć, osobisty kontakt z trenerem przez czat 7 dni w tygodniu, analiza zmian po ćwiczeniach i diecie w miesiącu adaptacyjnym (opcjonalne), oraz indywidualne prowadzenie ze specjalnie dostosowaną dietą i treningami."
            />
            <FaqItem
              question="Co wyróżnia pakiet SUPER CHARGED na 6 miesięcy?"
              answer="Pakiet SUPER CHARGED na 6 miesięcy obejmuje wszystkie korzyści pakietu ADAPTACJA, dodatkowo oferując godzinną konsultację video."
            />
            <FaqItem
              question="Dlaczego warto wybrać pakiet X-TREME na 12 miesięcy?"
              answer="Pakiet X-TREME na 12 miesięcy jest naszą najlepszą ofertą cenową, zawierającą wszystkie elementy pakietu ADAPTACJA oraz dodatkowe korzyści takie jak godzinna konsultacja video i darmowa sesja treningowa na żywo."
            />
            <FaqItem
              question="Jak wygląda kontakt z trenerem w ramach pakietów?"
              answer="W ramach pakietów, kontakt z trenerem odbywa się poprzez panel czatu dostępny 7 dni w tygodniu. W pakiecie ADAPTACJA panel czatu nie jest dostępny."
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
