import React from "react";
import Loader from "../../../../components/Loader/Loader";
import { useWpSelector } from "../../../../selectors";
import TrainerSettingsForm from "../TrainerSettings/elements/TrainerSettingsForm";

import LatestCustomers from "./elements/LatestCustomers";
import LatestRaports from "./elements/LatestRaports";
import "./TrainerDashboardPage.scss";

const TrainerDashboardPage = () => {
  const { currentUser } = useWpSelector();

  return currentUser ? (
    <section className="trainer__dashboard">
      <h2 className="trainer__dashboard-heading">
        <span>CZEŚĆ</span> {currentUser?.name}
      </h2>
      <LatestCustomers />
      <LatestRaports />
      <h3 className="trainer__dashboard-second-heading">USTAWIENIA</h3>
      <div className="dashboard__settings">
        <div className="settings">
          <TrainerSettingsForm />
        </div>
      </div>
    </section>
  ) : (
    <Loader />
  );
};

export default TrainerDashboardPage;
