import React from "react";
import "./PageHeading.scss";

interface IPageHeading {
  variant: "h1" | "h2" | "h3" | "h4";
  children: string | React.ReactNode;
}

const PageHeading: React.FC<IPageHeading> = ({ children, variant }) => {
  const HeadingTag = variant as keyof JSX.IntrinsicElements;

  return React.createElement(
    HeadingTag,
    { className: "heading", data_set: variant },
    children
  );
};

export default PageHeading;
