import React, { useState } from "react";
import { FieldProps, FormikProps } from "formik";
import { Form } from "react-bootstrap";
import "./FormField.scss";
import {
  InfoIcon,
  LockIcon,
  FillEyeOff,
  FillEye,
  HeightIcon,
  ScaleIcon,
  BirthdayIcon,
  BoneIcon,
  RestaurantFillIcon,
  UserIcon,
  CalendarIcon,
  OwnIcon,
  EmailIcon,
} from "../../assets/images/svg/Icons";

interface FormFieldProps {
  type: string;
  className: string;
  placeholder: string;
  checkboxValue?: string;
  label?: string;
  labelIcon?: string;
  icon?: string;
  onChange?: any;
  value?: any;
  defaultValue?: any;
  cols: number;
  rows: number;
  span: string;
  contactForm?: boolean;
  min?: string;
  max?: string;
}

const FormField: React.FC<FieldProps & FormFieldProps> = ({
  field,
  form,
  type,
  className,
  placeholder,
  label,
  icon,
  labelIcon,
  onChange,
  defaultValue,
  value,
  checkboxValue,
  cols,
  rows,
  contactForm,
  span,
  max,
  min,
}) => {
  const [showPass, setShowPass] = useState(false);
  if (contactForm) {
    return (
      <Form.Group>
        <div className={`form-label__wrapper${labelIcon ? " has-icon" : ""}`}>
          {labelIcon && (
            <div className="svg-container">
              {labelIcon === "height" && <HeightIcon />}
              {labelIcon === "scale" && <ScaleIcon />}
              {labelIcon === "birth" && <BirthdayIcon />}
              {labelIcon === "bone" && <BoneIcon />}
              {labelIcon === "restaurant" && <RestaurantFillIcon />}
              {labelIcon === "info" && <InfoIcon />}
            </div>
          )}
          {label && <Form.Label htmlFor={field.name}>{label}</Form.Label>}
        </div>
        <div className="form-field">
          <Form.Control
            type={type}
            {...field}
            // onChange={onChange}
            size="lg"
            placeholder={placeholder}
            className={className}
            defaultValue={defaultValue}
          />
          {icon === "user" && <UserIcon />}
          {icon === "calendar" && <CalendarIcon />}
          {icon === "own" && <OwnIcon />}
          {icon === "email" && <EmailIcon />}
        </div>
        {span && <span className="d-block form-field__dsc">{span}</span>}
        {renderError(form, field.name)}
      </Form.Group>
    );
  }

  switch (type) {
    case "textarea":
      return (
        <Form.Group>
          <div className={`form-label__wrapper${labelIcon ? " has-icon" : ""}`}>
            {labelIcon && (
              <div className="svg-container">
                {labelIcon === "info" && <InfoIcon />}
              </div>
            )}
            {label && <Form.Label htmlFor={field.name}>{label}</Form.Label>}
          </div>
          <div className="form-field">
            <Form.Control
              as="textarea"
              defaultValue={defaultValue}
              size="lg"
              rows={rows}
              cols={cols}
              className={`contentarea${className}`}
              {...field}
              placeholder={placeholder}
            />
          </div>
          {span && <span className="d-block form-field__dsc">{span}</span>}
          {renderError(form, field.name)}
        </Form.Group>
      );
    case "password":
      return (
        <Form.Group>
          {label && <Form.Label htmlFor={field.name}>{label}</Form.Label>}
          <div className="form-field">
            <Form.Control
              type={showPass ? "text" : "password"}
              defaultValue={defaultValue}
              {...field}
              size="lg"
              placeholder={placeholder}
              className={className}
            />
            {icon === "lock" && <LockIcon />}
            {icon === "eye" &&
              (!showPass ? (
                <FillEyeOff onClick={() => setShowPass(!showPass)} />
              ) : (
                <FillEye onClick={() => setShowPass(!showPass)} />
              ))}
          </div>
          {renderError(form, field.name)}
        </Form.Group>
      );
    case "checkbox":
      return (
        <Form.Group>
          <label className="checkbox-container">
            <span>{checkboxValue}</span>
            <Form.Control type="checkbox" {...field} />
            <span className="checkmark"></span>
          </label>
          {renderError(form, field.name)}
        </Form.Group>
      );
    default:
      return (
        <Form.Group>
          <div className={`form-label__wrapper${labelIcon ? " has-icon" : ""}`}>
            {labelIcon && (
              <div className="svg-container">
                {labelIcon === "height" && <HeightIcon />}
                {labelIcon === "scale" && <ScaleIcon />}
                {labelIcon === "birth" && <BirthdayIcon />}
                {labelIcon === "bone" && <BoneIcon />}
                {labelIcon === "restaurant" && <RestaurantFillIcon />}
                {labelIcon === "info" && <InfoIcon />}
              </div>
            )}
            {label && <Form.Label htmlFor={field.name}>{label}</Form.Label>}
          </div>
          <div className="form-field">
            <Form.Control
              type={type}
              {...field}
              // onChange={onChange}
              size="lg"
              min={min}
              max={max}
              placeholder={placeholder}
              className={className}
              value={value !== 0 ? value : ""}
              defaultValue={defaultValue}
            />
            {icon === "user" && <UserIcon />}
            {icon === "calendar" && <CalendarIcon />}
            {icon === "own" && <OwnIcon />}
            {icon === "email" && <EmailIcon />}
          </div>
          {span && <span className="d-block form-field__dsc">{span}</span>}
          {renderError(form, field.name)}
        </Form.Group>
      );
  }
};

const renderError = (form: FormikProps<any>, name: string) => {
  return (
    form.touched[name] &&
    form.errors[name] && (
      <span className="error-text" data-test={`error-text-${name}`}>
        {/* @ts-ignore */}
        {form.errors[name]}
      </span>
    )
  );
};

FormField.defaultProps = { className: "", placeholder: "" };

export default FormField;
