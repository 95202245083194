import { FC } from "react";
import { Plan } from "../../../actions/wp";
import "./ClientPackage.scss";

interface IClientPackage {
  id?: string;
  plans: Plan[];
}

const ClientPackage: FC<IClientPackage> = ({ id, plans }) => {
  const selectedPlan = plans.find((item: Plan) => item.id === +id!);

  const packageTitle = selectedPlan?.title.rendered;

  return (
    <div
      className={`package ${selectedPlan?.id === 28 && "super-charged"} ${
        selectedPlan?.id === 27 && "xtreme"
      }`}
    >
      {packageTitle}
    </div>
  );
};

export default ClientPackage;
