import React from "react";
import {
  AgnieszkaBorder,
  MichalBorder,
} from "../../../assets/images/svg/borderLines";
import {
  CupIcon,
  CheckIcon2,
  CheckIcon3,
} from "../../../assets/images/svg/Icons";
import {
  SmallAnimatedArrows,
  AnimatedArrows,
} from "../../../components/AnimatedArrows/AnimatedArrows";
import PageHeading from "../../../components/headings/PageHeading";
import AgnieszkaImg from "../../../assets/images/png/agnieszka.png";
import MichalImg from "../../../assets/images/png/michal.png";

import "./WhoAreWe.scss";

const WhoAreWe = () => {
  return (
    <section className="achievements">
      <div className="container">
        <header className="position-relative achievements__header">
          <div className="d-none d-lg-block arrows arrows--left">
            <SmallAnimatedArrows />
            <SmallAnimatedArrows />
            <AnimatedArrows />
          </div>
          <div className="header__content">
            <span className="d-inline-block font-italic">
              JESTEŚ W DOBRYCH RĘKACH
            </span>
            <PageHeading variant="h2">KIM JESTEM?</PageHeading>
          </div>
          <div className="d-none d-lg-block arrows arrows--right">
            <SmallAnimatedArrows />
            <SmallAnimatedArrows />
          </div>
        </header>
        <div className="position-relative achievements__content">
          <div className="achievement">
            <div className="position-relative d-inline-block d-xl-none achievement__image">
              <img src={MichalImg} alt="Michał Bobek" />
              <div className="border-line">
                <MichalBorder />
              </div>
            </div>
            <div className="achievement__content">
              <header className="achievement__header">
                <PageHeading variant="h3">
                  MICHAŁ&nbsp;<span>BOBYK</span>
                </PageHeading>
                <p>
                  Specjalizuje się w treningach modelujących sylwetkę,
                  redukujących tkankę tłuszczową oraz budujących masę mięśniową.
                  Często uczestniczy w szkoleniach w temacie dietetyki,
                  suplementacji oraz treningu w kształtowaniu sylwetki.
                </p>
                <p>
                  Jest również wykładowcą Akademii sportowej Niva Fitness.
                  Prowadzi te szkolenia oraz kursy dla trenerów personalnych.
                </p>
                <div className="d-flex align-items-center">
                  <CupIcon />
                  <span>Osiągnięcia:</span>
                </div>
              </header>
              <ul className="list-unstyled px-0">
                <div>
                  <li className="d-flex align-items-start">
                    <CheckIcon3 />
                    <span className="d-inline-block text-left">
                      Mistrz Świata w Kulturystyce 2019
                    </span>
                  </li>
                  <li className="d-flex align-items-start">
                    <CheckIcon3 />
                    <span className="d-inline-block text-left">
                      Mistrz Polski w Kulturystyce 2019
                    </span>
                  </li>
                  <li className="d-flex align-items-start">
                    <CheckIcon3 />
                    <span className="d-inline-block text-left">
                      Mistrz Polski w Kulturystyce 2018 roku
                    </span>
                  </li>
                </div>
                <div>
                  <li className="d-flex align-items-start">
                    <CheckIcon3 />
                    <span className="d-inline-block text-left">
                      5 miejsce na zawodach Biogenix Challenge 2017 roku
                    </span>
                  </li>
                  <li className="d-flex align-items-start">
                    <CheckIcon3 />
                    <span className="d-inline-block text-left">
                      Vice Mistrz Polski w Kulturystyce 2017 roku
                    </span>
                  </li>
                  <li className="d-flex align-items-start">
                    <CheckIcon3 />
                    <span className="d-inline-block text-left">
                      Mistrz Polski w Kulturystyce w kategorii Debiuty 2016
                    </span>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoAreWe;
