type UseNutritionsType = (
  weight?: number,
  goal?: string,
  gender?: string
) => { calories: number; protein: number; fats: number; carbs: number };

export const useNutritions: UseNutritionsType = (weight, goal, gender) => {
  let calories = 0;
  let protein = 0;
  let fats = 0;
  let carbs = 0;

  if (weight) {
    if (gender === "Kobieta") {
      protein = 1.5 * weight;
      fats = 1 * weight;
      switch (goal?.toUpperCase()) {
        case "REDUKCJA":
          carbs = weight * 1.5;
          break;
        case "REKOMPOZYCJA":
          carbs = weight * 2.5;
          break;
        case "MASA":
          carbs = weight * 3.5;
          break;
        default:
          carbs = 0;
          break;
      }
    } else if (gender === "Mężczyzna") {
      protein = 2 * weight;
      fats = 0.8 * weight;
      switch (goal?.toUpperCase()) {
        case "REDUKCJA":
          carbs = weight * 2;
          break;
        case "REKOMPOZYCJA":
          carbs = weight * 3;
          break;
        case "MASA":
          carbs = weight * 4;
          break;
        default:
          carbs = 0;
          break;
      }
    }
  }

  const proteinKcal = protein * 4;
  const fatsKcal = fats * 9;
  const carbsKcal = carbs * 4;

  calories = proteinKcal + fatsKcal + carbsKcal;

  return { calories: calories, protein, carbs, fats };
};
