import axios from "axios";
import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { User } from "../../../../actions/wp";
import Loader from "../../../../components/Loader/Loader";
import Constants from "../../../../constants";
import ClientInfo from "./elements/ClientInfo";

export type UserProfileParams = {
  clientId: string;
};

const TrainerSingleClientPage = () => {
  const token = localStorage.getItem("_wp_react_typescipt_token");
  let { clientId } = useParams<UserProfileParams>();
  const [user, setUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUser = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${Constants.apiUri}/users/${clientId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUser(response.data);
        setError("");
      } catch (err) {
        setError("Coś poszło nie tak");
      } finally {
        setIsLoading(false);
      }
    };
    fetchUser();
  }, [clientId, token]);

  return !isLoading ? (
    !error ? (
      <ClientInfo user={user} />
    ) : (
      <Alert variant="danger">{error}</Alert>
    )
  ) : (
    <Loader />
  );
};

export default TrainerSingleClientPage;
