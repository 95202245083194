import React from "react";
import "./WidthLayout.scss";

interface IWidthLayout {
  children: React.ReactNode;
}
const WidthLayout: React.FC<IWidthLayout> = ({ children }) => {
  return <section className="layout__wrapper">{children}</section>;
};

export default WidthLayout;
