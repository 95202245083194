import React from "react";
import "./RouteHeading.scss";

interface IRouteHeading {
  children: string | React.ReactNode;
}

const RouteHeading: React.FC<IRouteHeading> = ({ children }) => {
  return <h2 className="form-heading heading_route">{children}</h2>;
};

export default RouteHeading;
