import React from "react";
import RouteHeading from "../../../../components/headings/RouteHeading";
import Loader from "../../../../components/Loader/Loader";
import { useWpSelector } from "../../../../selectors";

import DetailsForm from "./components/DetailsForm";
import "./DetailsPage.scss";

const DetailsPage = () => {
  const { currentUser } = useWpSelector();

  return (
    <div className="dashboard__details">
      {currentUser ? (
        <div className="details">
          <header className="position-relative dashboard__header details__header">
            <RouteHeading>TWOJE CIAŁO I SYLWETKA</RouteHeading>
            <p className="mb-0">
              Informacje, które tu wpiszesz pomogą nam dostosować się do twoich
              indywidualnych potrzeb i ułatwią nam pracę nad twoim
              zrównoważonym, zdrowym progresem.
            </p>
          </header>
          <div className="details__form">
            <DetailsForm currentUser={currentUser} />
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default DetailsPage;
