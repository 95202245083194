import React, { useEffect } from 'react';

const TabFocusHandler: React.FC = () => {
    useEffect(() => {
        const handleTabKey = (e: KeyboardEvent) => {
            if (e.key === 'Tab') {
                document.body.classList.remove('using-mouse');
            }
        };

        const handleMouseDown = () => {
            document.body.classList.add('using-mouse');
        };

        document.body.addEventListener('keydown', handleTabKey);
        document.body.addEventListener('mousedown', handleMouseDown);

        return () => {
            document.body.removeEventListener('keydown', handleTabKey);
            document.body.removeEventListener('mousedown', handleMouseDown);
        };
    }, []);

    return null;
};

export default TabFocusHandler;
