import { useState, ChangeEvent } from 'react';
import NiceSelect from "../../../components/NiceSelect/NiceSelect";
import transformation1 from "../../../assets/images/png/transformations/transformation-1.png";
import transformation2 from "../../../assets/images/png/transformations/transformation-2.png";
import transformation3 from "../../../assets/images/png/transformations/transformation-3.png";
import transformation4 from "../../../assets/images/png/transformations/transformation-4.png";
import transformation5 from "../../../assets/images/png/transformations/transformation-5.png";
import transformation6 from "../../../assets/images/png/transformations/transformation-6.png";
import transformation7 from "../../../assets/images/png/transformations/transformation-7.png";
import transformation8 from "../../../assets/images/png/transformations/transformation-8.png";
import transformation9 from "../../../assets/images/png/transformations/transformation-9.jpg";
import "./Metamorphoses.scss";

const MetamorphosesSection = () => {
  const transformations = [
    transformation1, transformation2, transformation3, transformation4, transformation5, transformation6, transformation7, transformation8, transformation9
  ];

  const [sortOrder, setSortOrder] = useState("newest");

  const sortTransformations = () => {
    if (sortOrder === "newest") {
      return [...transformations].reverse();
    }
    return transformations;
  };

  const handleSortChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSortOrder(event.target.value);
  };

  const sortedTransformations = sortTransformations();

  return (
    <section className="metamorphoses">
      <div className="container">
        <div className='metamorphoses__wrapper'>
          <div className="d-flex justify-content-between align-items-center flex-row-reverse metamorphoses__sorting">
            <NiceSelect
              id="a-select"
              placeholder="Od najnowszych"
              className="metamorphoses__select"
              value={sortOrder}
              onChange={handleSortChange}
            >
              <option value="newest">Od najnowszych</option>
              <option value="oldest">Od najstarszych</option>
            </NiceSelect>
            <span>Sortowanie:</span>
          </div>
          <div className='metamorphoses__content'>
            {sortedTransformations.map((transformation, index) => (
              <div key={index} className='metamorphoses__item'>
                <img src={transformation} alt={`transformation-${index + 1}`} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MetamorphosesSection;
