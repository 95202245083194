import React, { useEffect, useState } from "react";
import axios from "axios";
import { useWpSelector } from "../../../../../selectors";
import Button from "../../../../../components/Buttons/LinkButton";
import TrainerClients from "../../../../../components/TrainercClients/TrainerClients";
import Constants from "../../../../../constants";
import links from "../../../../../constants/links";
import "./LatestCustomers.scss";

const LatestCustomers = () => {
  const { currentUser } = useWpSelector();
  const token = localStorage.getItem("_wp_react_typescipt_token");
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchLatestUsers = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${Constants.apiUri}/clients`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            per_page: 5,
            orderby: "registered_date",
            order: "desc",
          },
        });
        setUsers(response.data.users);
      } catch (err) {
        setError("Przepraszamy, coś poszło nie tak, spróbuj ponownie później");
      } finally {
        setIsLoading(false);
      }
    };

    fetchLatestUsers();
  }, [token]);

  return (
    <div className="cust__list">
      <h3 className="trainer__dashboard-second-heading">
        TWOI NAJNOWSI KLIENCI
      </h3>
      <TrainerClients
        clients={users}
        error={error}
        isLoading={isLoading}
        currentUser={currentUser}
      />
      <div className="cust__list_btn">
        <Button
          href={links.trainerClients}
          text="Zobacz wszystkich"
          type="link"
          variant="secondary"
        />
      </div>
    </div>
  );
};

export default LatestCustomers;
