import axios from "axios";
import React, { useEffect, useState } from "react";
import { SearchIcon } from "../../../../assets/images/svg/Icons";
import Loader from "../../../../components/Loader/Loader";
import NiceSelect from "../../../../components/NiceSelect/NiceSelect";
import Pagination from "../../../../components/Pagination/Pagination";
import TrainerRaports from "../../../../components/TrainerRaports/TrainerRaports";
import Constants from "../../../../constants";
import { useWpSelector } from "../../../../selectors";

const TrainerRaportsPage = () => {
  const { currentUser } = useWpSelector();
  const token = localStorage.getItem("_wp_react_typescipt_token");
  const [raports, setRaports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const fetchLatestRaports = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${Constants.apiUri}/reports`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            per_page: itemsPerPage, // Liczba użytkowników do pobrania
            orderby: "date", // Sortowanie według daty rejestracji
            order: "desc",
            page: currentPage,
            search: searchValue.length > 2 ? searchValue : "",
          },
        });
        setTotalPages(+response.headers["x-wp-totalpages"]);
        setRaports(response.data);
        setError("");
      } catch (err) {
        setError("Przepraszamy, coś poszło nie tak, spróbuj ponownie później");
      } finally {
        setIsLoading(false);
      }
    };

    fetchLatestRaports();
  }, [token, currentPage, itemsPerPage, searchValue]);

  const handleSearchRaport = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 2 || searchValue) {
      setSearchValue(e.target.value);
      setCurrentPage(1);
    }
  };

  const handleSortChange = (e: React.ChangeEvent<HTMLInputElement>) => {};

  const handleClickPage = (page: number) => {
    setCurrentPage(page);
  };

  return raports ? (
    <div className="dashboard__reports">
      <div className="reports">
        <div className="reports__content">
          <header className="reports__content__header">
            <div className="reports__content__col reports__content__col--left">
              <div className="input_search">
                <input
                  type="text"
                  placeholder="Wyszukaj raport"
                  onChange={handleSearchRaport}
                />
                <SearchIcon />
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center reports__content__col reports__content__col--right">
              <span>Sortowanie:</span>
              <NiceSelect
                id="a-select"
                placeholder="Od najnowszych"
                className="reports__select"
                onChange={handleSortChange}
              >
                <option value="newest">Rodzaj pakietu</option>
                <option value="oldest">Od najnowszych</option>
              </NiceSelect>
            </div>
          </header>
          <div className="reports__content__body cust_list">
            <TrainerRaports
              raports={raports}
              error={error}
              isLoading={isLoading}
              displayName={true}
              currentUser={currentUser}
            />
          </div>
          <Pagination
            currentPage={currentPage}
            onClickPage={handleClickPage}
            onNextPage={() => setCurrentPage(currentPage + 1)}
            onPrevPage={() => setCurrentPage(currentPage - 1)}
            itemsQuantityAvaliabl={[5, 10, 15]}
            onClickItemsQuantity={(quantity) => setItemsPerPage(quantity)}
            quantityPerPage={itemsPerPage}
            totalPages={totalPages}
          />
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default TrainerRaportsPage;
