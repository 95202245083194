export enum ActionWPTypes {
  fetchPosts = "FETCH_POSTS",
  fetchGoals = "FETCH_GOALS",
  fetchGoal = "FETCH_GOAL",
  fetchPlans = "FETCH_PLANS",
  fetchPlan = "FETCH_PLAN",
  fetchProducts = "FETCH_PRODUCTS",
  fetchPost = "FETCH_POST",
  deletePost = "DELETE_POST",
  updatePost = "UPDATE_POST",
  publishPost = "PUBLISH_POST",
  postMessage = "POST_MESSAGE",
  fetchCurrentUser = "FETCH_CURRENT_USER",
  forTesting = "TESTING_WP_TYPE",
  fetchMessages = "FETCH_MESSAGES",
  fetchUsers = "FETCH_USERS",
}

export enum ActionAuthTypes {
  authUser = "AUTH_USER",
  unAuthUser = "UNAUTH_USER",
  currentUser = "CURRENT_USER",
  forTesting = "TESTING_AUTH_TYPE",
}

export enum ActionMessagesTypes {
  warningMsg = "WARNING_MESSAGE",
  successMsg = "SUCCESS_MESSAGE",
  clearMsg = "CLEAR_MSG",
  forTesting = "TESTING_MESSAGE_TYPE",
}

export enum ActionUserTypes {
  createUser = "CREATE_USER",
  updateUser = "UPDATE_USER",
}
