import PageHeading from "../../../components/headings/PageHeading";
import Faq from "../../../components/Faq/Faq";
import AgnieszkaStopka from "../../../assets/images/png/agnieszka-stopka.png";
import MichalStopka from "../../../assets/images/png/michal-stopka.png";
import "./FaqForm.scss";
import axios from "axios";
import Constants from "../../../constants";
import { Field, Formik, FormikHelpers, Form as FormikForm } from "formik";
import {
  contactFormValidationSchema,
  initValuesContactForm,
} from "../../ContactPage/sections/ContactForm";
import FormField from "../../../components/Form/FormField";
import Loader from "../../../components/Loader/Loader";
import Button from "../../../components/Buttons/LinkButton";
import ContactFormThanksModal from "../../../components/ContactFormThanksModal/ContactFormThanksModal";
import { useState } from "react";

const FaqForm = () => {
  const token = localStorage.getItem("_wp_react_typescipt_token");
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleSubmit = async (values: any, actions: FormikHelpers<any>) => {
    await axios
      .post(`${Constants.apiUri}/send-email`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status < 300) {
          handleShow();
          actions.resetForm();
        }
      })
      .catch((error) => {});
  };

  return (
    <section className="faq-form">
      <div className="container">
        <div className="faq-form__wrapper">
          <div className="faq-form__left">
            <Faq showHeader={false} />
          </div>
          <div className="faq-form__right">
            <div className="right__wrapper">
              <header className="right__header">
                <div className="right__heading">
                  <PageHeading variant="h2">
                    CIĄGLE MASZ WĄTPLIWOŚCI?
                  </PageHeading>
                  <PageHeading variant="h3">NAPISZ DO MNIE!</PageHeading>
                </div>
                <div className="d-flex right__avatars">
                  <div className="avatar">
                    <img src={MichalStopka} alt="MICHAŁ BOBYK" />
                  </div>
                </div>
              </header>
              <p>
                Wiadomości wysłane za pomocą tego formularza trafią na moją
                firmową skrzynkę mailową. Odpiszę najszybciej jak pozwoli mi
                na to Nasz rozkład treningów. Jeśli zależy Ci na kontakcie
                bezpośrednim, przejdź do strony kontakt.
              </p>
              <div>
                <Formik
                  onSubmit={handleSubmit}
                  validationSchema={contactFormValidationSchema}
                  initialValues={{
                    ...initValuesContactForm,
                    to: "info@healthandbodyacademy.com",
                  }}
                >
                  {({ isSubmitting }) => {
                    return (
                      <FormikForm className="form__wrapper">
                        <div className="form__wrapper_group">
                          <Field
                            type="form"
                            label="IMIĘ"
                            name="name"
                            placeholder="Twoje imię tutaj"
                            contactForm={true}
                            component={FormField}
                          />
                          <Field
                            type="text"
                            label="NAZWISKO"
                            name="surname"
                            contactForm={true}
                            placeholder="Twoje nazwisko tutaj"
                            component={FormField}
                          />
                        </div>
                        <div className="form__wrapper_group">
                          <Field
                            type="email"
                            label="E-MAIL"
                            contactForm={true}
                            name="email"
                            placeholder="np. jankowalski@poczta.pl"
                            component={FormField}
                          />
                          <Field
                            type="phone"
                            label="TELEFON (OPCJONALNE)"
                            contactForm={true}
                            name="phone"
                            placeholder="Podaj 9-cyfrowy numer"
                            component={FormField}
                          />
                        </div>
                        <Field
                          name="message"
                          type="textarea"
                          label="TWOJA WIADOMOŚĆ"
                          rows="5"
                          maxLength="800"
                          component={FormField}
                          placeholder="Hej. Jestem zainteresowany treningiem z Tobą. Odzewij się do mnie, proszę najszybciej jak to możliwe."
                        />
                        <Field
                          name="termsOne"
                          checkboxValue="Akceptuję regulamin"
                          component={FormField}
                          type="checkbox"
                        />
                        <Field
                          name="termsTwo"
                          checkboxValue="Akceptuję politykę prywatności"
                          component={FormField}
                          type="checkbox"
                        />
                        <div className="btn_wr">
                          <Button
                            variant="primary"
                            submit={true}
                            disabled={isSubmitting}
                            type="button"
                          >
                            {isSubmitting ? (
                              <Loader variant="light" />
                            ) : (
                              "ZATWIERDŹ I WYŚLIJ"
                            )}
                          </Button>
                        </div>
                      </FormikForm>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <ContactFormThanksModal
          handleClose={handleClose}
          showModal={showModal}
        />
      </div>
    </section>
  );
};

export default FaqForm;
