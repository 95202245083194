import { Link, useLocation } from "react-router-dom";
import { AllowedRoles } from "../../actions/wp";
import { ChevronIcon } from "../../assets/images/svg/Icons";
import links from "../../constants/links";
import { useWpSelector } from "../../selectors";
import { useCurrentPathNav } from "../AdminSidebarNav/functions/useCurrentPathNav";

import "./PanelBreadcrumps.scss";

const PanelBreadcrumps = () => {
  const { pathname } = useLocation();

  const { renderCurrentPath } = useCurrentPathNav(false);
  const { currentUser } = useWpSelector();

  const isAdmin = currentUser?.roles.includes(AllowedRoles.admin);

  const pathNotDashboard = pathname !== links.dashboard;

  return (
    <div className="breadcrumps__wrapper">
      <Link to={links.home}>
        Strona główna <ChevronIcon />
      </Link>
      <Link to={links.dashboard} className={`${!pathNotDashboard && "active"}`}>
        {isAdmin ? "Panel Trenera" : "Twój panel"}{" "}
        {pathNotDashboard && <ChevronIcon />}
      </Link>
      {pathNotDashboard && (
        <span className="current_path">{renderCurrentPath()}</span>
      )}
    </div>
  );
};

export default PanelBreadcrumps;
