import React from "react";
import {
  CompletedStageIcon,
  CurrentStageIcon,
  InactiveStageIcon,
} from "../../../assets/images/svg/Icons";

interface SignUpStepsProps {
  currentStep: number;
}

const totalSteps = 4;
const stepTexts = ["Twoje cele", "Twój cel", "Wyb. pakiet", "Twoje dane"];

const SignUpSteps: React.FC<SignUpStepsProps> = ({ currentStep }) => {
  const steps: any = [];
  for (let i = 0; i < totalSteps; i++) {
    let classNames = "text-center form-register__step";
    classNames +=
      i < currentStep - 1
        ? " completed"
        : i === currentStep - 1
        ? " active"
        : "";
    let StepIcon =
      i === currentStep - 1
        ? CurrentStageIcon
        : i < currentStep - 1
        ? CompletedStageIcon
        : InactiveStageIcon;

    steps.push(
      <div key={i + 1} className={classNames}>
        <div className="position-relative form-register__step-number">
          {i < currentStep - 1 ? (
            <StepIcon />
          ) : (
            <>
              <StepIcon />
              <span className="position-absolute">{i + 1}</span>
            </>
          )}
        </div>
        <span className="d-block form-register__step-text">{stepTexts[i]}</span>
      </div>
    );
  }

  return (
    <section className="form-register__steps">
      <div className="d-flex justify-content-center">{steps}</div>
    </section>
  );
};

export default SignUpSteps;
