import { mockedPackages } from "../data/mockedPackages";
import PackageItem from "../elements/PackageItem";
import "./PackagesSection.scss";

const PackagesSection = () => {
  return (
    <section className="packages_section container">
      {mockedPackages.map((item) => {
        return (
          <PackageItem
            key={item.id}
            desc={item.desc}
            duration={item.duration}
            id={item.id}
            img={item.img}
            includes={item.includes}
            price={item.price}
            title={item.title}
            target={item.target}
            bestseller={item.bestseller}
            bestPrice={item.bestPrice}
          />
        );
      })}
    </section>
  );
};

export default PackagesSection;
